import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  padding: 10px 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url(${({ background }) => background});
  background-size: cover;
  text-align: center;
`

export const Title = styled.h1`
  margin: 0;
  padding-top: 20px;

  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 35px;
`

export const Subtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 16px;
`

export const ModalTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 16px;
`

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;

  margin-bottom: 10px;
`

export const TextHelp = styled.p`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;

  margin-top: 30px;

  cursor: pointer;
`

export const Icon = styled.span`
  display: inline-block;
  height: 15px;
  width: 15px;

  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gray.medium};
`

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;
`

export const Price = styled.p`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 30px;
`

export const Warning = styled.div`
  width: 100%;
  min-height: 32px;
  padding: 8px 2rem;

  background-color: #fff;
  box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  margin-top: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  > img {
    width: 24px;
    height: 24px;
  }
`
