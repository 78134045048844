import { rem } from 'polished'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin: 10px 0;
`
export const Label = styled.label`
  font-size: ${rem('10px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};
  padding-left: 4px;

  ${({ containError }) =>
    containError &&
    css`
      color: ${({ theme }) => theme.colors.danger};
    `}

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('14px')};
  }
`

export const Input = styled.div`
  width: 100%;
  height: 22px;
  display: block;
  padding-left: 5px;

  display: flex;
  align-items: center;

  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.light};

  ${({ containError }) =>
    containError &&
    css`
      color: ${({ theme }) => theme.colors.danger};
    `}
  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    height: 45px;
  }
`

export const List = styled.div`
  height: 300px;
  overflow-y: scroll;
`

export const Placeholder = styled.span`
  display: block;
  width: 100%;
  padding-right: 40px;

  color: ${({ theme }) => theme.colors.gray.light};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${rem('12px')};

  ${({ hasValue }) =>
    hasValue &&
    css`
      color: ${({ theme }) => theme.colors.gray.medium};
    `}

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const WrapInput = styled.div`
  position: relative;

  &::after {
    content: '';
    display: block;

    height: 2px;
    width: 16px;

    position: absolute;
    right: 13px;
    bottom: 13px;

    background: ${({ theme }) => theme.colors.gray.light};

    @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
      bottom: 20px;
    }
  }

  &::before {
    content: '';
    display: block;

    height: 16px;
    width: 2px;

    position: absolute;
    right: 20px;
    bottom: 6px;

    background: ${({ theme }) => theme.colors.gray.light};

    @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
      bottom: 13px;
    }
  }
`

export const Icon = styled.div`
  height: 16px;
  width: 16px;

  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray.medium};

  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    height: 10px;
    width: 10px;
    border-radius: 50%;

    background: ${({ theme }) => theme.colors.white};

    ${({ selected }) =>
      selected &&
      css`
        background: ${({ theme }) => theme.colors.gray.medium};
      `}
  }
`

export const Option = styled.div`
  margin: 15px 0;
  width: 100%;

  display: flex;
  align-items: center;

  &:hover {
    ${({ theme }) => theme.colors.gray.medium};
  }
`

export const TextOption = styled.p`
  display: block;
  width: 100%;
  font-size: ${rem('14px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};
  padding-left: 4px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const Error = styled.p`
  font-size: ${rem('10px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.medium};
  text-align: right;
  margin-top: 5px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('14px')};
  }
`

const codes = {
  ESTOQUE: '#0027F4',
  TRANSITO: '#00E0FF',
  ENCOMENDA: '#EABC00',
}
export const Delivery = styled.div`
  padding: 3px 8px;
  border-radius: 20px;
  color: #fff;
  white-space: nowrap;

  line-height: 1;

  ${({ code }) =>
    Object.keys(codes).includes(code)
      ? css`
          background-color: ${codes[code]};
        `
      : ''}
`

export const WrapDelivery = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
