import styled, { css } from 'styled-components'
import { rem, rgba } from 'polished'
import Cleave from 'cleave.js/react'

import { componentLoader } from 'styles/helpers'

export const Container = styled.div`
  position: relative;
`

export const Label = styled.label`
  position: absolute;
  top: -6px;

  padding-top: 5px;

  transition: 0.3s;
  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    top: -4px;
    font-size: ${rem('14px')};
  }
`

export const Value = styled(Cleave)`
  all: unset;

  width: 100%;
  height: 100%;
  display: block;
  padding-top: 10px;

  color: ${({ theme }) => theme.colors.gray.medium};
  transition: 0.3s;
  box-sizing: border-box;
  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.medium};
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const Field = styled.div`
  position: relative;

  height: 34px;
  padding: 0 5px;

  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.medium};

  ${({ focusedColor, focus }) =>
    focus &&
    css`
      border-color: ${focusedColor};

      ${Label} {
        color: ${focusedColor};
        transition: 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }

      ${Value} {
        color: ${focusedColor};
        transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      }
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      ${componentLoader({
        visible: true,
        primary: true,
        centeredRight: true,
      })}
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${({ theme }) => theme.colors.gray.light};

      ${Label} {
        color: ${({ theme }) => theme.colors.gray.medium};
      }

      ${Value} {
        color: ${({ theme }) => rgba(theme.colors.gray.dark, 0.4)};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.danger};
      border-color: ${({ theme }) => theme.colors.danger};

      ${Label} {
        color: ${({ theme }) => theme.colors.danger};
      }

      ${Value} {
        color: ${({ theme }) => theme.colors.danger};

        ::placeholder {
          color: ${({ theme }) => theme.colors.danger};
        }
      }
    `}

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    height: 50px;
  }
`

export const Error = styled.p`
  font-size: ${rem('10px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.medium};
  text-align: right;
  margin-top: 5px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('14px')};
  }
`

export const WrapperEyeIcon = styled.div`
  position: absolute;
  top: 8px;
  right: 45px;
  cursor: pointer;
  
  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    top: 15px;
  }
`
