import styled from 'styled-components'
import { flexbox, layout, space, position } from 'styled-system'

/**
 * Flexbox usage example: https://styled-system.com/api#flexbox
 * Layout usage example: https://styled-system.com/api#layout
 * Space usage example: https://styled-system.com/getting-started#margin--padding
 */
export const Flex = styled.div`
  ${flexbox}
  ${layout}
  ${space}
  ${position}

  display: flex;
`
