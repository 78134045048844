import styled from 'styled-components'
import { breakpoints } from 'styles'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 30px 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-size: cover;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.9);
`

export const Content = styled.div`
  margin: auto;
  /* max-width: 240px; */

  @media (min-width: ${breakpoints.small}) {
    max-width: 500px;
  }
`

export const Title = styled.p`
  margin-bottom: 20px;

  color: #fff;
  font-size: 20px;
  font-family: ${({ theme }) => theme.fonts.bold};
`

export const Text = styled.p`
  color: #fff;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.book};

  a {
    text-decoration: underline;

    color: white;
    font-family: ${({ theme }) => theme.fonts.black};
  }
`

export const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.bold};
`

export const Form = styled.form`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
