import { createStore, createEvent, createEffect } from 'effector'
import { useStore } from 'effector-react'
import { persist } from 'effector-storage/session'

import { getDisponibilityZones } from 'services/api'

const store = createStore({
  consent: {},
  broker: {},
  order: {},
  posBureau: [],
  step1: {},
  step2: {},
  step3: {},
  allVehicles: {
    list: [],
    categories: [],
    sort: 'minPackage:desc',
    category: 'all',
  },
  portoCard: [],
  isElegiblePortoCard: false,
  coupon: '',
  zone: '',
  loading: {
    visible: false,
    text: '',
    subText: '',
  },
  origin: '',
  isMocked: false,
})

// process.env.NODE_ENV === 'development' && store.watch(console.log)
process.env.NODE_ENV === 'development' && persist({ store, key: 'omni' })

const setOrder = createEvent()
const setOrigin = createEvent()
const setZone = createEvent()
const setStep1Info = createEvent()
const setPosBureau = createEvent()
const setStep2Info = createEvent()
const setStep3Info = createEvent()
const setStep6Info = createEvent()
const setPortoCard = createEvent()
const setLoading = createEvent()
const setIsElegiblePortoCard = createEvent()
const setBroker = createEvent()
const setConsent = createEvent()
const setCoupon = createEvent()
const setAllVehicles = createEvent()
const setAllVehiclesCategory = createEvent()
const setIsMocked = createEvent()

const resetStore = createEvent()

const getZoneID = createEffect({
  handler: async (initials) => {
    try {
      const zones = await getDisponibilityZones()
      const zone = zones.find(
        (zone) => zone.initials === initials.toLowerCase()
      ).id

      setZone(zone)
    } catch (e) {
      console.error(e)
    }
  },
  name: 'getZoneID',
})

store
  .on(setConsent, (state, consent) => ({
    ...state,
    consent,
  }))
  .on(setOrder, (state, order) => ({
    ...state,
    order,
  }))
  .on(setBroker, (state, broker) => ({
    ...state,
    broker,
  }))
  .on(setZone, (state, zone) => ({
    ...state,
    zone,
  }))
  .on(setStep1Info, (state, step1) => ({
    ...state,
    step1: { ...state.step1, ...step1 },
  }))
  .on(setStep2Info, (state, step2) => ({
    ...state,
    step2: { ...state.step2, ...step2 },
  }))
  .on(setStep3Info, (state, step3) => ({
    ...state,
    step3: { ...state.step3, ...step3 },
  }))
  .on(setPosBureau, (state, posBureau) => {
    return {
      ...state,
      posBureau: posBureau,
    }
  })
  .on(setStep6Info, (state, step6) => ({
    ...state,
    step6: { ...state.step6, ...step6 },
  }))
  .on(setPortoCard, (state, portoCard) => ({
    ...state,
    portoCard,
  }))
  .on(setLoading, (state, loading) => ({
    ...state,
    loading: { ...state.loading, ...loading },
  }))
  .on(setCoupon, (state, coupon) => ({
    ...state,
    coupon,
  }))
  .on(setIsElegiblePortoCard, (state, isElegiblePortoCard) => ({
    ...state,
    isElegiblePortoCard,
  }))
  .on(setOrigin, (state, origin) => ({
    ...state,
    origin: origin,
  }))
  .on(getZoneID.doneData, (state, zone) => ({
    ...state,
    step2: { ...state.step2, zone },
  }))
  .on(setAllVehicles, (state, data) => ({
    ...state,
    allVehicles: {
      ...state.allVehicles,
      ...data,
    },
  }))
  .on(setAllVehiclesCategory, (state, data) => ({
    ...state,
    allVehicles: {
      ...state.allVehicles,
      ...data,
    },
  }))
  .on(setIsMocked, (state, data) => ({
    ...state,
    isMocked: data,
  }))
  .reset(resetStore)
  .watch(async (store) => {
    const { order, zone } = store

    if (!zone && !!order?.customer?.defaultAddress?.state) {
      await getZoneID(order?.customer?.defaultAddress?.state)
    }
  })

export default function useFlow() {
  return {
    store: useStore(store),
    setOrder,
    setStep1Info,
    setStep2Info,
    setStep3Info,
    setStep6Info,
    setLoading,
    setBroker,
    setConsent,
    getZoneID,
    setCoupon,
    setPosBureau,
    setOrigin,
    setIsElegiblePortoCard,
    setPortoCard,
    setAllVehicles,
    setAllVehiclesCategory,
    setIsMocked,
    resetStore,
  }
}
