import React from 'react'

import { Container } from './styles'

export default function Button({
  block,
  color,
  isLoading,
  children,
  link,
  gtmName,
  gtmClickType,
  disabled,
  inverted,
  ...props
}) {
  return (
    <Container
      {...props}
      link={link}
      color={color}
      block={block}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      data-gtm-type="click"
      data-gtm-name={`${gtmName || children.toString().toLowerCase()}`}
      data-gtm-clicktype={`${gtmClickType || 'button'}`}
    >
      {children}
    </Container>
  )
}
