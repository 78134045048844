import { rem } from 'polished'
import styled from 'styled-components'

export const Content = styled.div`
  position: relative;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${rem('16px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  margin-bottom: 30px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('20px')};
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  margin-bottom: 20px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const SubTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.light};
`

export const WrapCNH = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    display: flex;
    justify-content: space-between;
  }
`

export const CHNItem = styled.div`
  width: 100%;
  padding: 30px 0;

  &:not(:last-item) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    width: 40%;

    border-bottom: none;
    padding: 0;
  }
`

export const Form = styled.form`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 2rem;
`

export const WrapImage = styled.div`
  height: 284px;
  width: 200px;
  margin: auto;

  img {
    width: 100%;
  }
`

export const Camera = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .camera-image {
    height: 100%;
  }

  img {
    height: 100%;
  }
`

export const Button = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 50px;
  height: 50px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 1rem;
`

export const BoxCamera = styled.div`
  #box-camera {
    all: initial;
  }
`
