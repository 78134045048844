import styled, { css } from 'styled-components'
import { breakpoints } from 'styles'

const handlePadding = (value) => {
  return value.map((el) => ` ${el}px`)
}

export const Container = styled.div`
  padding: ${({ padding }) =>
    padding ? handlePadding(padding[1]) : handlePadding([80, 18, 0])};
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.small}) {
    height: ${({ fullHeight }) => (!fullHeight ? 'fit-content' : '100%')};
    padding: ${({ padding }) =>
      padding ? handlePadding(padding[0]) : handlePadding([80, 30, 20])};
  }

  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
      background-size: cover;
      background-position: center;
    `}
`
