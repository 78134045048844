import styled from 'styled-components'

import { breakpoints } from 'styles'

export const Main = styled.main`
  position: relative;

  width: 100%;
  height: 100%;

  background: #fff;
`
export const Wrapper = styled.div`
  display: grid;
  height: calc(100% - 70px);

  @media (min-width: ${breakpoints.medium}) {
    width: 100vw;

    grid-template-columns: 40% 1fr;
  }
`

export const Overflow = styled.div`
  overflow-y: auto;
`

export const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`
