import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import useFlow from 'store'

import * as S from './styles'

import { ChatBox, LineInput, FloatingButton, Alert } from 'components'
import { getCoupon, sendPayment } from 'services/api'
import { PAYMENT } from 'helpers'
import {
  calculateDiscount,
  encryptTextWithKey,
  formatMoney,
  updateCustomData,
} from 'utils'
import { useErrorHandler } from 'store/orderRequest/hook'

const DISCOUNT_VALUE = 150

export default function PayWithPorto() {
  const {
    store: { order, portoCard, isMocked },
    setLoading,
    setOrder,
  } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const { package: orderPackege } = order

  const methods = useForm()
  const history = useHistory()
  const { dataLayer = [] } = window

  const [isLoading, setIsLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [price, setPrice] = useState(order?.summary?.baseCost.rawValue)

  const handlePriceWithDiscount = (price) => {
    if (!price) {
      return
    }

    return formatMoney(price)
  }

  const onSubmit = methods.handleSubmit(async ({ cardNumber }) => {
    try {
      setIsLoading(true)
      const userCard = portoCard.find((card) =>
        card.number?.includes(cardNumber)
      )

      if (!userCard) {
        throw Error('Dados inválidos, verifique os dados informados')
      }

      const payment = await sendPayment(
        {
          cardNumber: userCard?.number,
          orderId: order?.id,
          type: PAYMENT.PORTO,
        },
        isMocked
      )

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'dados-cartao',
        tipo_servico: 'cartao',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'sucesso',
        descricao: 'Pagamento realizado com sucesso',
      })

      setOrder({
        ...order,
        payment,
      })
      history.push('/completed')
    } catch (error) {
      setShowAlert(true)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'dados-cartao',
        tipo_servico: 'cartao',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'erro',
        descricao: error.message,
        erro: {
          codigo: error.code,
          servico: '/payments',
          mensagem: error.message,
        },
      })

      if (error.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    } finally {
      setIsLoading(false)
    }
  })

  useEffect(() => {
    if (order.coupon) {
      ;(async () => {
        try {
          setLoading({ visible: true, text: 'Verificando cupom' })
          const { value, type } = await getCoupon(order.coupon)
          const orderPrice = orderPackege?.cost.rawValue * orderPackege?.months

          const finalPrice = calculateDiscount(orderPrice, value, type)

          setPrice(finalPrice / orderPackege?.months)
        } catch (error) {
          console.warning(error)
        } finally {
          setLoading({ visible: false, text: '' })
        }
      })()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.coupon])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'selecao-cartao',
      },
      carro: {
        categoria: order?.product.vehicle.category.slug,
        marca: order?.product.vehicle.model.brand.title.toLowerCase(),
        id: order?.product.id,
        nome: order?.product.vehicle.model.specification,
        variacao: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
        preco: formatMoney(
          order?.summary.baseCost.rawValue * order?.summary.months
        ),
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkout/pagamento/dados-cartao',
    })
  }, [dataLayer, order])

  return (
    <S.Container>
      <ChatBox
        text={`Pague com o Cartão Porto e ganhe ${handlePriceWithDiscount(
          DISCOUNT_VALUE
        )}* de desconto em uma das 3 primeiras parcelas do seu Carro por Assinatura Porto Bank!.`}
      />

      <S.Content>
        <S.Box>
          <S.BoxContent>
            <S.NewPrice>
              <span>Valor da Parcela</span>
              <p>{handlePriceWithDiscount(price)}</p>
            </S.NewPrice>
          </S.BoxContent>
        </S.Box>

        <FormProvider {...methods}>
          <S.Title>Dados do cartão:</S.Title>

          <S.Form onSubmit={onSubmit}>
            <p>
              Confirme os quatro últimos digitos do seu cartão Porto Bank para
              fazer o pagamento:
            </p>

            <S.FormGroup>
              <LineInput
                name="cardNumber"
                placeholder="Quatro últimos digitos"
                label="Quatro últimos digitos"
                maxLength="4"
                defaultValue=""
                numericOnly
              />
            </S.FormGroup>
          </S.Form>
        </FormProvider>
      </S.Content>

      <S.Button>
        <FloatingButton
          rounded
          color={'white'}
          text="Voltar"
          height="57px"
          action={() => {
            history.push('/selecionar-cartao-credito')
          }}
          disabled={isLoading}
          isLoading={isLoading}
          gtmName="selecionar-outro-cartao"
        />
        <FloatingButton
          rounded
          text="Pagar meu Carro por Assinatura"
          height="57px"
          action={onSubmit}
          gtmClickType="submit"
          disabled={isLoading}
          isLoading={isLoading}
          gtmName="pagar-meu-carro-facil"
        />
      </S.Button>

      <Alert
        isVisible={showAlert}
        actions={{
          primary: { onClick: () => setShowAlert(false), label: 'Entendi' },
        }}
      >
        <p>
          Não foi possível concluir a transação, por favor tente mais tarde!
        </p>
      </Alert>
    </S.Container>
  )
}

PayWithPorto.properties = {
  pageStep: 'Pagar com cartão Porto',
  showStep: false,
}
