import styled from 'styled-components'
import { breakpoints } from 'styles'

export const Content = styled.div`
  padding-bottom: 20px;
`

export const Label = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: rgba(5, 5, 5, 0.8);
  font-family: ${({ theme }) => theme.fonts.black};
`

export const Bold = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.black};
`

export const Druk = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.wide};
`

export const Grid = styled.div`
  display: grid;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: ${breakpoints.medium}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Wrapper = styled.div`
  margin-top: 35px;
  padding: 35px 15px;
  width: 100%;

  background: #ffffff;
  border: 2px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
`

export const FormGrid = styled.div`
  display: grid;
  margin-top: 20px;
  grid-gap: 30px;

  @media (min-width: ${breakpoints.medium}) {
    grid-template-columns: 1fr 1fr;
  }
`
