import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Flex, Grid, Select } from 'components'

import {
  Container,
  Content,
  Icon,
  Label,
  Price,
  Subtitle,
  TextHelp,
  Title,
  Warning,
} from './styles'

export default function Main({
  background,
  version,
  index,
  setValues,
  values,
  vehicleOptions,
  goTo,
  setModalHelp,
}) {
  const forms = useForm({
    defaultValues: {
      models: index,
    },
  })

  const [periods, setPeriods] = useState([])
  const [bundles, setBundles] = useState([])
  const [plans, setPlans] = useState({ active: false, options: [] })
  const [price, setPrice] = useState('')
  const [change, setChange] = useState('')

  const bundle = forms.watch('bundles')
  const period = forms.watch('periods')
  const model = forms.watch('models')

  function periodChange() {
    setBundles([])
    setPrice('')
    forms.setValue('bundles', '')

    const preBundles = Array.from(
      new Set(
        version.packages
          ?.filter((pkg) => {
            return pkg.months === parseInt(period) || null
          })
          .map((pkg) => pkg.mileage)
      )
    )
      .map((bundle) => ({ value: bundle, label: `${bundle} KM` }))
      .sort()

    setBundles(preBundles)
  }

  function bundleChange() {
    setPrice('')

    const pkgs = version?.packages
      //eslint-disable-next-line
      ?.filter((pkg) => {
        if (
          pkg.months === parseInt(period) &&
          pkg.mileage === parseInt(bundle)
        ) {
          return pkg
        }
      })
      .sort()

    setPrice(pkgs?.[0]?.cost?.formattedValue)
  }

  function modelChange() {
    goTo(model)
    forms.reset()
  }

  const actions = {
    period: periodChange,
    bundle: bundleChange,
    model: modelChange,
  }

  useEffect(() => {
    const availablePeriods = Array.from(
      new Set(version.packages?.map((pkg) => pkg.months))
    )
      .map((month) => ({ value: month, label: `${month} meses` }))
      .sort((a, b) => {
        if (a?.value > b?.value) {
          return 1
        }

        return -1
      })

    setPeriods(availablePeriods)
  }, []) //eslint-disable-line

  useEffect(() => {
    if (typeof actions[change] === 'function') {
      actions[change]()
    }
    setChange('')
  }, [change]) //eslint-disable-line

  useEffect(() => {
    const selectedPackage = version.packages.find(
      (item) =>
        item.months === parseInt(period) &&
        item.mileage === parseInt(bundle) &&
        item?.plans?.length > 0
    )

    const productPackage = selectedPackage?.id

    let plan = ''

    if (selectedPackage?.plans.length > 1) {
      const plansOptions = selectedPackage?.plans?.map((item) => ({
        value: item.id,
        label: item.title,
      }))

      setPlans({ active: true, options: plansOptions })
      plan = forms.watch('plans')
    } else {
      plan = selectedPackage?.plans[0].id
    }

    const currentValues = {
      plan,
      period,
      bundle,
      productPackage,
      product: version.id,
    }

    setValues(currentValues)
  }, [model, period, bundle]) //eslint-disable-line

  return (
    <Container>
      <Content background={background}>
        <Title>{version.vehicle.model.specification}</Title>
        <Subtitle>{version.vehicle.specification}</Subtitle>
        <FormProvider {...forms}>
          <form>
            <Grid
              gridRowGap="30px"
              gridColumnGap="10px"
              mt="36px"
              gridTemplateColumns="1fr"
            >
              <Select
                placeholder="Escolha a versão"
                name="models"
                options={vehicleOptions}
                onChange={() => setChange('model')}
                selected={model}
                gtmName="versao"
              />
            </Grid>
            <TextHelp onClick={() => setModalHelp(true)}>
              Escolha o prazo e a quilometragem do seu contrato <Icon>i</Icon>
            </TextHelp>

            <Grid
              gridRowGap="30px"
              gridColumnGap="10px"
              mt="12px"
              gridTemplateColumns="1fr 1fr"
            >
              <Select
                placeholder="Tempo de contrato"
                name="periods"
                options={periods}
                onChange={() => setChange('period')}
                selected={period}
                gtmName="periodo"
              />
              <Select
                placeholder="Pacote de KM"
                name="bundles"
                options={bundles}
                onChange={() => setChange('bundle')}
                selected={bundle}
                gtmName="quilometragem"
              />
            </Grid>
            <Grid
              gridRowGap="30px"
              gridColumnGap="10px"
              mt="12px"
              gridTemplateColumns="1fr"
            >
              {plans.active && (
                <Select
                  placeholder="Escolha a versão"
                  name="plans"
                  options={plans.options}
                  selected={model}
                />
              )}
            </Grid>
          </form>
        </FormProvider>
        <Flex minHeight="50px" flexDirection="column" mt="30px">
          {price && (
            <>
              <Label>Valor mensal</Label>
              <Price>{price}</Price>
            </>
          )}
        </Flex>

        <Warning>
          <img src="/images/warning.svg" alt="icone de atenção" />
          <p>
            <strong>Informação importante</strong>: Está proposta ficará
            disponivel por apenas 42 horas.
          </p>
        </Warning>
      </Content>
    </Container>
  )
}
