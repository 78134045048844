export const calculateDiscount = (value, discount, type) => {
  if (type === 'value') {
    return value - discount
  }

  return value - value * (discount / 100)
}

export const formatMoney = (value) => {
  if (!value) {
    return
  }

  return value?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  })
}
