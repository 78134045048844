import React, { useCallback } from 'react'
import Icon from 'components/Icon'
import { colors } from 'styles'

import * as S from './styles'
import Button from 'components/Button'

const Modal = ({
  title = '',
  visible = true,
  setIsVisible,
  children,
  buttonText = 'Fechar',
  onClickCloseButton,
  buttonClose,
}) => {
  const closeModalFunction = useCallback(() => {
    if (typeof onClickCloseButton === 'function') {
      onClickCloseButton()
      return
    }

    setIsVisible(false)
  }, [onClickCloseButton, setIsVisible])

  const buttonCloseRender = useCallback(() => {
    if (buttonClose) {
      return buttonClose()
    }

    return (
      <Button onClick={closeModalFunction} height="40px">
        {buttonText}
      </Button>
    )
  }, [buttonClose, buttonText, closeModalFunction])

  if (!visible) {
    return <></>
  }

  return (
    <S.Modal>
      <S.Behind onClick={() => setIsVisible(false)} />

      <S.Content>
        <S.Header>
          <S.Title>{title}</S.Title>

          <S.Button>
            <Icon
              name="x-circle"
              color={colors.gray.dark}
              onClick={() => setIsVisible(false)}
            />
          </S.Button>
        </S.Header>

        <S.ChildrenWrap>
          <>{children}</>

          <S.WrapButton>{buttonCloseRender()}</S.WrapButton>
        </S.ChildrenWrap>
      </S.Content>
    </S.Modal>
  )
}

export default Modal
