import styled, { css } from 'styled-components'
import { rem } from 'polished'

import { componentLoader } from 'styles/helpers'

export const Options = styled.select`
  all: unset;

  width: 100%;
  min-width: 10rem;
  height: 44px;
  padding: 0 25px 0 1.4rem;

  display: flex;
  align-items: center;

  cursor: pointer;
  border-radius: 90px;
  box-sizing: border-box;
  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  background-color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
`

export const Option = styled.option`
  padding: 5px 0;

  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
`

export const Container = styled.div`
  position: relative;

  ${Options} {
    color: #fff;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${Options} {
        color: ${({ theme }) => theme.colors.gray.light};
        border-color: ${({ theme }) => theme.colors.gray.light};
      }
    `}

  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}

  > svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    height: 18px;
    color: #fff;
    pointer-events: none;
  }

  ${({ selected }) =>
    selected &&
    css`
      > svg {
        color: #fff;
      }

      ${Options} {
        transition: 0.3s;
        background-color: ${({ theme }) => theme.colors.primary};
      }
    `}
`

export const Loader = styled.div`
  ${componentLoader({
    visible: true,
    primary: true,
  })}

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
`

export const Error = styled.p`
  position: absolute;
  bottom: -30px;
  left: 0;

  padding: 6px 20px 0;

  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.medium};
`

export const TipBox = styled.div`
  position: absolute;
  top: 0;
  right: -15px;
`
