import React from 'react'

import { Button } from 'components'
import { useLockBodyScroll } from 'hooks'

import { Container, Overlay, Dialog, Flag, Content, Actions } from './styles'

export default function Alert({ isVisible, flag, actions, children }) {
  const { primary, secondary } = actions

  useLockBodyScroll(isVisible)

  return (
    <Container>
      <Overlay isOpen={isVisible} />

      <Dialog isOpen={isVisible}>
        {flag && <Flag color={flag.color}>{flag.text}</Flag>}

        <Content>{children}</Content>

        {actions && (
          <Actions>
            {secondary && (
              <Button color="gray" onClick={secondary.onClick}>
                {secondary.label}
              </Button>
            )}

            {primary && (
              <Button onClick={primary.onClick}>{primary.label}</Button>
            )}
          </Actions>
        )}
      </Dialog>
    </Container>
  )
}
