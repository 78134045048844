import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
      transition: 0.3s;
    `}
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;

  background-color: rgb(0 26 255 / 90%);
`

export const Dialog = styled.div`
  z-index: 3;
  padding: 0 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TextBox = styled.div`
  text-align: center;
`

export const Text = styled.p`
  :first-of-type {
    margin-bottom: 20px;
    margin-top: 40px;
  }

  color: #fff;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 20px;
`

export const Loader = styled.img`
  height: 100px;
  width: auto;
`

export const RingLoad = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
