import { FloatingButton } from 'components'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useFlow from 'store'
import { formatMoney, updateCustomData } from 'utils'

import * as S from './styles'

const FirstPageCheckin = () => {
  const { dataLayer = [] } = window

  const history = useHistory()
  const {
    store: { order },
  } = useFlow()

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'inicio-checkin',
      },
      carro: {
        nome: order?.product?.title,
        categoria: order?.product?.vehicle?.category?.title,
        marca: order?.product?.vehicle?.model?.brand?.title,
        id: order?.product?.vehicle?.id,
        preco: formatMoney(
          order?.package?.months * order?.package?.cost?.rawValue
        ),
        variacao: `${order?.colorName} | ${order?.package?.mileage} km | ${order?.package?.months} meses`,
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkin',
    })
  }, [dataLayer, order])

  return (
    <S.Container>
      <div>
        <S.Title strong>{order?.customer?.firstName},</S.Title>
        <S.Title>Vamos continuar!</S.Title>
        <S.Title>Chegou a hora de você ter em mãos alguns documentos.</S.Title>
      </div>

      <div>
        <S.WrapContent>
          <S.Text>Agora você deve enviar:</S.Text>
          <S.WrapItems>
            <S.WrapIcon>
              <img src="/images/cnh.svg" alt="" />
            </S.WrapIcon>
            <S.Text>CNH</S.Text>
          </S.WrapItems>
          <S.WrapItems>
            <S.WrapIcon>
              <img src="/images/selfie.svg" alt="" />
            </S.WrapIcon>
            <S.Text>Tirar um selfie</S.Text>
          </S.WrapItems>
        </S.WrapContent>

        <FloatingButton
          rounded
          gtmName="prosseguir"
          action={() => history.push('/upload-cnh')}
          text="Prosseguir"
        />
      </div>
    </S.Container>
  )
}

export default FirstPageCheckin
