import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import * as S from './styles'

function LineSelect({ label, name, options, placeholder }) {
  const { control, register, errors } = useFormContext()

  const containError = useMemo(() => {
    if (!errors || !errors[name]) {
      return false
    }

    return errors[name]?.label?.message
  }, [errors, name])

  return (
    <S.Container>
      <S.Label containError={!!containError}>{label}</S.Label>

      <input type="hidden" {...register(name)} />

      <S.Select
        containError={!!containError}
        onChange={(e) => {
          const label = e?.target?.selectedOptions[0]?.text
          const value = JSON.parse(e.target.value)

          control.setValue(name, {
            label,
            value,
          })
        }}
      >
        <option hidden>{placeholder || 'Selecione'}</option>
        {options?.map((item, index) => (
          <option key={index} value={JSON.stringify(item.value)}>
            {item.label}
          </option>
        ))}
        {!options?.length && <option hidden>Não há opções disponíveis</option>}
      </S.Select>
    </S.Container>
  )
}

export default LineSelect
