import React from 'react'

import useFlow from 'store'

import { Container, Dialog, RingLoad, Overlay, TextBox, Text } from './styles'

export default function Loading() {
  const {
    store: { loading },
  } = useFlow()

  return (
    <Container visible={loading.visible}>
      <Overlay />
      <Dialog>
        <RingLoad>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </RingLoad>
        <TextBox>
          <Text>{loading.text || 'Aguarde'}</Text>
          <Text>{loading.subText}</Text>
        </TextBox>
      </Dialog>
    </Container>
  )
}
