import styled from 'styled-components'

export const Container = styled.section`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-image: url('/images/maintence-background.jpg');
  background-size: cover;
`

export const Title = styled.h1`
  margin: 0;

  font-family: ${({ theme }) => theme.fonts.black};
  font-size: 80px;
  line-height: 122px;
  color: #ffffff;
`

export const Subtitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.black};
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;

  margin-bottom: 50px;
`

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
`
