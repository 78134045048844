import styled from 'styled-components'

import { breakpoints } from 'styles'

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? align : 'flex-end')};

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
    justify-content: center;
  }
`
