import styled from 'styled-components'

export const Container = styled.div`
  height: 70px;
  width: 100vw;

  display: flex;
  padding: 0 1rem;

  border-top: 4px solid ${({ theme }) => theme.colors.secondary};
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
`

export const Logo = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
  }
`
