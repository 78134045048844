import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
}

export async function firebaseInit() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  }
}

export async function getToken() {
  const currentUser = await firebase.auth().currentUser

  if (!currentUser) {
    await firebase.auth().signInAnonymously()
  }

  const token = await firebase.auth().currentUser.getIdToken(true)

  return token
}

firebaseInit()

export { firebase }
