import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'

import useFlow from 'store'
import { Alert, FloatingButton, Text } from 'components'
import { updateOrder } from 'services/api'
import { STATUS } from 'helpers'

import { Container, Message, Wrapper } from './styles'
import { Gallery, Main } from './components'
import validationSchema from './validation'
import Modal from 'components/Modal'
import { colors } from 'styles'
import { useErrorHandler } from 'store/orderRequest/hook'

export default function Vehicle() {
  const { store, setOrder, setLoading } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const history = useHistory()
  const sliderRef = useRef()
  const dataLayer = window.dataLayer || []

  const [values, setValues] = useState({})
  const [, setCurrentSlide] = useState(0)
  const [isVisible, setVisibility] = useState(false)
  const [modalHelp, setModalHelp] = useState(false)

  const {
    step2: { vehicles, index },
  } = store

  const vehicleOptions = vehicles.map((item, index) => ({
    value: index,
    label: item?.vehicle?.specification,
  }))

  const { background, images } = useMemo(() => {
    let background = '',
      images = []

    if (vehicles?.[0].vehicle?.model?.gallery?.length) {
      background = vehicles?.[0].vehicle?.model?.gallery?.[0].url
      images = vehicles?.[0].vehicle?.model?.gallery.map((image) => ({
        id: image.id,
        src: image.url,
      }))
    }

    return {
      background,
      images,
    }
  }, [vehicles])

  const settings = {
    dots: false,
    arrows: false,
    draggable: true,
    infinite: false,
    afterChange: (index) => {
      setCurrentSlide(index)
    },
    initialSlide: index || 0,
  }

  async function onSubmit() {
    const data = values
    try {
      await validationSchema.validate(data)
    } catch (error) {
      setVisibility(true)

      return
    }

    setLoading({ visible: true })

    try {
      delete data.period
      delete data.bundle
      const order = await updateOrder(store.order.id, {
        ...data,
        status: STATUS.PRODUCT,
      })

      const { product, summary } = order

      const totalPrice = summary.baseCost.rawValue * summary.months

      dataLayer.push({
        event: 'escolha_carro',
        retorno: 'sucesso',
        descricao: 'veiculo selecionado com sucesso',
        carro: {
          categoria: product.vehicle.category.slug,
          marca: product.vehicle.model.brand.title.toLowerCase(),
          id: product.id,
          nome: product.vehicle.model.specification,
          variacao: `${product.vehicle.specification} | ${summary.months} meses | ${summary.mileage} km`,
          preco: totalPrice,
        },
      })

      setOrder({ ...order })

      history.push('/step3')
    } catch (error) {
      console.error(error)

      dataLayer.push({
        event: 'escolha_carro',
        retorno: 'erro',
        carro: {
          categoria: '',
          marca: '',
          id: '',
          nome: '',
          variacao: '',
          preco: '',
        },
        erro: {
          codigo: error.code,
          servico: '/orders',
          mensagem: error.message,
        },
      })

      if (error.code === 409) {
        showModalHendler({ showModal: true })
      }
    } finally {
      setLoading({ visible: false })
    }
  }

  function goTo(index) {
    sliderRef.current.slickGoTo(index)
  }

  useEffect(() => {
    const vehicle = vehicles?.[0].vehicle.model.specification.toLowerCase()
    dataLayer.push({
      event: 'step_change',
      etapa: `veiculo/${vehicle}`,
    })
  }, [vehicles]) //eslint-disable-line

  return (
    <Container>
      <Slider {...settings} ref={sliderRef}>
        {vehicles?.map((item, index) => (
          <div key={item.id}>
            <Main
              version={item}
              background={background}
              setValues={setValues}
              values={values}
              index={index}
              goTo={goTo}
              vehicleOptions={vehicleOptions}
              setModalHelp={setModalHelp}
            />
            <Gallery data={images} />
          </div>
        ))}
      </Slider>

      <Modal
        title="Pacote de KM"
        visible={modalHelp}
        setIsVisible={setModalHelp}
      >
        <>
          <Text textType="tinyText" color={colors.gray.dark}>
            Você pode escolher entre 1.000, 1.500, 2.000, 2.5000 por mês. Seu
            pacote é distribuído por todo o período de seu contrato, podendo ser
            utilizado da maneira que preferir. Por exemplo: escolhendo um
            contrato de 12 meses e um pacote de 1.000km, você terá 12.000 kms
            disponíveis em seu contrato. Caso você faça uma viagem e gaste mais
            em um período, não se preocupe, pois o cálculo será feito apenas ao
            final do contrato.
          </Text>

          <Text textType="tinyText" color={colors.gray.dark}>
            Além disso, caso você perceba que a quilometragem contrata não foi
            ideal para o seu perfil, você pode contratar um pacote de
            quilometragem adicional.
          </Text>
        </>
      </Modal>

      <Wrapper>
        <FloatingButton
          rounded
          action={() => history.goBack()}
          color="white"
          text="Voltar"
          gtmName="voltar"
        />
        <FloatingButton
          rounded
          action={onSubmit}
          gtmName="quero-esse-carro"
          text="Quero este carro"
          gtmClickType="submit"
        />
      </Wrapper>

      <Alert
        isVisible={isVisible}
        actions={{
          primary: { onClick: () => setVisibility(false), label: 'Entendi' },
        }}
      >
        <Message>Campos obrigatórios não preenchidos!</Message>
      </Alert>
    </Container>
  )
}

Vehicle.properties = {
  pageStep: 'Veículo',
  showStep: false,
}
