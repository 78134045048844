import { rem } from 'polished'
import styled from 'styled-components'
import { colors } from 'styles'

export const CardTitle = styled.div`
  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-family: ${({ theme }) => theme.fonts.medium};
`

export const Card = styled.div`
  background: ${colors.white};
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  margin-bottom: 20px;
  padding: 15px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    margin: 15px;
  }
`

export const Label = styled.label`
  font-size: ${rem('10px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};
`

export const WrapForm = styled.div`
  margin: 15px 0;
`

export const Form = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const submitButtonStyle = {
  height: '115px',
}
