import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Container, Prefix } from './style'
import { LineInput } from 'components'
import { formatMoneyDecimalToBRL } from 'helpers'

const keysToIgnore = ['Backspace', 'Delete']
export default function LineInputMoney(props) {
  const { control } = useFormContext()

  return (
    <Container>
      <Prefix>R$</Prefix>
      <LineInput
        {...props}
        onChange={(inputed) => {
          const value = formatMoneyDecimalToBRL(
            inputed.target.value.replace(/\D/g, '')
          )

          if (control) {
            control.setValue(props.name, value)
          }

          return value
        }}
        onKeyDown={(e) => {
          if (/\D/.test(e.key) && !keysToIgnore.includes(e.key)) {
            e.preventDefault()
          }
        }}
      />
    </Container>
  )
}
