import { rem } from 'polished'
import styled from 'styled-components'

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-size: ${rem('16px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  margin: 0;
  margin-bottom: 7px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('24px')};
  }
`

export const SubTitle = styled.h2`
  color: ${({ theme }) => theme.colors.gray.light};
  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.book};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('20px')};
  }
`

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const WrapImage = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    width: 400px;
  }
`

export const Img = styled.img`
  width: 100%;
`

export const WrapLineInput = styled.div`
  margin: 10px 0;
`

export const Form = styled.form`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const WrapInfo = styled.div`
  margin-bottom: 40px;
`

export const ButtonLink = styled.button.attrs({
  type: 'button',
})`
  display: inline-block;
  height: 15px;
  width: 15px;

  cursor: pointer;

  border: none;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gray.medium};
`

export const DeliveryInfo = styled.div`
  cursor: pointer;

  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 12px;
  margin-bottom: 1rem;
  text-decoration: underline;
  transition: color 0.5s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: 14px;
  }
`

export const Deadline = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 18px;

  margin-bottom: 1rem;

  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};
`

export const DeadlineInfo = styled.div`
  p {
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.gray.medium};
    font-family: ${({ theme }) => theme.fonts.medium};
  }
`
