import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import { Icon } from 'components'
import { Container, Options, Option, Error, Loader } from './styles'

/**
 * @param {SelectProps} props
 */
export default function Select({
  options,
  color,
  isLoading,
  name,
  placeholder,
  disabled,
  block,
  selected,
  gtmName,
  ...props
}) {
  const { register, errors = {}, getValues } = useFormContext() || {}

  return (
    <Container
      selected={selected}
      block={block}
      color={color}
      disabled={disabled}
    >
      <Options
        {...props}
        name={name}
        ref={register}
        disabled={disabled || isLoading}
        data-gtm-type="click"
        data-gtm-clicktype="select"
        data-gtm-subname={`${getValues(name)}`}
        data-gtm-name={`${
          gtmName || placeholder?.toLowerCase() || name?.toLowerCase()
        }`}
      >
        <Option hidden value="">
          {placeholder ? placeholder : 'Selecione'}
        </Option>

        {options?.map(({ value, label }, index) => (
          <Option value={value} key={index}>
            {label}
          </Option>
        ))}
      </Options>

      {isLoading ? <Loader /> : <Icon name="chevron-down" />}

      <ErrorMessage errors={errors} name={name} as={<Error />} />
    </Container>
  )
}
