import styled, { css } from 'styled-components'

const sharedFlex = css`
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  ${sharedFlex}
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.book};
  color: #fff;

  margin-bottom: 1rem;
`

export const Text = styled.p`
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.book};
  color: #fff;
  margin-bottom: 1rem;
`

export const Badge = styled.img``
