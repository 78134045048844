import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import Resizer from 'react-image-file-resizer'

import * as S from './styles'

const images = ['.jpeg', '.png']
const FILE_TYPES = {
  image: {
    'image/*': images,
  },
  pdf: '.pdf',
}

const MAX_SIZE_MB = 10
const MAX_SIZE = MAX_SIZE_MB * 1024 * 1024

const LineInputUpload = ({ label, name, maxFiles, acceptFiles, gtmName }) => {
  const [files, setFiles] = useState([])
  const { setValue, register, errors, setError } = useFormContext()

  useEffect(() => {
    setValue(name, files)
  }, [files, name, setValue])

  const containError = useCallback(() => {
    if (!errors || !errors[name]) {
      return false
    }

    return errors[name]?.message
  }, [errors, name])

  // const toBase64 = useCallback(
  //   (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader()
  //       reader.readAsDataURL(file)
  //       reader.onload = () => resolve(reader.result)
  //       reader.onerror = (error) => reject(error)
  //     }),
  //   []
  // )

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1920,
        1080,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })

  const onDrop = useCallback(async (acceptedFiles) => {
    const rows = []
    for (const key in acceptedFiles) {
      if (Object.hasOwnProperty.call(acceptedFiles, key)) {
        const element = acceptedFiles[key]
        // const file = await toBase64(element)
        const fileResized = await resizeFile(element)

        rows.push({
          name: element.name,
          size: element.size,
          type: element.type,
          base64: fileResized,
        })
      }
    }
    setFiles(rows)
  }, [])

  const filesAccepted = useMemo(() => {
    if (FILE_TYPES.hasOwnProperty(acceptFiles)) {
      return FILE_TYPES[acceptFiles]
    }

    return '*'
  }, [acceptFiles])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles,
    accept: filesAccepted,
    maxSize: MAX_SIZE,
  })

  useEffect(() => {
    const errorsMap = {
      'file-too-large': `O arquivo deve ter menos que ${MAX_SIZE_MB}MB`,
      'file-invalid-type': 'Formato de arquivo inválido',
    }

    if (fileRejections.length) {
      const fileRejection = fileRejections[0]
      const error = fileRejection?.errors[0].code
      setError(name, {
        type: 'custom',
        message: errorsMap[error] || 'Erro na validação',
      })
    }
  }, [fileRejections, name, setError])

  const renderMessages = useCallback(() => {
    if (isDragReject) {
      return (
        <S.Text>
          Tipo de arquivo não suportado, ele deve ser {images.join(' ou ')}
        </S.Text>
      )
    }

    if (isDragActive) {
      return <S.Text>Solte o arquivo aqui</S.Text>
    }

    return (
      <S.Text>Clique aqui para adicionar o arquivo, ou arraste aqui</S.Text>
    )
  }, [isDragActive, isDragReject])

  const renderImage = useCallback(
    (data, index) => {
      return (
        <S.Item key={index}>
          <S.RemoveItem
            onClick={() => {
              let file = [...files]
              file.splice(index, 1)
              setFiles(file)
            }}
          />
          <img src={data.base64} alt="" />
        </S.Item>
      )
    },
    [files]
  )

  const renderItems = useCallback(() => {
    const rows = []
    for (const key in files) {
      const element = files[key]

      if (element.type.includes('image')) {
        rows.push(renderImage(element, key))
      }
    }

    return rows
  }, [files, renderImage])

  return (
    <S.Content>
      <input
        type="hidden"
        {...register(name)}
        data-gtm-name={gtmName}
        data-gtm-type="click"
        data-gtm-clicktype="link"
      />
      <S.Wrap {...getRootProps()} containError={containError()}>
        <S.Label>{label}</S.Label>
        <input {...getInputProps()} />

        {renderMessages()}
      </S.Wrap>
      <S.Error>{containError() || ''}</S.Error>

      <S.WrapImage>{renderItems()}</S.WrapImage>
    </S.Content>
  )
}

export default LineInputUpload
