import { Container as LineInput } from 'components/LineInput/styles'
import styled from 'styled-components'

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 16px;
  }
`

export const TextHeader = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 18px;
  }
`

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px;
`

export const Line = styled.div`
  height: 1px;
  width: 100%;

  margin: 15px 0;

  background-color: ${({ theme }) => theme.colors.gray.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 20px 0;
  }
`

export const Field = styled.div`
  margin: 20px 0;
`

export const Form = styled.form`
  height: 100%;
`

export const WrapInlineFields = styled.div`
  display: flex;
  gap: 10px;

  ${LineInput} {
    width: 50%;
  }
`

export const WrapButton = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;

  justify-content: flex-end;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.small}) {
    flex-direction: row;
  }
`
