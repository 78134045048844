import api from 'services/api'

export async function createOrder({ origin, ...rest }) {
  try {
    const response = await api.post(`/integracao/orders/create/${origin}`, {
      ...rest,
    })

    return response.data
  } catch (error) {
    const err = new Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}

export async function getOrderDetails(id) {
  try {
    const response = await api.get(`/integracao/orders/${id}`)

    return response.data
  } catch (error) {
    const { data } = error.response

    const err = new Error(
      data?.message
        ? data?.message
        : 'Ocorreu um erro ao analisar a selfie. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}

export async function getOrdersByUser(document, recaptcha) {
  try {
    const response = await api.get(
      `/integracao/orders?customer.cpf.number=${document}`,
      {
        headers: {
          recaptcha,
        },
      }
    )

    return response.data
  } catch (error) {
    throw error
  }
}

export async function getOrderById(order) {
  try {
    const response = await api.get(`/integracao/orders/lastOrder`, {
      headers: {
        user_authorization: order,
      },
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function updateOrder(hash, data) {
  try {
    const response = await api.put(`/integracao/orders/${hash}`, data)

    return response.data
  } catch (error) {
    const err = new Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}

export async function isEligibleOfferProduct({ cep, id }) {
  const payload = {
    order: {
      id: id,
    },
    cep,
  }

  try {
    const response = await api.post(`/integracao/orders/offer`, payload)

    const status = response.data.decision

    return status
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Bank.'
    )
  }
}

export async function checkDiscount({ registration, id }) {
  try {
    const payload = {
      order: { id },
      registration,
    }

    const response = await api.post(
      `/integracao/orders/backend/check-for-discount`,
      payload
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Bank.'
    )
  }
}

export async function getUserAnalysis(id) {
  try {
    const payload = {
      order: { id },
    }

    const response = await api.post(`/integracao/orders/analyse`, payload)

    return response.data
  } catch (error) {
    const err = new Error(
      'Houve um problema ao realizar a análise, tente novamente.'
    )

    err.code = error.response.status

    throw err
  }
}

export async function validateClientByPhone(customer, payload) {
  try {
    const response = await api.post(`/ca_validity/verifyNumber/${customer}`, {
      lastFour: payload,
    })

    return response
  } catch (error) {
    const err = new Error(
      'Não foi possível validar esse código no momento. Tente novamente mais tarde.'
    )

    err.retries = error.response.data.retries
    err.message = `Dados incorretos, vocês possui mais ${
      3 - error.response.data.retries
    } tentativas.`

    throw err
  }
}
