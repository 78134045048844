import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px 15px 30px;
  width: 100%;
  height: 350px;

  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;

  transition: transform 0.3s, box-shadow 0.2s;
  will-change: transform;

  &:hover {
    transform: translate(3px, -3px);
    box-shadow: -2px 2px 10px 2px rgb(0 0 0 / 20%);
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.small}) {
    width: 265px;
  }
`

export const Title = styled.p`
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  font-family: ${({ theme }) => theme.fonts.black};
`

export const Version = styled.p`
  font-size: 12px;
  line-height: 12px;
  font-family: ${({ theme }) => theme.fonts.book};
  color: #696969;
`

export const Image = styled.img`
  width: 100%;
  height: 160px;

  object-fit: cover;
`

export const Label = styled.p`
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: rgba(51, 51, 51, 0.5);
  font-family: ${({ theme }) => theme.fonts.book};
`

export const Price = styled.p`
  font-family: ${({ theme }) => theme.fonts.black};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 30px;
`

export const Text = styled.p`
  margin-right: 5px;

  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;
`
