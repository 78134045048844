import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  padding: 10px 30px;
  display: flex;
  align-items: center;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export const Image = styled.img`
  height: 100%;

  object-fit: cover;
  transition: 0.8s cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    transform: scale(1.1);
    transition: 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  }
`

export const Item = styled.div`
  width: 100%;

  height: 150px;
  overflow: hidden;

  cursor: pointer;
`
