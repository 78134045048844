import api from 'services/api'

export async function getDisponibilityZones() {
  try {
    const response = await api.get('/integracao/disponibility-zones')

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Bank.'
    )
  }
}
