import api from 'services/api'

export async function checkIsElegiblePortoCard(payload, isMocked = false) {
  if (isMocked === 'elegible') {
    return {
      status: 'AVALIABLE',
    }
  } else if (isMocked === 'credit-porto') {
    return {
      status: 'NOT AVALIABLE',
    }
  }

  try {
    const response = await api.post(`/integracao/cards/is-elegible`, payload)

    return response.data
  } catch (error) {
    const { data } = error.response

    const err = new Error(
      data?.message
        ? data?.message
        : 'Ocorreu um erro. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}

export async function getPortoOffers(id, isMocked = false) {
  try {
    if (isMocked === 'elegible' || isMocked === 'credit-porto') {
      return {
        status: 'AVALIABLE',
        logos: [
          {
            logo: 'MASTERCARD',
            logo_code: 2,
            logo_type: 'GOLD',
            logo_limit: 4000,
          },
        ],
      }
    }

    const response = await api.get(`/integracao/cards/offers`, {
      params: {
        order_id: id,
      },
    })

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente em alguns instantes.'
    )
  }
}

export async function getPortoCardCustomer(id, isMocked = false) {
  try {
    if (isMocked === 'credit-porto') {
      return [
        {
          number: '00001234',
        },
      ]
    }

    const response = await api.get('/integracao/cards/company-card', {
      params: { order_id: id },
    })

    return response.data
  } catch (error) {
    console.log('error::::', error)
    return []
  }
}
