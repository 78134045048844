import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, ChatBox, Container, Modal, Text } from 'components'

import { checkIsElegiblePortoCard, getPortoCardCustomer } from 'services/api'
import useFlow from 'store'
import { formatMoney, updateCustomData } from 'utils'

import * as S from './styles'
import { useErrorHandler } from 'store/orderRequest/hook'

const SelectTypeCreditCard = () => {
  const { dataLayer = [] } = window

  const history = useHistory()

  const [visible, setIsVisible] = useState(false)
  const [countRequests, setCountRequests] = useState(0)

  const {
    store: { order, portoCard, isMocked, isElegiblePortoCard },
    setLoading,
    setPortoCard,
    setIsElegiblePortoCard,
  } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const portoCardAvaliable = useMemo(() => {
    return portoCard.length || isElegiblePortoCard
  }, [isElegiblePortoCard, portoCard.length])

  const checkIsElegible = async () => {
    try {
      setIsVisible(false)
      setLoading({ visible: true, text: 'Buscando ofertas' })

      const responseIsElegible = await checkIsElegiblePortoCard(
        {
          order_id: order?.id,
        },
        isMocked
      )

      const cards = await getPortoCardCustomer(order?.id, isMocked)

      if (cards.length) {
        setPortoCard(cards)
      }

      if (responseIsElegible?.status === 'AVALIABLE') {
        setIsElegiblePortoCard(true)
        history.push('/cartao-porto')
        return
      } else if (!cards.length) {
        history.push('/selecionar-cartao-credito')
        return
      }
    } catch (e) {
      setCountRequests((value) => value + 1)

      if (e.code === 409) {
        showModalHendler({ showModal: true })
        return
      }

      setIsVisible(true)
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }

  const closeModal = () => {
    if (countRequests <= 3) {
      checkIsElegible()
    }
  }

  useEffect(() => {
    checkIsElegible()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'selecao-cartao',
      },
      carro: {
        categoria: order?.product.vehicle.category.slug,
        marca: order?.product.vehicle.model.brand.title.toLowerCase(),
        id: order?.product.id,
        nome: order?.product.vehicle.model.specification,
        variacao: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
        preco: formatMoney(
          order?.summary.baseCost.rawValue * order?.summary.months
        ),
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkout/pagamento/metodo',
    })
  }, [dataLayer, order])

  const creditCardText = useMemo(() => {
    if (portoCardAvaliable) {
      return 'Outro Cartão de Crédito'
    }

    return 'Cartão de Crédito'
  }, [portoCardAvaliable])

  return (
    <Container>
      <div>
        <ChatBox
          text={`<strong class="text-druk">Olá ${order?.customer?.firstName}!</strong> Qual destas formas de pagamento você
            prefere?`}
        ></ChatBox>

        <S.WrapAdice>
          {portoCardAvaliable && (
            <S.Text>
              Pague com o Cartão Porto Bank e ganhe R$ 150 de desconto em uma
              das 3 primeiras parcelas do seu Carro por Assinatura Porto Bank
              Bank!
            </S.Text>
          )}
        </S.WrapAdice>

        <S.WrapCreditCard>
          {portoCardAvaliable && (
            <S.CreditCardPorto
              onClick={() => {
                let url = '/porto-payment'
                if (isElegiblePortoCard && !portoCard.length) {
                  url = '/cartao-porto'
                }
                history.push(url)
              }}
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-nam="meu-cartao-porto"
            >
              Meu Cartão
              <img src="/images/porto-icon-white.svg" alt="logo porto" />
            </S.CreditCardPorto>
          )}
          <S.CreditCard
            onClick={() => {
              history.push('/selecionar-cartao-credito')
            }}
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-nam="outro-cartao-de-credito"
          >
            {creditCardText}
          </S.CreditCard>
        </S.WrapCreditCard>

        <Modal
          title="Erro"
          visible={visible}
          setIsVisible={setIsVisible}
          onClickCloseButton={() => {}}
          buttonClose={() => (
            <Button onClick={closeModal} disabled={countRequests > 3}>
              Tentar novamente
            </Button>
          )}
        >
          <Text>
            Ocorreu um erro ao buscar ofertas!
            <br />
            {countRequests > 3 ? 'Entre em contato com o suporte!' : ''}
          </Text>
        </Modal>
      </div>
    </Container>
  )
}

export default SelectTypeCreditCard
