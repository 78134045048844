import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { ChatBox, FloatingButton, Container } from 'components'
import { BROWSERS_ALLOWED } from 'helpers'

import { sendAnalyzeSelfSMS } from 'services/api'
import useFlow from 'store'

import {
  checkUserWebcam,
  detectUserBrowser,
  getUserCameraPermission,
  userCanUseSDK,
} from 'utils'

import * as S from './styles'
import { useErrorHandler } from 'store/orderRequest/hook'

const SelfPermission = () => {
  const history = useHistory()
  const captcha = useGoogleReCaptcha()

  const [isSDK, setIsSDK] = useState(true)
  const {
    store: { order },
    setOrder,
    setLoading,
  } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const handleRedirectPage = async () => {
    if (isSDK) {
      history.push('/captura-selfie')
      return
    }

    try {
      setLoading({ visible: true, text: '' })
      const response = await captcha.executeRecaptcha()
      const process = await sendAnalyzeSelfSMS(order?.id, response)

      setOrder({
        ...order,
        processSelfie: {
          ...process,
        },
      })
      history.push('/envio-selfie')
    } catch (error) {
      console.error(error)

      if (error.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }

  const getUserPermission = async () => {
    const permission = await getUserCameraPermission()

    if (!permission) {
      setIsSDK(false)
      return
    }
  }

  const handleSDKorSMS = useCallback(async () => {
    const hasWebcam = await checkUserWebcam()

    if (!hasWebcam) {
      setIsSDK(false)
      return
    }

    if (userCanUseSDK() && BROWSERS_ALLOWED.includes(detectUserBrowser())) {
      getUserPermission()
      return
    }

    setIsSDK(false)
  }, [])

  useEffect(() => {
    handleSDKorSMS()
  }, [handleSDKorSMS])

  return (
    <Container
      padding={[
        [45, 30, 20],
        [40, 18, 0],
      ]}
    >
      <div>
        <ChatBox
          text={`
            Olá ${order?.customer?.firstName}, para continuarmos você precisa enviar sua selfie, caso esteja no computador e não possui webcam fique tranquilo que enviaremos um SMS para que possa realizar a captura através do seu celular.      
          `}
        />
      </div>

      <S.Card>
        <S.CardContent>
          <S.IconWrapper>
            <img
              src="/images/circle-approved.svg"
              alt="icone do que devemos usar na selfie"
            />
            <div>
              <img src="/images/webcam.svg" alt="icone de webcam" />
              <p>
                Tire a foto com o rosto de frente para a câmera frontal do seu
                celular.
              </p>
            </div>
          </S.IconWrapper>
          <S.IconWrapper>
            <img
              src="/images/circle-denied.svg"
              alt="icone o que não usar para a selfie"
            />
            <div>
              <img src="/images/glasses-hat.svg" alt="icone de boné, óculos" />
              <p>
                Ao realizar a selfie evite utilizar boné, chapéu, óculos ou
                qualquer coisa que cubra seu rosto.
              </p>
            </div>
          </S.IconWrapper>
        </S.CardContent>
      </S.Card>

      <FloatingButton
        rounded
        gtmName="prosseguir"
        action={handleRedirectPage}
        text="Prosseguir"
      />
    </Container>
  )
}

export default SelfPermission
