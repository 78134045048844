import { rem } from 'polished'
import styled, { css } from 'styled-components'
import { colors } from 'styles'

export const Container = styled.div``

export const Select = styled.select`
  width: 100%;
  cursor: pointer;

  padding: 2px 2px;

  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  background: transparent;

  color: ${({ theme }) => theme.colors.gray.medium};
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.medium};

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  ${({ containError }) =>
    containError &&
    css`
      color: ${colors.danger};
      border-color: ${colors.danger};
    `}

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const Label = styled.label`
  font-size: ${rem('10px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};
  padding-left: 4px;

  ${({ containError }) =>
    containError &&
    css`
      color: ${colors.danger};
    `}

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('14px')};
  }
`
