import React, { useState, useCallback } from 'react'

import useFlow from 'store'

import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { isMobile } from 'react-device-detect'

import schema from './schema'

import { FormProvider, useForm } from 'react-hook-form'

import { LineInput, Button, Modal, Text, ChatBox } from 'components'

import { postResetPassword } from 'services/api'

import * as S from './styles'

function ResetPassword() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalChindren, setModalChildren] = useState()
  const [isFailed, setIsFailed] = useState(false)

  const history = useHistory()
  const {
    setLoading,
    store: { loading },
  } = useFlow()

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  async function onSubmit(data) {
    setLoading({ visible: true, text: '' })
    setIsFailed(false)
    try {
      const token = window.location.pathname.split('password/')

      if (!token[1]) {
        history.push('/')
      }

      await postResetPassword(data, token[1])
      setModalChildren('Senha alterada com sucesso.')
      setIsModalVisible(true)
    } catch (err) {
      setIsFailed(true)
      setModalChildren(
        'Houve um problema com a alteração da sua senha por favor, tente novamente mais tarde.'
      )
      setIsModalVisible(true)
      throw Error(
        'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Bank.'
      )
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }

  const handleRedirect = useCallback(() => {
    if (!isFailed) {
      setLoading({ visible: true, text: '' })
      setIsModalVisible(false)
      if (isMobile) {
        window.location = 'carrofacil://carrofacil/initialLoading'
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_URL_SITE
        }, 500)
      } else {
        window.location.href = process.env.REACT_APP_URL_SITE
      }
    }
  }, [isFailed, setLoading])

  return (
    <S.WrapperForm>
      <Modal
        title="Reset de senha"
        visible={isModalVisible}
        buttonClose={() => (
          <Button onClick={() => handleRedirect()} type="button" height="40px">
            Fechar
          </Button>
        )}
        onClickCloseButton={() => {
          handleRedirect()
          setIsModalVisible(!isModalVisible)
        }}
      >
        <Text>{modalChindren}</Text>
      </Modal>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <S.Container>
            <ChatBox text="Reset de senha" />
            <LineInput
              label="Senha"
              placeholder="Digite a nova senha"
              name="password"
              type="password"
              isPassword
            />

            <LineInput
              label="Confirme a senha"
              placeholder="Digite a nova senha novamente"
              name="password_confirmation"
              type="password"
              isPassword
            />

            <S.WrapperButton>
              <Button isLoading={loading.visible} type="submit" height="40px">
                Enviar
              </Button>
            </S.WrapperButton>
          </S.Container>
        </form>
      </FormProvider>
    </S.WrapperForm>
  )
}

export default ResetPassword
