import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;

  padding: 70px 16px 20px;

  background-image: url('/images/analise.png');
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    padding: 70px 50px 30px;
  }
`

export const Content = styled.div``

export const Text = styled.p`
  margin-bottom: 20px;

  font-family: ${({ theme }) => theme.fonts.book};
  color: #fff;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: 20px;
  }
`

export const Badge = styled.img``
