import api from 'services/api'

export async function createCustomer(document) {
  const PAYLOAD_PARSE = {
    cpf: {
      number: document,
    },
  }

  try {
    const response = await api.post('/integracao/users', PAYLOAD_PARSE)

    return response.data
  } catch (error) {
    console.error(error)

    throw Error(
      'Houve uma instabilidade no sistema. Por favor tente novamente em alguns instantes.'
    )
  }
}

export async function getCustomer(params) {
  const { document, id } = params

  const PARAMS_PARSED = {
    'cpf.number': document,
    id,
  }

  try {
    const response = await api.get('/integracao/customers', {
      params: PARAMS_PARSED,
    })

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Bank.'
    )
  }
}

export async function updateCustomer(hash, data) {
  try {
    const response = await api.put(`/integracao/customers/${hash}`, data)

    return response.data
  } catch (error) {
    const err = new Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}

export async function getIfUserExists(document, recaptcha) {
  try {
    const response = await api.get(
      `/ca_validity/verifyUserExists/${document}`,
      {
        headers: {
          recaptcha,
        },
      }
    )

    return response
  } catch (error) {
    const err = new Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}
