import { checkFullName, isAfterDate } from 'utils'
import * as yup from 'yup'

export default yup.object().shape({
  cardName: yup
    .string()
    .required('O nome é obrigatório')
    .test('checkFullName', 'Por favor insira o nome completo', (value) =>
      checkFullName(value)
    ),
  cardNumber: yup
    .string()
    .required('O número é obrigatório')
    .test('number-valid', 'A numeração está incorreta', (value) => {
      const regex = RegExp(/^[0-9]{13,19}$/)

      return regex.test(value)
    }),
  cardVality: yup
    .string()
    .required('A validade é obrigatório')
    .test('number-valid', 'A data está incorreta', (value) =>
      isAfterDate(value, new Date(), 'MM/YYYY')
    ),
  cardCVV: yup
    .string()
    .required('O CVV é obrigatório')
    .test('number-valid', 'A numeração está incorreta', function (value) {
      const regex = /^3[47][0-9]{13}$/ // Cartão AMEX

      if (regex.test(this?.parent?.cardNumber)) {
        if (value.length === 4) {
          return true
        }

        return false
      }

      if (value.length === 3) {
        return true
      }

      return false
    }),
})
