import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'components'
import useFlow from 'store'
import { STATUS, RESET_BIRTH_DATE, prepareToRedirect } from 'helpers'
import { createOrder, updateCustomer, updateOrder } from 'services/api'
import { updateCustomData, encryptTextWithKey, getSucursal } from 'utils'

import {
  Bold,
  Container,
  Content,
  Overlay,
  Text,
  Title,
  Wrapper,
  WrapButtons,
} from './styles'
import { useErrorHandler } from 'store/orderRequest/hook'

export default function Orders() {
  const [overlay, setOverlay] = useState({ visible: false })

  const { store, setOrder, setLoading, setStep1Info } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const history = useHistory()
  const dataLayer = window.dataLayer || []

  function continueFlow() {
    const { customer } = store.order

    updateCustomData({
      user: {
        id_proposta: store.order.id,
        user_id: encryptTextWithKey(store.order.customer.cpf.number, 'chave'),
        id_crm: store.order.customer.id,
        origem: store.order?.origin,
        susep: store.order.representative.broker?.susep || '',
      },
    })

    const redirectTo = prepareToRedirect(
      store.order.status,
      customer,
      store?.order
    )

    history.push(redirectTo)
    return
  }

  async function startOver() {
    setLoading({ visible: true })

    const defaultAddress = {
      cep: '',
      number: '',
      state: '',
      city: '',
      neighborhood: '',
      street: '',
    }
    const cnh = {
      number: '',
      expiration: '',
      emission: '',
      firstCNH: '',
      backCNH: '',
      frontCNH: '',
      picture: '',
    }
    const rg = {
      number: '',
      issueDate: '',
      issuingAgency: '',
    }

    const payload = {
      fullName: '',
      email: '',
      cellphone: '',
      // NAO MUDAR ISSO É USADO PARA
      // O CHAT OFFER PEGUNTAR A DATA
      // SIM... GAMBETA
      birthdate: RESET_BIRTH_DATE,
      picture: '',
      income: '',
      occupation: '',
      company: '',
      gender: '',
      marital: '',
      motherName: '',
      signature: '',
      defaultAddress,
      cnh,
      rg,
    }

    const {
      cpf: { number: document },
      fullName: name,
      email,
      defaultAddress: { cep },
      cellphone,
      birthdate,
    } = store.order.customer

    setStep1Info({ document, name, email, cep, cellphone, birthdate })

    try {
      await updateOrder(store.order.id, {
        status: STATUS.CANCELED,
      })

      await updateCustomer(store.order.customer.id, payload)

      const { order } = await createOrder({
        user: { cpf: { number: store.order.customer.cpf.number } },
        representative: store.order.representative.id,
        origin: 'omnichannel',
      })

      updateCustomData({
        user: {
          id_proposta: order.id,
          user_id: encryptTextWithKey(store.order.customer.cpf.number, 'chave'),
          id_crm: order.customer.id,
          susep: order.representative?.broker?.susep || '',
          origem: 'omnichannel',
        },
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'proposta',
        tipo_servico: 'confirmar-exclusao',
        retorno: 'sucesso',
        descricao: 'comecar-nova-jornada',
        lead_id: store?.order.id,
        client_id: store?.order.customer.id,
        origem: store?.order?.origin,
        sucursal: getSucursal(store?.order?.representative?.broker?.branch),
        product: 'carro-facil',
        susep: store?.broker?.id,
      })

      setOrder({ ...order })
      history.push('/step1')
    } catch (error) {
      console.error(error)

      if (error.code === 409) {
        showModalHendler({ showModal: true })
      }

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'proposta',
        tipo_servico: 'confirmar-exclusao',
        retorno: 'erro',
        descricao: 'instabilidade-no-sistema',
        lead_id: store?.order.id,
        client_id: store?.order.customer.id,
        origem: store?.order?.origin,
        sucursal: getSucursal(store?.order?.representative?.broker?.branch),
        product: 'carro-facil',
        susep: store?.broker?.id,
        erro: {
          codigo: error.code,
          servico: '/orders',
          mensagem: error.message,
        },
      })
    } finally {
      setLoading({ visible: false })
    }
  }

  function openOverlay() {
    setOverlay((state) => ({ ...state, visible: true }))
  }

  function closeOverlay() {
    setOverlay((state) => ({ ...state, visible: false }))
  }

  useEffect(() => {
    if (overlay.visible) {
      dataLayer.push({
        event: 'step_change',
        etapa: '/evy/deletar-proposta',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlay.visible])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'orders-check',
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/evy/assinatura-em-andamento',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Content>
        <Title>
          <Bold>Oi {store.order?.customer?.firstName || 'Cliente'}!</Bold>
        </Title>
        <Text>
          Vi aqui que você já iniciou a sua jornada até seu Carro por Assinatura
          Porto Bank...
        </Text>
        <Text>O que você quer fazer agora?</Text>

        <WrapButtons>
          <Button
            color="white"
            onClick={openOverlay}
            gtmName="comecar-uma-assinatura-nova"
          >
            Começar uma jornada nova
          </Button>
          <Button
            onClick={continueFlow}
            color="white"
            gtmName="retomar-assinatura-anterior"
          >
            Continuar de onde parei
          </Button>
        </WrapButtons>
      </Content>

      <Overlay onClick={closeOverlay} visible={overlay.visible}>
        <Wrapper>
          <Text>
            Olá, <Bold>{store.order?.customer?.firstName || 'Cliente'}!</Bold>
          </Text>
          <Text>
            Você realmente quer deletar a jornada anterior e iniciar um nova?
          </Text>
          <WrapButtons>
            <Button onClick={startOver}>Sim, eu quero!</Button>
            <Button color="white" onClick={closeOverlay}>
              Não
            </Button>
          </WrapButtons>
        </Wrapper>
      </Overlay>
    </Container>
  )
}

Orders.properties = {
  pageStep: 'Orders',
  showStep: false,
}
