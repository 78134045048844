import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import { updateCustomer, updateOrder } from 'services/api'
import { STATUS } from 'helpers'

import {
  FloatingButton,
  LineInput,
  Grid,
  Container,
  ChatBox,
  Text,
} from 'components'
import useFlow from 'store'

import { Content, Form } from './styles'
import validationSchema from './validation'
import { encryptTextWithKey, updateCustomData } from 'utils'
import { useErrorHandler } from 'store/orderRequest/hook'

export default function Step4() {
  const { store, setOrder } = useFlow()
  const { showModalHendler } = useErrorHandler()
  const { dataLayer = [] } = window

  const forms = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  })
  const history = useHistory()
  const [loaders, setLoaders] = useState({ doc: false, page: false })

  const onSubmit = forms.handleSubmit(async ({ defaultAddress }) => {
    setLoaders((state) => ({ ...state, page: true }))

    try {
      const [, orders] = await Promise.all([
        updateCustomer(store.order.customer.id, {
          defaultAddress,
        }),
        updateOrder(store.order.id, {
          status: STATUS.PERFIL,
        }),
      ])

      setOrder({ ...orders })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'cadastrar',
        tipo_servico: 'endereco',
        client_id: encryptTextWithKey(orders.customer.cpf.number, 'chave'),
        origem: orders.origin,
        susep: orders.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'sucesso',
        descricao: 'Lead enviado com sucesso',
      })
      history.push('/analysis')
    } catch (error) {
      console.error(error)

      if (error.code === 409) {
        showModalHendler({ showModal: true })
      }
      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'cadastrar',
        tipo_servico: 'endereco',
        client_id: encryptTextWithKey(store.order.customer.cpf.number, 'chave'),
        origem: store.order.origin,
        susep: store.order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'error',
        erro: {
          codigo: error.code,
          servico: '',
          mensagem: error.message,
        },
      })
    } finally {
      setLoaders((state) => ({ ...state, page: false }))
    }
  })

  const defaultAddress = useMemo(() => {
    return store?.order?.customer?.defaultAddress
  }, [store?.order?.customer?.defaultAddress])

  useEffect(() => {
    const {
      order: { customer },
    } = store

    const fields = [
      { name: 'defaultAddress.cep', value: customer?.defaultAddress.cep },
      { name: 'defaultAddress.street', value: customer?.defaultAddress.street },
      {
        name: 'defaultAddress.neighborhood',
        value: customer?.defaultAddress.neighborhood,
      },
      { name: 'defaultAddress.city', value: customer?.defaultAddress.city },
      { name: 'defaultAddress.state', value: customer?.defaultAddress.state },
    ]

    fields.forEach(({ name, value }) => forms.setValue(name, value))
  }, []) //eslint-disable-line

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'step4',
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/evy/endereco',
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Content>
        <ChatBox
          text={`${store.order?.customer?.firstName}, falta pouco! Preencha os dados a seguir, para que possamos dar continuidade à análise do seu perfil`}
        />

        <FormProvider {...forms}>
          <Form onSubmit={onSubmit}>
            <Text>Dados pessoais</Text>
            <Grid mb="16px" gridGap="16px" gridTemplateColumns="1fr 40%">
              <LineInput
                name="defaultAddress.cep"
                placeholder="CEP"
                label="CEP"
                mask="#####-###"
                disabled
              />
              <LineInput
                name="defaultAddress.number"
                placeholder="Digite aqui"
                label="Número*"
                numericOnly
              />
            </Grid>
            <Grid mb="16px" gridGap="16px" gridTemplateColumns="1fr">
              <LineInput
                name="defaultAddress.street"
                placeholder="Digite aqui"
                label="Rua"
                disabled={!!defaultAddress.street}
              />
              <LineInput
                name="defaultAddress.neighborhood"
                placeholder="Digite aqui"
                label="Bairro"
                disabled={!!defaultAddress.neighborhood}
              />
              <LineInput
                name="defaultAddress.complement"
                placeholder="Digite aqui"
                label="Complemento (se houver)"
              />
            </Grid>

            <Grid mb="16px" gridGap="16px" gridTemplateColumns="1fr 20%">
              <LineInput
                name="defaultAddress.city"
                placeholder="Digite aqui"
                label="Cidade"
                disabled={!!defaultAddress.city}
              />
              <LineInput
                name="defaultAddress.state"
                placeholder="Digite aqui"
                label="Estado"
                maxLength="2"
                toUpperCase
                disabled={!!defaultAddress?.state}
              />
            </Grid>
          </Form>
        </FormProvider>
      </Content>
      <FloatingButton
        rounded
        isLoading={loaders.page}
        text="Avançar"
        action={onSubmit}
        gtmClickType="submit"
        height="57px"
      />
    </Container>
  )
}

Step4.properties = {
  pageStep: 'Endereço',
  showStep: true,
}
