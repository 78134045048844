import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;

  height: 100%;
  padding: 50px 30px 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: center;
  background-image: url('/images/check-background.png');
`

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 35px;
`

export const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.black};
`
export const Flex = styled.div`
  align-self: center;
  display: flex;
  gap: 35px;
`

export const Title = styled.p`
  font-size: 25px;
  line-height: 26px;
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.book};
`

export const Text = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  font-family: ${({ theme }) => theme.fonts.book};
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.95);
  opacity: 0;
  visibility: hidden;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`
export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  padding: 20px;
`

export const WrapButtons = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  width: 100%;

  gap: 15px;
  margin-top: 20px;

  button {
    box-sizing: border-box;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    button {
      width: 100%;
      height: 40px;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    gap: 20px;
  }
`
