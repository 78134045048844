import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  padding: 70px 25px 18px;
  /* min-height: 100%; */

  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.small}) {
    /* height: 100%; */
    padding: 70px 30px 30px;
  }
`

export const Content = styled.div`
  width: 100%;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.small}) {
    height: 100%;
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${rem('16px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  margin-bottom: 30px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('20px')};
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  margin-bottom: 20px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const SubTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.gray.medium};
`

export const WrapCNH = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    display: flex;
    justify-content: space-between;
  }
`

export const CHNItem = styled.div`
  width: 100%;
  padding: 30px 0;

  &:not(:last-item) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    width: 40%;

    border-bottom: none;
    padding: 0;
  }
`

export const Form = styled.form`
  height: 100%;
`

export const WrapImage = styled.div`
  height: 284px;
  width: 200px;
  margin: auto;

  img {
    width: 100%;
  }
`
