import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useFlow from 'store'
import {
  Flex,
  FloatingButton,
  Grid,
  Switch,
  Text as TextComp,
} from 'components'

import { Container, Content, Text, Title, Form } from './styles'
import {
  createOrder,
  isEligibleOfferProduct,
  updateCustomer,
} from 'services/api'
import { RESET_BIRTH_DATE, STATUS } from 'helpers'
import { encryptTextWithKey, getSucursal, updateCustomData } from 'utils'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import validationSchema from './validation'
import Modal from 'components/Modal'

export default function Consent() {
  const forms = useForm({ resolver: yupResolver(validationSchema) })

  const history = useHistory()
  const { setLoading, setOrder, store } = useFlow()

  const [errorModal, setErrorModal] = useState('')

  const { dataLayer = [] } = window

  const onSubmit = async (data) => {
    setLoading({ visible: true, text: 'Estamos analisando seus dados' })
    try {
      let order = {}

      const {
        name,
        email,
        cellphone,
        document,
        defaultAddress,
        birthdate,
        customer,
      } = store.step1

      const user = {
        defaultAddress,
        fullName: name,
        cellphone,
        birthdate,
        email,
      }

      if (store.order?.id) {
        await updateCustomer(store.order.customer.id, user)

        order = store.order
      } else {
        if (!customer?.id) {
          user.cpf = {}
          user.cpf.number = document
        }

        const response = await createOrder({
          user,
          origin: store.origin || 'omnichannel',
          representative: store.broker.id,
          coupon: store?.coupon || undefined,
          customer_id: customer?.id,
          marketingOptInFlag: !data.marketing,
        })

        if (
          !response.createdCustomer &&
          response.order.customer.birthdate === RESET_BIRTH_DATE
        ) {
          await updateCustomer(response.order.customer.id, user)
        }

        order = response.order
      }

      const isEligible = await isEligibleOfferProduct({
        cep: defaultAddress.cep || order.customer.defaultAddress.cep,
        id: order.id,
      })

      if (isEligible === STATUS.DENIED) {
        setLoading({ visible: false, text: '' })
        history.push('/denied')
        return
      }

      setOrder({ ...order, status: STATUS.OFFER })

      dataLayer.push({
        event: 'lead-porto',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        lead_id: order.customer.id,
        origem: order.origin,
        susep: order.representative.broker?.susep || '',
        sucursal: getSucursal(order.representative.broker?.branch || ''),
        product: 'carro-facil',
        retorno: 'sucesso:omni',
        descricao: 'Lead enviado com sucesso',
      })

      history.push('/step2')
    } catch (error) {
      dataLayer.push({
        event: 'lead-porto',
        client_id: encryptTextWithKey(
          store.order?.customer?.cpf?.number,
          'chave'
        ),
        lead_id: store.order?.customer?.id,
        origem: store.order?.origin,
        susep: store.order?.representative?.broker?.susep || '',
        sucursal: getSucursal(
          store.order?.representative?.broker?.branch || ''
        ),
        product: 'carro-facil',
        retorno: 'erro:omni',
        descricao: error.message,
      })

      setErrorModal(
        'Ocorreu uma instabilidade no sistema! Tente novamente mais tarde'
      )
      console.error(error)
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'consent',
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/evy/termos',
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { document } = store.step1
    if (!document) {
      history.push('/step0')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.step1])

  return (
    <Container>
      <FormProvider {...forms}>
        <Form onSubmit={forms.handleSubmit(onSubmit)}>
          <div></div>
          <Content>
            <Grid mb="30px">
              <Title>
                Precisamos saber se você concorda com as opções citadas a seguir
              </Title>
            </Grid>
            <Flex alignItems="center" gap="20px" mb="30px">
              <Text>
                Li e concordo com os{' '}
                <a
                  data-gtm-type="click"
                  data-gtm-clicktype="link"
                  data-gtm-name="termos-de-uso"
                  href="https://www.carroporassinaturaporto.com.br/files/termos/termos.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Termos de Uso
                </a>{' '}
                e com a Política de{' '}
                <a
                  data-gtm-type="click"
                  data-gtm-clicktype="link"
                  data-gtm-name="politica-de-privacidade"
                  href="https://www.portoseguro.com.br/politica-de-privacidade"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacidade.
                </a>
              </Text>
            </Flex>
            <Flex alignItems="start" gap="20px" mb="30px">
              <Text>
                Concordo em compartilhar minhas informações com o Grupo Porto,
                seus corretores e outros parceiros especializados em gestão de
                frotas automotivas, a fim de possibilitar a realização do
                orçamento e a comercialização dos serviços Carro por Assinatura
                Porto Bank.
              </Text>
            </Flex>

            <Flex alignItems="center" gap="20px">
              <Switch name="marketing" gtmName="aceito-receber-comunicacao" />

              <Text>
                Não tenho interesse em receber comunicações com condições
                especiais e ofertas de Produtos e Serviços Porto.
              </Text>
            </Flex>
          </Content>

          <FloatingButton
            text="Prosseguir"
            gtmName="prosseguir"
            height="57px"
            rounded
          />
        </Form>

        <Modal
          title={'Ops! Ocorreu um erro'}
          visible={errorModal}
          setIsVisible={setErrorModal}
        >
          <>
            <TextComp>{errorModal}</TextComp>
          </>
        </Modal>
      </FormProvider>
    </Container>
  )
}

Consent.properties = {
  title: 'Termos de consentimento',
}
