import styled, { css } from 'styled-components'

export const Container = styled.div``

export const Content = styled.div`
  ${({ theme }) => css`
    height: calc(100vh - ${theme.dimensions.header.height});
  `};

  padding: 80px 40px 80px;

  background-image: url('/images/broker-background.png');
  background-size: cover;
`

export const Text = styled.p`
  margin-bottom: 20px;

  font-family: ${({ theme }) => theme.fonts.book};
  color: #fff;
`

export const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.wide};
`
