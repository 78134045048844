import styled from 'styled-components'

export const ChatBox = styled.div`
  position: relative;
  width: 100%;

  padding: 50px 30px 25px;

  background: #e8e8e8;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 30px 0;
`

export const Car = styled.img`
  position: absolute;

  top: -40px;
  right: 10px;
`
export const Text = styled.div`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;
  line-height: 18px;
  color: rgba(5, 5, 5, 0.5);

  .text-druk {
    color: ${({ theme }) => theme.colors.primary};
    font-family: ${({ theme }) => theme.fonts.wide};
  }

  .text-wide {
    font-family: ${({ theme }) => theme.fonts.wide};
    color: #000;
  }

  a {
    color: #000;
    text-decoration: underline;
  }
`
