import * as S from './styles'

export default function Container({
  children,
  image,
  padding,
  justify,
  fullHeight,
}) {
  return (
    <S.Container
      padding={padding}
      image={image}
      justify={justify}
      fullHeight={fullHeight}
    >
      {children}
    </S.Container>
  )
}
