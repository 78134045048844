import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { FloatingButton, LineInput, Container, Flex } from 'components'
import Modal from 'components/Modal'
import SelectModal from 'components/SelectModal'
import { Delivery } from 'components/SelectModal/styles'

import { STATUS } from 'helpers'
import { createReservation, getProductDetail, updateOrder } from 'services/api'
import useFlow from 'store'
import {
  encryptTextWithKey,
  formatMoney,
  stockPriorityByStatus,
  stockStatusPriority,
  STOCK_CODES,
  updateCustomData,
} from 'utils'

import schema from './schema'
import * as S from './styles'
import { useErrorHandler } from 'store/orderRequest/hook'

const PLATES = [
  { text: '🚫 Indiferente', value: ['all'], exclusive: true },
  { text: '1 - 2 (Segunda-feira)', value: [1, 2] },
  { text: '3 - 4 (Terça-feira)', value: [3, 4] },
  { text: '5 - 6 (Quarta-feira)', value: [5, 6] },
  { text: '7 - 8 (Quinta-feira)', value: [7, 8] },
  { text: '9 - 0 (Sexta-feira)', value: [9, 0] },
]

const Step6 = () => {
  const { dataLayer = [] } = window

  const history = useHistory()
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      carColor: {
        text: '',
        value: '',
      },
      plateNumber: [PLATES[0]],
    },
  })
  const {
    store: { order },
    setLoading,
    setOrder,
    setStep6Info,
  } = useFlow()

  const { showModalHendler } = useErrorHandler()

  const colorSelected = methods.watch('carColor')
  const plateSelected = methods.watch('plateNumber')

  const [IsLoading, setIsLoading] = useState(false)
  const [modalInfo, setModalInfo] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [colors, setColors] = useState([])
  const [productsInStock, setProductsInStock] = useState([])
  const [modalHelp, setModalHelp] = useState(false)

  const vehicleData = useMemo(() => {
    return order?.product
  }, [order])

  useEffect(() => {
    ;(async () => {
      if (!order || productsInStock.length) {
        return
      }

      try {
        setLoading({ visible: true, text: '' })

        const data = vehicleData.vehicle

        const response = await getProductDetail({
          state: order?.customer?.defaultAddress?.state || 'SP',
          version: data?.code,
          model: data?.model?.code,
          brand: data?.model?.brand?.code,
        })

        if (!response.length) {
          throw Error('Não foi possível localizar o veículo')
        }

        const rows = response.map((element) => ({
          text: element.color.title,
          value: element.color.code,
          delivery: {
            text: element.deliveryDate,
            code: element.deliveryCode,
            address: element.deliveryDetails.address,
          },
        }))

        setColors(rows)
        setProductsInStock(response)
      } catch (error) {
        console.log('error::::', error)
      } finally {
        setLoading({ visible: false, text: '' })
      }
    })()
  }, [order, productsInStock, setLoading, vehicleData])

  const flattenPlates = useCallback((plateNumbers) => {
    return plateNumbers
      .map((item) => item.value.join(','))
      .join(',')
      .split(',')
  }, [])

  const getVehiclesByPlatesSeleted = useCallback(
    (data) => {
      let car = {}

      const colorData = productsInStock.find(
        (item) => item.color.code === data.carColor.value
      )

      const platesAvaliable = colorData.inStockPlates

      if (data.plateNumber[0]?.value[0] === 'all') {
        const vehicle = platesAvaliable.reduce((a, b) => {
          if (!a) {
            return b
          }

          if (a?.delivery?.priority < b?.delivery?.priority) {
            return a
          }

          return b
        })

        return vehicle?.details || {}
      }

      const minimumDeliveryPlate = data.plateNumber.reduce((a, b) => {
        if (!a) {
          return b
        }

        if (a?.delivery?.priority < b?.delivery?.priority) {
          return a
        } else if (a?.delivery?.priority === b?.delivery?.priority) {
          return a
        }

        return b
      })

      const plateCategory = minimumDeliveryPlate?.value
        ? minimumDeliveryPlate?.value[0]
        : ''

      if (plateCategory) {
        car = platesAvaliable.find((item) =>
          item?.platesIncluded.includes(plateCategory)
        )
      }

      return car?.details || {}
    },
    [productsInStock]
  )

  const formatPlatesNotWanted = useCallback((plates) => {
    const allPlates = new Array(10).fill(null).map((value, index) => `${index}`)
    return allPlates.filter((item) => !plates.includes(item))
  }, [])

  useEffect(() => {
    methods.setValue('plateNumber', [
      {
        text: '🚫 Indiferente',
        value: ['all'],
        exclusive: true,
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorSelected])

  const platesByDelivery = useCallback(() => {
    const colorData = productsInStock.find(
      (item) => item.color.code === colorSelected?.value
    )

    if (!colorData) {
      return PLATES
    }

    const platesIndex = {
      1: 1,
      2: 1,
      3: 2,
      4: 2,
      5: 3,
      6: 3,
      7: 4,
      8: 4,
      9: 5,
      0: 5,
    }

    let platesFormat = PLATES

    const stockPlates = colorData?.inStockPlates
    const platesAvaliable = Object.keys(colorData?.inStockPlates)

    for (let index = 0; index < platesAvaliable.length; index++) {
      const key = platesAvaliable[index]
      const element = stockPlates[key]

      const singlePlate = element.platesIncluded[0]
      const position = platesIndex[singlePlate]

      platesFormat[position].delivery = element.delivery
    }

    return platesFormat
  }, [colorSelected, productsInStock])

  const renderDelivery = useCallback(() => {
    if (!plateSelected.length || !colorSelected?.value) {
      return <></>
    }

    let minimum = 3
    for (let index = 0; index < plateSelected.length; index++) {
      const element = plateSelected[index]

      const priority = stockStatusPriority[element?.delivery?.code] || 3

      if (minimum > priority) {
        minimum = priority
      }
    }

    let key = stockPriorityByStatus[minimum]
    let text = STOCK_CODES[key]

    if (plateSelected[0].exclusive) {
      const colorData = productsInStock.find(
        (item) => item.color.code === colorSelected?.value
      )

      key = colorData?.deliveryCode
      text = colorData?.deliveryDate
    }

    return (
      <S.Text>
        <Flex>
          Estimativa de entrega:
          <Delivery code={key}>{text}</Delivery>
        </Flex>
      </S.Text>
    )
  }, [colorSelected, plateSelected, productsInStock])

  const onSubmit = async (data) => {
    const { vehicle } = order?.product
    const product = `${vehicle?.model?.brand?.code}${vehicle?.model?.code}${vehicle?.code}${data?.carColor?.value}`

    try {
      setIsLoading(true)
      const { defaultAddress } = order?.customer

      const firstVehicle = await getVehiclesByPlatesSeleted(data)

      if (
        data?.plateNumber[0]?.value[0] === 'all' &&
        firstVehicle?.plateFinal === 'NO_PLATE'
      ) {
        firstVehicle.vehiclePlate = parseInt(Math.random() * (9 - 0) + 0)
      }

      if (!firstVehicle?.chassiNumber) {
        setHasError(
          'Chassi do produto não encontrado, por favor entre em contato com seu corretor!'
        )
        throw Error('Erro ao criar reserva')
      }

      const reservationData = await createReservation({
        order: order.id,
        color: data.carColor.value,
        chassi: firstVehicle?.chassiNumber,
        vehiclePlate: firstVehicle?.vehiclePlate || '',
        state: defaultAddress?.state,
        product,
        deliveryCode: data.carColor.delivery.address,
      })

      let platesNotWanted = ''

      if (data?.plateNumber[0]?.value[0] !== 'all') {
        platesNotWanted = formatPlatesNotWanted(
          flattenPlates(data.plateNumber)
        ).join('')
      }

      const response = await updateOrder(order.id, {
        color: data.carColor.value,
        colorName: data.carColor.text,
        plate: platesNotWanted,
        status: STATUS.RESERVATION,
      })

      setOrder({ ...response })

      setStep6Info({
        color: data.carColor,
        chassiNumber: firstVehicle?.chassiNumber,
        vehiclePlate: firstVehicle?.vehiclePlate,
        reserveId: reservationData?.id,
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'salvar',
        tipo_servico: 'dados-veiculo',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        origem: order.origin,
        susep: order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'sucesso',
        descricao: 'Lead enviado com sucesso',
      })

      history.push('/terms')
    } catch (error) {
      setIsLoading(false)
      console.log('error::::', error)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'salvar',
        tipo_servico: 'dados-veiculo',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        origem: order.origin,
        susep: order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'error',
        descricao: error.message,
        erro: {
          codigo: error.code,
          servico: '/orders',
          mensagem: error.message,
        },
      })

      if (error.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    }
  }

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'step6',
      },
      carro: {
        categoria: order?.product.vehicle.category.slug,
        marca: order?.product.vehicle.model.brand.title.toLowerCase(),
        id: order?.product.id,
        nome: order?.product.vehicle.model.specification,
        variacao: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
        preco: formatMoney(
          order?.summary.baseCost.rawValue * order?.summary.months
        ),
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkout/preferencias-veiculo',
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...methods}>
      <Container
        padding={[
          [45, 30, 20],
          [40, 18, 0],
        ]}
      >
        <S.Form onSubmit={methods.handleSubmit(onSubmit)}>
          <div>
            <S.WrapImage>
              <S.Img
                src={vehicleData?.vehicle?.model?.gallery[0]?.url}
                alt=""
              />
            </S.WrapImage>
            <S.WrapInfo>
              <S.Text>{vehicleData?.vehicle?.model?.brand?.title}</S.Text>
              <S.Title>{vehicleData?.title}</S.Title>
              <S.SubTitle>{vehicleData.vehicle.specification}</S.SubTitle>
              {renderDelivery()}
            </S.WrapInfo>

            <S.WrapLineInput>
              <LineInput
                disabled
                name="disabled"
                label="Tempo de contrato"
                defaultValue={`${order?.package?.months} meses`}
              />
            </S.WrapLineInput>

            <S.WrapLineInput>
              <LineInput
                disabled
                name="disabled2"
                label="Quilometragem"
                defaultValue={`${order?.package?.mileage}km`}
              />
            </S.WrapLineInput>

            <SelectModal
              label="Cor do Veículo*"
              name="carColor"
              gtmName="cor-veiculo"
              options={colors}
              title="Selecione a cor do veículo"
            />

            <SelectModal
              label={
                <>
                  Selecione três opções para o final de placa de sua
                  preferência*{' '}
                  <S.ButtonLink onClick={() => setModalHelp(true)}>
                    i
                  </S.ButtonLink>
                </>
              }
              name="plateNumber"
              gtmName="final-placa"
              options={platesByDelivery()}
              multiSelect
              maxQuantitySelect={3}
              title={
                <>
                  Selecione três opções para o final de placa de sua preferência
                  <S.ButtonLink onClick={() => setModalHelp(true)}>
                    i
                  </S.ButtonLink>
                </>
              }
            />
            <S.DeliveryInfo onClick={() => setModalInfo(true)}>
              <p>Prazos de entrega do Carro por Assinatura Porto Bank</p>
            </S.DeliveryInfo>
          </div>

          <FloatingButton
            text="Prosseguir"
            height="57px"
            isLoading={IsLoading}
            rounded
          />

          <Modal
            title="Seleção de placas"
            visible={modalHelp}
            setIsVisible={setModalHelp}
          >
            <>
              <S.Text>
                Esta informação é importante para os habitantes da cidade de São
                Paulo, pois o final de placa define a data de rodízio do
                veículo, ou seja, o dia em que o veículo não pode circular pela
                região central da cidade. Caso não seja uma informação relevante
                para você, seleciona a opção “indiferente”.
              </S.Text>
            </>
          </Modal>
        </S.Form>
      </Container>

      <Modal
        title="Prazos do Carro Facil"
        visible={modalInfo}
        setIsVisible={setModalInfo}
      >
        <div>
          <S.Deadline>
            <img
              src="/images/raio.svg"
              alt="icone azul representando entrega rápida"
            />

            <p>
              <strong>Entrega Rápida:</strong> * Até 10 dias úteis para as
              Capitais.
            </p>
          </S.Deadline>
          <S.Deadline>
            <img
              src="/images/car_key.svg"
              alt="icone azul representando entrega rápida"
            />

            <p>
              <strong>Entrega Normal:</strong> ** Até 30 dias úteis para todas
              as regiões.
            </p>
          </S.Deadline>
          <S.Deadline>
            <img
              src="/images/condutor.svg"
              alt="icone azul representando entrega sob encomenda"
            />
            <p>
              <strong>Sob encomenda:</strong> Até 180 dias
            </p>
          </S.Deadline>

          <S.DeadlineInfo>
            <p>
              * Demais regiões será acrescido o tempo de transporte do Carro
              Fácil para o ponto de entrega. Prazos válidos a partir da
              confirmação do pagamento da primeira parcela e validação dos
              documentos.
            </p>
            <p>
              ** Dentre as opções de placa selecionadas, será priorizada a
              reserva do veículo que possuir o menor prazo de entrega.
            </p>
            <p>
              *** Prazos válidos a partir da confirmação do pagamento da
              primeira parcela e validação dos documentos.
            </p>
          </S.DeadlineInfo>
        </div>
      </Modal>

      <Modal title="Alerta" visible={hasError} setIsVisible={setHasError}>
        <p>
          Chassi do produto não encontrado, por favor entre em contato com seu
          corretor!
        </p>
      </Modal>
    </FormProvider>
  )
}

Step6.properties = {
  pageStep: 'Dados do veículo',
  showStep: true,
}

export default Step6
