import { rem } from 'polished'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 70px 25px 18px;
  min-height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.small}) {
    padding: 70px 30px 30px;
    height: 100%;
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  margin-bottom: 20px;

  ${({ strong }) =>
    strong &&
    css`
      font-family: ${({ theme }) => theme.fonts.black};
    `}

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const Title = styled(Text)`
  font-size: ${rem('18px')};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('24px')};
  }
`

export const WrapIcon = styled.div`
  width: 40px;
  padding: 10px;
  border-radius: 10px;

  background: white;

  img {
    width: 100%;
    object-fit: cover;
  }
`

export const WrapItems = styled.div`
  display: flex;
  align-items: center;

  gap: 15px;
  margin-bottom: 20px;

  ${Text} {
    margin-bottom: 0;
  }
`

export const WrapContent = styled.div`
  padding-bottom: 50px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    padding-bottom: 100px;
  }
`
