const colors = {
  primary: '#0027F4',
  secondary: '#00E0FF',
  info: '#00689A',
  success: '#1EC83D',
  warning: '#FFCD00',
  danger: '#EA2A00',
  gray: {
    dark: '#232323',
    medium: '#737373',
    light: '#C7C7C7',
  },
}

export default colors
