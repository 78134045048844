import React, { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { yupResolver } from '@hookform/resolvers/yup'

import {
  FloatingButton,
  LineInput,
  Container,
  ChatBox,
  Text,
  Modal,
} from 'components'
import useFlow from 'store'
import {
  getBroker,
  getCustomer,
  getOrderDetails,
  getIfUserExists,
} from 'services/api'

import { Content, Form, FormGrid } from './styles'
import validationSchema from './validation'
import { STATUS, prepareToRedirect } from 'helpers'

import { redirectUser, updateCustomData } from 'utils'

import { ReturnOrderModal } from 'components/ReturnOrder'
import { handleDocumentEncrypt } from 'utils/encryptUserDocument'

export default function Step0() {
  const {
    setStep1Info,
    setOrder,
    setLoading,
    setBroker,
    setCoupon,
    setIsMocked,
    setOrigin,
  } = useFlow()

  const forms = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  })

  const history = useHistory()
  const location = useLocation()

  const captcha = useGoogleReCaptcha()

  const [loaders, setLoaders] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [showValidateModal, setShowValidateModal] = useState(false)

  const { dataLayer = [] } = window

  const onSubmit = forms.handleSubmit(async ({ document }) => {
    setLoaders(true)
    try {
      const responseCaptch = await captcha.executeRecaptcha()

      if (!responseCaptch) {
        return
      }

      const hash = handleDocumentEncrypt(document)

      const { data, status } = await getIfUserExists(hash, responseCaptch)

      setStep1Info({ document })

      if (status === 204) {
        history.push('/step1')
        return
      }

      setStep1Info({
        customer: {
          id: data?.hash,
        },
      })
      setShowValidateModal(true)
    } catch (error) {
      setHasError(true)
      console.error(error)
    } finally {
      setLoaders(false)
    }
  })

  async function fetchBroker() {
    const susep = new URLSearchParams(location.search).get('susep')
    const coupon = new URLSearchParams(location.search).get('coupon')
    const isMocked = new URLSearchParams(location.search).get('mock-avaliable') // 'elegible' || 'credit-porto'

    if (!!isMocked) {
      setIsMocked(isMocked)
    }

    if (susep) {
      try {
        const broker = await getBroker(susep)

        if (broker.length > 0) {
          setBroker(broker[0])
          updateCustomData({
            user: { susep },
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (coupon) {
      setCoupon(coupon)
    }
  }

  async function fetchCustomer() {
    const customerId = new URLSearchParams(location.search).get('customerId')

    if (customerId) {
      try {
        const customers = await getCustomer({ id: customerId })

        if (customers.length > 0) {
          const customer = customers[0]
          const cpf = customer?.cpf.number

          setStep1Info({ customer: cpf })

          forms.setValue('document', cpf)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    fetchBroker()
    fetchCustomer()
  }, []) //eslint-disable-line

  useEffect(() => {
    updateCustomData({
      page: {
        step: '/home',
      },
    })

    dataLayer.push({
      event: 'step_change',
      etapa: '/home',
    })
  }, [dataLayer])

  useEffect(() => {
    ;(async () => {
      const order = new URLSearchParams(window?.location.search).get('o')

      if (!order) {
        return
      }

      const CHECKIN = [
        STATUS.DONE,
        STATUS.CNH_SENT,
        STATUS.CNH_REJECTED,
        STATUS.CNH_APPROVED,
        STATUS.SELFIE,
        STATUS.SEND_SELFIE,
        STATUS.SELFIE_REJECTED,
        STATUS.APPROVED_ZENDESK,
        STATUS.CANCELED_ZENDESK,
        STATUS.PCO_TIMEOUT,
      ]

      try {
        setLoading({ visible: true, text: 'Buscando dados do contrato' })
        const response = await getOrderDetails(order)

        setOrder({ ...response })

        if (CHECKIN.includes(response?.status)) {
          const route = await redirectUser(response.status)
          history.push(route)
          return
        }

        history.push(prepareToRedirect(response?.status))
      } catch (error) {
        console.log(error)
      } finally {
        setLoading({ visible: false, text: '' })
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOrigin(new URLSearchParams(window?.location.search).get('origin'))
  }, [setOrigin])

  return (
    <Container>
      <Content>
        <ChatBox
          text={`Estamos muito felizes por você ter escolhido o 
        <strong>Carro por Assinatura Porto Bank!</strong><br>
        Digite seu CPF para iniciarmos a proposta, mas se você já tiver uma em andamento nós vamos identificar.`}
        />
        <FormProvider {...forms}>
          <Form onSubmit={onSubmit}>
            <Text>Insira somente os números</Text>
            <FormGrid>
              <LineInput
                name="document"
                placeholder="000.000.000-00"
                label="CPF"
                mask="###.###.###-##"
                focusedColor="#0027F4"
                isLoading={loaders.doc}
                numericOnly
                defaultValue=""
              />
            </FormGrid>
          </Form>
        </FormProvider>
      </Content>
      <FloatingButton
        rounded
        isLoading={loaders}
        disabled={loaders}
        gtmClickType="submit"
        text="Avançar"
        action={onSubmit}
        height="57px"
      />

      <Modal title="Falha!" visible={hasError} setIsVisible={setHasError}>
        <p>Falha ao buscar seus dados na base, por favor tente novamente.</p>
      </Modal>

      <ReturnOrderModal
        setShowModal={setShowValidateModal}
        showModal={showValidateModal}
      />
    </Container>
  )
}

Step0.properties = {
  pageStep: 'Dados de contato',
  showStep: true,
}
