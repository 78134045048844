import { createStore } from 'effector'

import * as action from './action'
import * as service from './service'

const initialStore = {
  showModal: false,
}

export const $requestError = createStore(initialStore, {
  name: 'orderRequestStore',
})

$requestError.on(action.showModalHendler, (state, showModal) => {
  console.log('state, showModal', state, showModal)
  return service.setShowModalErrorHandler({ ...state, showModal })
})
