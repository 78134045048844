import React from 'react'

import useFlow from 'store'
import { Container } from 'components'

import * as S from './styles'

export default function Ordered() {
  const { store } = useFlow()
  const { firstName = 'Cliente' } = store?.order?.customer || {}

  return (
    <Container image="/images/ordered.png" fullHeight={true}>
      <S.Wrapper>
        <div>
          <S.Title>Olá, {firstName}!</S.Title>
          <S.Text>
            Estamos analisando os dados enviados e em breve teremos uma
            resposta.
          </S.Text>
        </div>
      </S.Wrapper>
    </Container>
  )
}

Ordered.properties = {
  pageStep: 'Em Analise',
  showStep: false,
}
