import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Alert, FloatingButton, Container, ChatBox } from 'components'
import { getUserAnalysis } from 'services/api'
import useFlow from 'store'

import { Label } from './styles'
import { formatMoney, updateCustomData } from 'utils'
import { useErrorHandler } from 'store/orderRequest/hook'

export default function Analysis() {
  const { store, setLoading, setPosBureau } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const history = useHistory()

  const { dataLayer = [] } = window
  const [visibility, setVisibility] = useState(false)

  const { summary, product } = store.order
  const onSubmit = async () => {
    setLoading({ visible: true })
    try {
      const { order, reasons } = await getUserAnalysis(store.order.id)

      if (reasons?.length > 0) {
        setPosBureau(reasons)
        history.push('/bureau')
        return
      }

      dataLayer.push({
        event: 'envio_proposta',
        carro: {
          categoria: product.vehicle.category.slug,
          marca: product.vehicle.model.brand.title.toLowerCase(),
          id: product.id,
          nome: product.vehicle.model.specification,
          variacao: `${product.vehicle.specification} | ${summary.months} meses | ${summary.mileage} km`,
          preco: formatMoney(summary.baseCost.rawValue * summary.months),
        },
        retorno: 'sucesso',
        descricao: 'Enviado para analise',
      })

      history.push(
        {
          derivation: '/em-analise',
          denied: '/denied',
          approved: '/step5',
        }[order?.status]
      )
    } catch (error) {
      console.error(error)

      dataLayer.push({
        event: 'envio_proposta',
        carro: {
          categoria: product.vehicle.category.slug,
          marca: product.vehicle.model.brand.title.toLowerCase(),
          id: product.id,
          nome: product.vehicle.model.specification,
          variacao: `${product.vehicle.specification} | ${summary.months} meses | ${summary.mileage} km`,
          preco: formatMoney(summary.baseCost.rawValue * summary.months),
        },
        retorno: 'sucesso',
        descricao: 'Enviado para analise',
      })

      if (error.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    } finally {
      setLoading({ visible: false })
    }
  }

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'analysis',
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/analise-interna',
    })
  }, [dataLayer])

  return (
    <Container>
      <ChatBox
        text={`<p>Tudo pronto!</p> Agora, seus dados serão submetidos a uma <strong class="text-wide">análise interna.</strong>`}
      />
      <FloatingButton
        text="Ok, enviar para analise!"
        height="57px"
        gtmName="ok-enviar-para-analise"
        action={onSubmit}
        gtmClickType="submit"
        rounded
      />

      <Alert
        isVisible={visibility}
        actions={{
          primary: { onClick: () => setVisibility(false), label: 'Entendi' },
        }}
      >
        <Label>Ok! Enviar para análise</Label>
      </Alert>
    </Container>
  )
}

Analysis.properties = {
  title: 'Analise',
}
