import * as yup from 'yup'
import { checkFullName } from 'utils'

export default yup.object().shape({
  motherName: yup
    .string()
    .test('checkFullName', 'Por favor insira o nome completo', (value) =>
      checkFullName(value)
    )
    .required('Por favor insira o nome da mãe'),
  marital: yup.object().shape({
    label: yup.string().required('Por favor, selecione uma opção'),
    value: yup.string().required('Por favor, selecione uma opção'),
  }),
  gender: yup.object().shape({
    label: yup.string().required('Por favor, selecione uma opção'),
    value: yup.string().required('Por favor, selecione uma opção'),
  }),
  company: yup
    .string()
    .min('2', 'Nome da empresa inválido')
    .required('Por favor, insira o nome da empresa'),
    income: yup
    .string()
    .required('Por favor, insira o quanto você recebe')
    .test(
      'verifyIfIsCharacters',
      'Por favor, remova as letras',
      (value) => {
        if (/[A-Z]/gi.test(value)) {
          return false
        }

        return true
      }
    ),
  occupation: yup.object().shape({
    label: yup.string().required('Por favor, insira o seu enquadramento'),
    value: yup.string().required('Por favor, insira o seu enquadramento'),
  }),
})
