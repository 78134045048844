import styled from 'styled-components'
import { rem } from 'polished'
import { breakpoints } from 'styles'

export const Content = styled.div``

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.small}) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
  }
`

export const Topics = styled.div`
  .accordion__button {
    position: relative;

    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;

    outline: none;
    cursor: pointer;
    text-align: left;
    user-select: none;
    font-family: ${({ theme }) => theme.fonts.book};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .accordion__panel {
    padding: 0 ${rem('10px')} ${rem('10px')};

    line-height: 1.6;
    font-size: ${rem('13px')};
    color: ${({ theme }) => theme.colors.gray.medium};
    font-family: ${({ theme }) => theme.fonts.book};
    animation: fadein 0.35s ease-in;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
