import React, { useEffect } from 'react'

import useFlow from 'store'
import { Container } from 'components'

import * as S from './styles'
import { encryptTextWithKey, formatMoney, updateCustomData } from 'utils'

export default function WelcomeToCF() {
  const { dataLayer = [] } = window

  const {
    store: { order },
  } = useFlow()
  const { firstName = 'Cliente' } = order?.customer || {}

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'envio-cnh',
      },
      carro: {
        categoria: order?.product?.vehicle?.category?.title,
        nome: order?.product?.title,
        marca: order?.product?.vehicle?.model?.brand?.title,
        id: order?.product?.vehicle?.id,
        preco: formatMoney(
          order?.package?.months * order?.package?.cost?.rawValue
        ),
        variacao: `${order?.colorName} | ${order?.package?.mileage} km | ${order?.package?.months} meses`,
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkin/concluido',
    })
  }, [dataLayer, order])

  useEffect(() => {
    dataLayer.push({
      event: 'solicitacao_servico',
      nome_servico: 'concluido',
      client_id: encryptTextWithKey(order?.customer.cpf.number, 'chave'),
      retorno: 'sucesso',
      descricao: 'Bem-vindo ao Carro por Assinatura Porto Bank',
    })
  }, [dataLayer, order?.customer.cpf.number])

  return (
    <Container image="/images/welcome.png">
      <S.Wrapper>
        <S.Title>
          <h1>Parabéns, {firstName}!</h1>

          <h2>
            Seja bem-vindo ao <br />
            Carro por Assinatura Porto Bank!
          </h2>
        </S.Title>

        <S.Text>
          <p>
            Agora você pode acompanhar todos os detalhes do seu Carro por
            Assinatura Porto Bank através do seu e-mail. Vamos enviar todas
            atualizações das etapas de preparação do seu veiculo.
          </p>
        </S.Text>
      </S.Wrapper>
    </Container>
  )
}

WelcomeToCF.properties = {
  pageStep: 'Bem vindo ao CF',
  showStep: false,
}
