import React from 'react'

import { Flex } from 'components'

import { Container, Item, Round, Text } from './styles'

import steps from './steps'

export default function Phase({ pageStep }) {
  return (
    <Container>
      <Flex justifyContent="space-around">
        {steps
          .sort((a, b) => a.position - b.position)
          .map((el, id) => (
            <Item key={id} active={el.step === pageStep}>
              <Round />
              <Text dangerouslySetInnerHTML={{ __html: el.step }} />
            </Item>
          ))}
      </Flex>
    </Container>
  )
}
