import axios from 'axios'
import { getToken } from 'services/firebase'

export * from './customers'
export * from './orders'
export * from './products'
export * from './addresses'
export * from './users'
export * from './reservations'
export * from './disponibility-zones'
export * from './card'
export * from './payments'
export * from './riskanalysis'
export * from './reset-password'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
})

api.interceptors.request.use(async (config) => {
  const token = await getToken()

  config.headers.fireToken = token

  return config
})

export default api
