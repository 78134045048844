import * as P from 'pages'

const routes = [
  {
    path: '/',
    component: P.Step0,
    redrect: false,
  },
  {
    path: '/consent',
    component: P.Consent,
    redrect: false,
  },
  {
    path: '/step0',
    component: P.Step0,
    redrect: false,
  },
  {
    path: '/step1',
    component: P.Step1,
    redrect: false,
  },
  {
    path: '/step2',
    component: P.VehiclesList,
    redrect: true,
  },
  {
    path: '/reset-password/:hash',
    component: P.ResetPassword,
    redrect: false,
    outMaintenance: true,
  },
  {
    path: '/step2/vehicle',
    component: P.Vehicle,
    redrect: true,
  },
  {
    path: '/step3',
    component: P.Step3,
    redrect: true,
  },
  {
    path: '/step4',
    component: P.Step4,
    redrect: true,
  },
  {
    path: '/step5',
    component: P.Step5,
    redrect: true,
  },
  {
    path: '/step6',
    component: P.Step6,
    redrect: true,
  },
  {
    path: '/resume',
    component: P.Resume,
    redrect: true,
  },
  {
    path: '/analysis',
    component: P.Analysis,
    redrect: true,
  },
  {
    path: '/bureau',
    component: P.SerasaPosBureau,
    redrect: true,
  },
  {
    path: '/terms',
    component: P.Terms,
    redrect: true,
  },
  {
    path: '/denied',
    component: P.Denied,
    redrect: true,
  },
  {
    path: '/em-analise',
    component: P.Ordered,
    redrect: true,
  },
  {
    path: '/completed',
    component: P.Completed,
    redrect: true,
  },
  {
    path: '/orders-check',
    component: P.OrdersCheck,
    redrect: true,
  },
  {
    path: '/broker',
    component: P.BrokerFlow,
    redrect: true,
  },
  {
    path: '/maintenance',
    component: P.Maintenance,
    redrect: false,
  },
  {
    path: '/cartao-porto',
    component: P.OfferPortoCard,
    redrect: true,
  },
  {
    path: '/selecao-cartao',
    component: P.SelectTypeCreditCard,
    redrect: true,
  },
  {
    path: '/porto-payment',
    component: P.PayWithPorto,
    redrect: true,
  },
  {
    path: '/assinar',
    component: P.UserSignature,
    redrect: true,
  },
  {
    path: '/selecionar-cartao-credito',
    component: P.OtherCreditCard,
    redrect: true,
  },
  {
    path: '/upload-cnh',
    component: P.UploadCNH,
    redrect: true,
  },
  {
    path: '/checkin-self',
    component: P.SelfPermission,
    redrect: true,
  },
  {
    path: '/captura-selfie',
    component: P.UploadSelfie,
    redrect: true,
  },
  {
    path: '/envio-selfie',
    component: P.SelfieSMS,
    redrect: true,
  },
  {
    path: '/selfie-denied',
    component: P.DeniedSelfie,
    redrect: true,
  },
  {
    path: '/checkin',
    component: P.FirstPageCheckin,
    redrect: true,
  },
  {
    path: '/bem-vindo',
    component: P.WelcomeToCF,
    redrect: true,
  },
]

export default routes
