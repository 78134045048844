import styled, { css } from 'styled-components'
import { breakpoints } from 'styles'

const communText = css`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;
  line-height: 18px;
  color: rgba(5, 5, 5, 0.5);
`

export const Container = styled.article`
  padding: 50px 25px 0;
  min-height: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.small}) {
    padding: 50px 30px 0;
  }
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`

export const Title = styled.h3`
  font-size: 14px;
  line-height: 19px;
  font-family: ${({ theme }) => theme.fonts.bold};
`

export const Box = styled.div`
  width: 100%;

  margin: 16px 0;
  padding: 30px;

  background-color: #e8e8e8;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 30px 0;

  @media (min-width: ${breakpoints.small}) {
    width: 50%;
  }
`

export const BoxContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`

export const Button = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;

  justify-content: flex-end;

  @media (min-width: ${breakpoints.small}) {
    flex-direction: row;
  }
`

export const NewPrice = styled.div`
  ${communText}
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.bold};
`

export const Form = styled.form`
  padding: 25px 16px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  p {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 23px;
  }
`

export const FormGroup = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.small}) {
    width: 50%;
  }
`
