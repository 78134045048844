import { isAfterDate, isBeforeDate } from 'utils'
import * as yup from 'yup'

const date = new Date()
date.setFullYear(date.getFullYear() - 10)

export default yup.object().shape({
  cnh: yup.object().shape({
    number: yup
      .string()
      .required('Insira o número da CNH')
      .min('11', 'Insira o número da CNH válido')
      .max('11', 'Insira o número da CNH válido'),
    firstCNH: yup
      .string()
      .required('Insira data da primeira emissão CNH')
      .min('8', 'Insira uma data válida')
      .test('validateEmission', 'Insira uma data válida', (value) =>
        isBeforeDate(value)
      ),
    emission: yup
      .string()
      .required('Insira data de emissão')
      .min('8', 'Insira uma data de emissão válida')
      .test('validateEmission', 'Insira uma data válida', (value) =>
        isBeforeDate(value)
      )
      .test('validateBirthdate', 'Insira uma data válida', (value) =>
        isAfterDate(value, date)
      ),
    expiration: yup
      .string()
      .required('Insira data de validade')
      .min('8', 'Insira uma data de validade')
      .test('validateBirthdate', 'Insira uma data válida', (value) =>
        isAfterDate(value)
      ),
  }),
  rg: yup.object().shape({
    number: yup
      .string()
      .required('Insira o número da RG')
      .min('9', 'Insira o número da válido')
      .max('9', 'Insira o número da válido'),
    issueDate: yup
      .string()
      .required('Insira data de emissão')
      .min('8', 'Insira uma data de emissão válida')
      .test('validateEmission', 'Insira uma data válida', (value) =>
        isBeforeDate(value)
      ),
    issuingAgency: yup
      .string()
      .required('Insira o emissor do seu RG')
      .min('3', 'Orgão expedidor inválido')
      .max('3', 'Orgão expedidor inválido'),
  }),
})
