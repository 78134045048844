import styled from 'styled-components'
import { breakpoints } from 'styles'

export const Content = styled.div`
  padding-bottom: 20px;
`

export const Label = styled.p`
  font-size: 10px;
  line-height: 13px;
  color: rgba(5, 5, 5, 0.8);
  font-family: ${({ theme }) => theme.fonts.black};
`

export const ChatBox = styled.div`
  position: relative;

  padding: 50px 30px 25px;

  background: #e8e8e8;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
`

export const Car = styled.img`
  position: absolute;

  top: -40px;
  left: 10px;
`

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;
  line-height: 18px;
  color: rgba(5, 5, 5, 0.5);

  @media (min-width: ${breakpoints.medium}) {
    font-size: 16px;
  }
`

export const Bold = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.black};
`

export const Druk = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.wide};
`

export const Form = styled.form`
  margin-top: 35px;
  padding: 35px 15px;

  background: #ffffff;
  border: 2px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
`

export const FormGrid = styled.div`
  display: grid;
  margin-top: 20px;
  grid-gap: 30px;

  @media (min-width: ${breakpoints.medium}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Option = styled.div`
  margin-top: 1rem;
  width: 100%;

  display: flex;
  align-items: center;

  p {
    font-family: ${({ theme }) => theme.fonts.book};
    font-size: 12px;
  }

  @media (min-width: ${breakpoints.medium}) {
    width: 50%;
  }
`
