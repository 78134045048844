import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 30px;
  left: 10px;
  z-index: 1;

  height: 38px;
  width: 38px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  cursor: pointer;
  background: #fff;
`
