import React from 'react'

import { Button } from 'components'

import { Box } from './styles'

export default function FloatingButton({
  text,
  action,
  rounded,
  align,
  disabled,
  isLoading,
  height,
  color,
  gtmName,
  gtmClickType,
}) {
  return (
    <Box align={align}>
      <Button
        onClick={action}
        mt="auto"
        color={color}
        align={align}
        gtmName={gtmName}
        gtmClickType={gtmClickType}
        borderRadius={rounded ? '90px' : '0'}
        height={height || '50px'}
        block={rounded ? false : true}
        width="95%"
        mb={rounded ? '10px' : 0}
        disabled={disabled}
        isLoading={isLoading}
      >
        {text}
      </Button>
    </Box>
  )
}
