import { rem } from 'polished'
import styled, { css } from 'styled-components'
import { breakpoints } from 'styles'

const regularText = css`
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.book};
`

export const Wrapper = styled.div`
  display: grid;
  flex: 1;
  grid-template-rows: 1fr auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
  grid-template-columns: 100%;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapp = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  grid-template-rows: auto 1fr;
  justify-items: center;
  ${regularText}

  @media (min-width: ${breakpoints.small}) {
    grid-template-columns: 48px 1fr;
    grid-template-rows: none;
    width: ${rem('380px')};
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
  }
`

export const RingLoad = styled.div`
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Redirect = styled.a`
  ${regularText}
  font-size: 1rem;
  text-decoration: underline;
`

export const Button = styled.div`
  justify-self: center;

  @media (min-width: ${breakpoints.small}) {
    justify-self: flex-end;
  }
`

export const Modal = styled.div`
  display: flex;
  justify-content: center;
`
