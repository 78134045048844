import React, { useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateCustomer } from 'services/api'

import { FloatingButton, LineInput, Container, ChatBox, Text } from 'components'
import useFlow from 'store'

import { Content, Wrapper, Grid } from './styles'
import { encryptTextWithKey, formatDate, updateCustomData } from 'utils'

import validationSchema from './validation'

export default function Step5() {
  const { dataLayer = [] } = window
  const { store, setOrder } = useFlow()

  const forms = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = forms.handleSubmit(async (values) => {
    setIsLoading(true)

    try {
      const payload = {
        cnh: {
          ...values.cnh,
          emission: formatDate(values.cnh.emission),
          expiration: formatDate(values.cnh.expiration),
          firstCNH: formatDate(values.cnh.firstCNH),
        },
        rg: {
          ...values.rg,
          issueDate: formatDate(values.rg.issueDate),
        },
      }

      const customer = await updateCustomer(store.order.customer.id, {
        ...payload,
      })

      setOrder({
        ...store.order,
        customer: {
          ...customer,
        },
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'cadastrar',
        tipo_servico: 'endereco',
        client_id: encryptTextWithKey(customer.cpf.number, 'chave'),
        origem: store.order.origin,
        susep: store.order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'sucesso',
        descricao: 'Lead enviado com sucesso',
      })
      history.push('/step6')
    } catch (error) {
      console.error(error)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'cadastrar',
        tipo_servico: 'endereco',
        client_id: encryptTextWithKey(store.order.customer.cpf.number, 'chave'),
        origem: store.order.origin,
        susep: store.order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'erro',
        erro: {
          codigo: error.code,
          servico: '/customers',
          mensagem: error.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  })

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'step5',
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/evy/dados-cnh',
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Content>
        <ChatBox
          text={`${store.order?.customer?.firstName}, informe os dados da sua CNH e RG para
          continuar.`}
        />

        <FormProvider {...forms}>
          <form onSubmit={onSubmit}>
            <Wrapper>
              <Text>Dados da CNH</Text>
              <Grid>
                <LineInput
                  name="cnh.number"
                  placeholder="00000000000"
                  label="Número da CNH*"
                  maxLength="11"
                  defaultValue=""
                  numericOnly
                />
                <LineInput
                  name="cnh.emission"
                  placeholder="dd/mm/aaaa"
                  label="Data de emissão*"
                  defaultValue=""
                  maskedValue
                  date
                />
              </Grid>
              <Grid>
                <LineInput
                  name="cnh.expiration"
                  placeholder="dd/mm/aaaa"
                  label="Data de validade*"
                  defaultValue=""
                  maskedValue
                  date
                />
                <LineInput
                  name="cnh.firstCNH"
                  placeholder="dd/mm/aaaa"
                  label="Data da primeira emissão da sua CNH*"
                  defaultValue=""
                  maskedValue
                  date
                />
              </Grid>
            </Wrapper>
            <Wrapper>
              <Text>Dados do Registro Geral (RG)</Text>
              <Grid>
                <LineInput
                  name="rg.number"
                  placeholder="00.000.000-0"
                  label="RG*"
                  maxLength="12"
                  defaultValue=""
                  mask="##.###.###-#"
                />
                <LineInput
                  name="rg.issueDate"
                  placeholder="dd/mm/aaaa"
                  label="Data de emissão*"
                  defaultValue=""
                  maskedValue
                  date
                />
              </Grid>
              <Grid>
                <LineInput
                  name="rg.issuingAgency"
                  placeholder="Secretaria da Segurança Pública"
                  label="Orgão emissor*"
                  defaultValue=""
                  maxLength="3"
                />
              </Grid>
            </Wrapper>
          </form>
        </FormProvider>
      </Content>
      <FloatingButton
        rounded
        gtmName="avançar"
        isLoading={isLoading}
        text="Avançar"
        action={onSubmit}
        height="57px"
        gtmClickType="submit"
      />
    </Container>
  )
}

Step5.properties = {
  pageStep: 'Dados da CNH',
  showStep: true,
}
