import styled from 'styled-components'
import { breakpoints } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.small}) {
    margin-bottom: 5rem;
  }
`

export const Title = styled.div`
  color: #fff;

  h1 {
    font-size: 1.5rem;
    font-family: ${({ theme }) => theme.fonts.book};
    font-weight: 400;
    margin-bottom: 4.2rem;
  }

  h2 {
    font-size: 1.75rem;
    font-family: ${({ theme }) => theme.fonts.book};
    font-family: ${({ theme }) => theme.fonts.bold};
    line-height: 150%;
  }
`

export const Text = styled.div`
  margin-bottom: 10rem;

  p {
    font-size: 1.2rem;
    font-family: ${({ theme }) => theme.fonts.book};
    color: #fff;
  }

  strong {
    font-size: 2rem;
  }
`
