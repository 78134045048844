import React, { useEffect, useState } from 'react'

import { firebase } from 'services/firebase'

import { Container, Subtitle, Text, Title } from './styles'

export default function Maintenance() {
  const [message, setMessage] = useState(``)

  const getMaintenanceMessage = () => {
    const ref = firebase.database().ref('web/config/omni/maintenance/message')

    ref.on('value', (snap) => {
      if (snap.exists()) {
        const message = snap.val().replaceAll('\n', '<br />')
        setMessage(message)
      }
    })
  }

  const fallbackMessage =
    '<br /> Fique tranquilo, estamos deixando tudo ainda melhor pra você.<br /><br />Em 24h estará funcionando normalmente.'

  useEffect(() => {
    getMaintenanceMessage()
  }, [])

  return (
    <Container>
      <Title>Calma ;)</Title>
      <Subtitle>tá tudo bem.</Subtitle>
      <Text dangerouslySetInnerHTML={{ __html: message || fallbackMessage }} />
    </Container>
  )
}

Maintenance.properties = {
  title: 'Manutenção',
  hideHeader: true,
}
