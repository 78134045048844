import * as yup from 'yup'

export default yup.object().shape({
  cnhFront: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('A CNH é obrigatória'),
        size: yup.string().required('A CNH é obrigatória'),
        type: yup.string().required('A CNH é obrigatória'),
        base64: yup.string().required('A CNH é obrigatória'),
      })
    )
    .required('A CNH é obrigatória'),
  cnhBack: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('A parte de baixo da CNH é obrigatória'),
        size: yup.string().required('A parte de baixo da CNH é obrigatória'),
        type: yup.string().required('A parte de baixo da CNH é obrigatória'),
        base64: yup.string().required('A parte de baixo da CNH é obrigatória'),
      })
    )
    .required('A parte de baixo da CNH é obrigatória'),
})
