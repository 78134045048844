import { rem } from 'polished'
import styled from 'styled-components'
import { breakpoints } from 'styles'

import { Container as ButtonContainer } from 'components/Button/styles'

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);

  display: flex;

  justify-content: center;
  align-items: center;
  padding: 0 0.6rem;

  z-index: 1000000;
`

export const Behind = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  width: 100%;
  min-height: 300px;

  background-color: #fff;
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 30%);

  border-radius: 10px;

  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.small}) {
    width: 500px;
  }
`

export const Header = styled.div`
  padding: 13px 20px;
  padding-right: 40px;
  box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 30%);

  position: relative;
`

export const ChildrenWrap = styled.div`
  height: 100%;
  padding: 13px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

export const Title = styled.div`
  font-size: ${rem('16px')};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-family: ${({ theme }) => theme.fonts.medium};

  line-height: 1;
`

export const Button = styled.button`
  position: absolute;

  top: 10px;
  right: 10px;

  background: transparent;
  border: none;

  cursor: pointer;
`

export const WrapButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;

  ${ButtonContainer} {
    width: 150px;
  }
`
