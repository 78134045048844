import styled from 'styled-components'
import { breakpoints } from 'styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 2rem;
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.small}) {
    margin-bottom: 5rem;
  }
`

export const Title = styled.h1`
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.book};
  color: #fff;

  margin-bottom: 1rem;
`

export const Text = styled.p`
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.book};
  color: #fff;

  strong {
    font-size: 2rem;
  }
`
