import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  padding: 40px;
  display: flex;

  flex-direction: column;
`

export const WrapperForm = styled.div`
    form {
        height: 100%;
    }
`

export const WrapperButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`