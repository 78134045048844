import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { FloatingButton, Container, ChatBox } from 'components'
import useFlow from 'store'

import { updateOrder } from 'services/api'
import { encryptTextWithKey, formatMoney, updateCustomData } from 'utils'
import { useErrorHandler } from 'store/orderRequest/hook'

export default function Terms() {
  const { dataLayer = [] } = window

  const {
    store: { order },
    setOrder,
  } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const history = useHistory()

  const [link, setLink] = useState('')
  const [hasLoading, setHasLoading] = useState(false)

  const onSubmit = async () => {
    setHasLoading(true)
    try {
      const termsAcceptance = {
        accepted: true,
        date: new Date().toString(),
        cpf: order?.customer.cpf.number,
        name: order?.customer.fullName,
        order_id: order?.id,
      }

      const response = await updateOrder(order?.id, {
        termsAcceptance,
        status: 'terms',
      })

      setOrder({ ...response })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'visualizar',
        tipo_servico: 'contrato',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        origem: order.origin,
        susep: order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'sucesso',
        descricao: 'enviado com sucesso',
      })

      history.push('/resume')
    } catch (error) {
      console.error(error)

      if (error.code === 409) {
        showModalHendler({ showModal: true })
      }

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'visualizar',
        tipo_servico: 'contrato',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        origem: order.origin,
        susep: order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'error',
        descricao: error.message,
      })
    } finally {
      setHasLoading(false)
    }
  }

  const openTerms = useCallback(async () => {
    const type =
      order?.plan?.title === 'Controle' ? '/controle' : '/convencional'

    setLink(
      `https://www.carroporassinaturaporto.com.br/files/${type}/termos.pdf`
    )
  }, [order?.plan?.title])

  useEffect(() => {
    openTerms()
  }, [openTerms])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'terms',
      },
      carro: {
        categoria: order?.product.vehicle.category.slug,
        marca: order?.product.vehicle.model.brand.title.toLowerCase(),
        id: order?.product.id,
        nome: order?.product.vehicle.model.specification,
        variacao: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
        preco: formatMoney(
          order?.summary.baseCost.rawValue * order?.summary.months
        ),
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkout/contrato',
    })
  }, [dataLayer, order])

  return (
    <Container>
      <ChatBox
        text={`
            <p>
              Parabéns <strong class="text-wide">${order?.customer?.fullName}!</strong>
            </p>
            <br/> 
            <p>Você está próximo de adquirir o seu Carro por Assinatura Porto Bank.</p>
            <br/> 
            
            <p>
              <strong class="text-wide">Você aceita o contrato?</strong>
            </p>
            <br/> 
            <br/> 
            <br/> 

            <a 
              href="${link}" 
              data-gtm-type="click"
              data-gtm-clicktype="link"
              data-gtm-name="ler-contrato-completo" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Ler contrato completo
            </a>
          `}
      />
      <FloatingButton
        text="Prosseguir"
        gtmName="prosseguir"
        isLoading={hasLoading}
        disabled={hasLoading}
        height="57px"
        action={onSubmit}
        gtmClickType="submit"
        rounded
      />
    </Container>
  )
}

Terms.properties = {
  title: 'Termos',
}
