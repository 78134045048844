import styled from 'styled-components'

export const Message = styled.div`
  position: relative;

  padding: 45px 20px 20px;
  margin-bottom: 50px;

  background-color: #e8e8e8;
  font-family: ${({ theme }) => theme.fonts.book};
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  color: ${({ theme }) => theme.colors.gray.medium};
`

export const Avatar = styled.img`
  position: absolute;
  top: -50px;
  left: 10px;
`

export const Text = styled.p``

export const Box = styled.div`
  display: flex;
`

export const Label = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.medium};
`

export const Wide = styled.span`
  font-family: ${({ theme }) => theme.fonts.wide};
  color: #000;
`
