import * as yup from 'yup'
import { isValidCpf } from 'utils'

export default yup.object().shape({
  document: yup
    .string()
    .required('Por favor insira seu CPF')
    .test('document-test', 'Por favor insira um CPF válido', (value) =>
      isValidCpf(value)
    ),
})
