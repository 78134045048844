import styled from 'styled-components'
import { rem } from 'polished'

export const Wrapper = styled.div`
  > p {
    margin-bottom: 2rem;
  }
`

export const ErrorMessage = styled.p`
  font-size: ${rem('14px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.medium};

  margin-top: 2rem;
`
