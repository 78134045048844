import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'

import useFlow from 'store'
import { Flex, Grid } from 'components'

import { Container, Image, Label, Price, Text, Title, Version } from './styles'

export default function Card({ data, allVehicles }) {
  const ref = useRef()
  const history = useHistory()
  const { setStep2Info } = useFlow()
  const { vehicle, packages } = data

  function setInfo() {
    const { code = '' } = vehicle?.model

    const models = allVehicles.filter(
      (item) => item.vehicle.model.code === code
    )

    const index = models.findIndex((item) => item.id === data.id)

    setStep2Info({ vehicles: models, index })
    history.push('/step2/vehicle')
  }

  const sortedPackages = packages.sort(
    (a, b) => a.cost.rawValue - b.cost.rawValue
  )
  const price = sortedPackages[0]?.cost.formattedValue

  return (
    <Container
      data-gtm-type="click"
      data-gtm-clicktype="card"
      data-gtm-name={` ${vehicle?.model?.specification} ${vehicle?.specification}`}
      onClick={setInfo}
      ref={ref}
    >
      <Grid mb="20px">
        <Title>{vehicle?.model?.specification}</Title>
        <Version>{vehicle?.specification}</Version>
      </Grid>
      <Grid mb="20px">
        <Image
          src={
            vehicle?.model.cover?.frontCover?.url ||
            vehicle?.model.gallery?.[0]?.url
          }
        />
      </Grid>
      <Grid>
        <Label>Custo mensal</Label>
      </Grid>
      <Flex alignItems="center" justifyContent="center">
        <Text>a partir de</Text>
        <Price>{price}</Price>
      </Flex>
    </Container>
  )
}
