import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useFormContext } from 'react-hook-form'
import Modal from 'components/Modal'
import * as S from './styles'

const SelectModal = ({
  name,
  label,
  options,
  title = '',
  placeholder = 'Selecione',
  multiSelect = false,
  maxQuantitySelect = 1,
  gtmName,
}) => {
  const methods = useFormContext()
  const [modalActive, setModalActive] = useState(false)

  const { dataLayer = [] } = window

  const selected = methods.watch(name)

  const containError = useMemo(() => {
    if (!methods.errors || !methods.errors[name]) {
      return false
    }

    if (methods.errors[name]?.value?.message) {
      return methods.errors[name]?.value?.message
    }

    return methods.errors[name]?.message
  }, [methods.errors, name])

  const register = () => {
    if (!methods?.control) {
      return {}
    }

    return methods.register(name)
  }

  const setValue = useCallback(
    (item) => {
      let value = item

      if (!multiSelect) {
        methods.setValue(name, value)
        return
      }

      if (item.exclusive) {
        methods.setValue(name, [item])
        return
      }

      value = Array.isArray(selected) ? selected : []
      value = value.filter((data) => !data?.exclusive)

      const alreadySetted = selected?.find(
        (select) => select.value === item.value
      )

      if (alreadySetted) {
        value = selected.filter((select) => select.value !== item.value)
      } else {
        if (value.length === maxQuantitySelect) {
          value.shift()
        }

        value.push(item)
      }

      methods.setValue(name, value)
    },
    [maxQuantitySelect, methods, multiSelect, name, selected]
  )

  const isSetted = useCallback(
    (item) => {
      let value = selected?.value === item?.value

      if (multiSelect) {
        value = !!selected?.find((data) => data.value === item.value)
      }

      return value
    },
    [multiSelect, selected]
  )

  useEffect(() => {
    if (modalActive) {
      dataLayer.push({
        event: 'step_change',
        etapa: `/evy/dados-veiculo/${gtmName}`,
      })
    }
  }, [dataLayer, gtmName, modalActive])

  const renderOption = useCallback(
    (item, index) => {
      return (
        <S.Option
          key={index}
          onClick={() => {
            setValue(item)
            if (!multiSelect) {
              setModalActive(false)
            }
          }}
          data-gtm-type="click"
          data-gtm-clicktype="select"
          data-gtm-name={item}
        >
          <S.Icon selected={isSetted(item)} />
          <S.WrapDelivery>
            <S.TextOption>{item.text}</S.TextOption>

            {item.delivery ? (
              <S.Delivery code={item.delivery.code}>
                {item.delivery.text}
              </S.Delivery>
            ) : (
              <></>
            )}
          </S.WrapDelivery>
        </S.Option>
      )
    },
    [isSetted, multiSelect, setValue]
  )

  const renderContent = useCallback(() => {
    return options.map(renderOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, renderOption, selected])

  const renderPlaceholder = useCallback(() => {
    if (!multiSelect) {
      return selected?.text || placeholder
    }

    const item = selected || []
    const text = item.map((data) => data.text).join(', ')

    return text || placeholder
  }, [multiSelect, placeholder, selected])

  return (
    <S.Container>
      <input {...register()} type="hidden" />

      <div>
        <S.Label containError={containError}>{label}</S.Label>

        <S.WrapInput>
          <S.Input
            onClick={() => setModalActive(true)}
            containError={containError}
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name={selected?.text || ''}
            data-gtm-subname={gtmName}
          >
            <S.Placeholder hasValue={selected}>
              {renderPlaceholder()}
            </S.Placeholder>
          </S.Input>
        </S.WrapInput>
        {!!containError && <S.Error>{containError}</S.Error>}
      </div>

      <Modal
        title={title}
        visible={modalActive}
        setIsVisible={setModalActive}
        buttonText="Salvar"
      >
        <S.List>{renderContent()}</S.List>
      </Modal>
    </S.Container>
  )
}

export default SelectModal
