import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { getCategories, getProducts } from 'services/api'
import useFlow from 'store'

import { updateCustomData } from 'utils'

import { Card } from './components'
import { Cards, Content, Price, Select, Flex, Text, WrapHeader } from './styles'

export default function List() {
  const price = useMemo(
    () => ({
      asc: 'minPackage:asc',
      desc: 'minPackage:desc',
    }),
    []
  )

  const { setValue, ...forms } = useForm()
  const { dataLayer = [] } = window

  const {
    store: { zone, allVehicles },
    setLoading,
    setAllVehicles,
    setAllVehiclesCategory,
  } = useFlow()

  const [vehicles, setVehicles] = useState([])
  const [options, setOptions] = useState([])

  const category = forms.watch('category')
  const sort = forms.watch('sort')

  const filterByCategory = useCallback(() => {
    if (!options?.length || !allVehicles?.list?.length) {
      return
    }

    let vehiclesRaw = allVehicles?.list

    if (category !== 'all' && category !== '') {
      vehiclesRaw = allVehicles?.list.filter((item) => {
        return item?.vehicle?.category?.slug === category
      })
    }

    vehiclesRaw = vehiclesRaw.sort((prev, current) => {
      if (!prev?.minorPackage?.cost || !current?.minorPackage?.cost) {
        return -1
      }

      const prevMinor =
        prev?.minorPackage?.cost?.rawValue <
        current?.minorPackage?.cost?.rawValue

      if (prevMinor) {
      }

      if (price.asc === sort) {
        if (prevMinor) return -1
      } else if (!prevMinor) {
        return -1
      }

      return 1
    })

    setVehicles(vehiclesRaw)
  }, [options?.length, allVehicles?.list, category, price.asc, sort])

  const fetchVehicles = useCallback(async () => {
    if (allVehicles?.list?.length) {
      return
    }

    try {
      setLoading({ visible: true, text: 'Buscando veículos...' })

      let response = await getProducts({
        visible: true,
        states: zone,
      })

      response = response.map((item) => ({
        ...item,
        minorPackage: item?.packages?.reduce((prev, curr) => {
          if (!prev || !prev?.cost?.rawValue) {
            return curr
          }

          if (!curr || !curr?.cost?.rawValue) {
            return prev
          }

          return prev?.cost?.rawValue < curr?.cost?.rawValue ? prev : curr
        }, {}),
      }))

      setVehicles(response)
      setAllVehicles({
        list: response,
      })

      return response
    } catch (e) {
      console.error(e)
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }, [allVehicles, setAllVehicles, setLoading, zone])

  const updateStates = useCallback(() => {
    if (allVehicles?.list?.length && !vehicles?.length) {
      setVehicles(allVehicles?.list)
    }

    if (allVehicles?.categories?.length && !options?.length) {
      setOptions(allVehicles?.categories)
    }

    if (allVehicles?.sort && !sort) {
      setValue('sort', allVehicles?.sort)
    }
    if (allVehicles?.category && !category) {
      setValue('category', allVehicles?.category)
    }
  }, [allVehicles, category, setValue, options?.length, sort, vehicles?.length])

  const toggleSort = useCallback(() => {
    let order = price.asc

    if (sort === price.asc) {
      order = price.desc
    }

    setValue('sort', order)
  }, [setValue, price, sort])

  const fetchCategories = useCallback(async () => {
    if (allVehicles?.categories?.length) {
      return
    }

    setLoading({ visible: true, text: 'Buscando categorias...' })
    try {
      const response = await getCategories()

      const options = response.map((item) => ({
        value: item.slug,
        label: item.title,
      }))

      const categories = [
        {
          value: 'all',
          label: 'Todos',
        },
        ...options,
      ]

      setOptions(categories)

      setAllVehiclesCategory({
        categories,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }, [allVehicles?.categories?.length, setAllVehiclesCategory, setLoading])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'step2',
      },
    })

    if (category) {
      setAllVehicles({
        category,
      })
      dataLayer.push({
        event: 'step_change',
        etapa: `/evy/categoria/${category}`,
      })
    }
  }, [category, setAllVehicles, dataLayer])

  useEffect(() => {
    if (sort) {
      setAllVehicles({
        sort,
      })
    }
  }, [sort, setAllVehicles])

  useEffect(() => {
    filterByCategory()
  }, [filterByCategory])

  useEffect(() => {
    updateStates()
  }, [updateStates])

  useEffect(() => {
    if (zone && options.length > 0) {
      fetchVehicles()
    }
  }, [zone, fetchVehicles, options])

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  return (
    <Content>
      <FormProvider {...forms}>
        <WrapHeader>
          <Text>Escolha o seu veículo</Text>

          <Flex>
            <input {...forms.register('sort')} name="sort" type="hidden" />
            <Select
              placeholder="Categorias"
              name="category"
              options={options}
            />
            <Price
              src="/images/price-filters.svg"
              onClick={toggleSort}
              inverted={sort === price.asc}
            />
          </Flex>
        </WrapHeader>
      </FormProvider>

      <Cards>
        {vehicles?.map((item) => (
          <Card allVehicles={vehicles} data={item} key={item.id} />
        ))}
      </Cards>
    </Content>
  )
}

List.properties = {
  pageStep: 'Veículos',
  showStep: false,
}
