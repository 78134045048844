import * as S from './styles'

const Text = ({
  children,
  bold = false,
  title = false,
  color,
  textType = 'body',
}) => {
  const props = {
    children,
    bold,
    title,
    color,
    textType,
  }

  if (title) {
    return <S.Title {...props}>{children}</S.Title>
  }

  return <S.Text {...props}>{children}</S.Text>
}

export default Text
