import api from 'services/api'

import { encryptByCrypto } from 'utils'

export async function postResetPassword(data, token) {
  try {
    const response = await api.post('/cx_authentication/reset_password', {
      password: encryptByCrypto(
        data.password,
        process.env.REACT_APP_APPAES_SECRET
      ),
      password_confirmation: encryptByCrypto(
        data.password_confirmation,
        process.env.REACT_APP_APPAES_SECRET
      ),
      token: token,
    })

    return response
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Bank.'
    )
  }
}
