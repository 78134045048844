import React, { useEffect } from 'react'

import useFlow from 'store'
import { FloatingButton, Container } from 'components'

import { formatMoney, updateCustomData } from 'utils'

import * as S from './styles'

export default function Denied() {
  const { dataLayer = [] } = window

  const {
    store: { order },
  } = useFlow()
  const { firstName = 'Cliente' } = order?.customer || {}

  useEffect(() => {
    if (!!order.product && !!order.summary) {
      updateCustomData({
        page: {
          step: 'step3',
        },
        carro: {
          categoria: order?.product.vehicle.category.slug,
          marca: order?.product.vehicle.model.brand.title.toLowerCase(),
          id: order?.product.id,
          nome: order?.product.vehicle.model.specification,
          variacao: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
          preco: formatMoney(
            order?.summary.baseCost.rawValue * order?.summary.months
          ),
        },
      })
    }

    dataLayer.push({
      event: 'step_change',
      etapa: '/status-proposta/denied',
    })
  }, [dataLayer, order.product, order.summary])

  return (
    <Container image="/images/negado.png" fullHeight={true}>
      <S.Wrapper>
        <S.Title>Olá, {firstName}</S.Title>

        <S.Text>
          Agradecemos o seu interesse, mas infelizmente não foi possível
          concluir sua solicitação neste momento.
        </S.Text>
        <S.Text>
          Seu cadastro não cumpriu algum de nossos requisitos para a assinatura
          Carro por Assinatura Porto Bank.
        </S.Text>
        <S.Text>
          Que tal conhecer nosso outro serviço Porto? O{' '}
          <strong>Seminovos Porto Bank</strong>. Acesse e conheça nossos
          modelos.
        </S.Text>
      </S.Wrapper>
      <FloatingButton
        rounded
        text="Ver seminovos"
        gtmName="ver-semi-novos"
        action={() =>
          (window.location.href =
            'https://carrofacilseminovos.com.br/?utm_source=carro-facil&utm_medium=own&utm_campaign=analise-interna')
        }
      />
    </Container>
  )
}

Denied.properties = {
  pageStep: 'Negado',
  showStep: false,
}
