import styled, { css } from 'styled-components'
import { breakpoints } from 'styles'

export const Container = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 1000;

  width: 100%;

  ${({ hasBg }) =>
    hasBg &&
    css`
      background: rgba(255, 255, 255, 0.5);
    `}
`

export const Text = styled.p`
  color: #bababa;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 10px;
  text-align: center;
  width: 50px;

  @media (min-width: ${breakpoints.small}) {
    width: 100%;
  }
`

export const Round = styled.div`
  width: 30px;
  height: 8px;
  margin-bottom: 5px;

  border-radius: 10px;
  border: 2px solid #bababa;

  @media (min-width: ${breakpoints.small}) {
    width: 60px;
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ active }) =>
    active &&
    css`
      ${Text} {
        color: ${({ theme }) => theme.colors.primary};
      }

      ${Round} {
        background: ${({ theme }) => theme.colors.primary};
        border-color: ${({ theme }) => theme.colors.primary};
      }
    `}
`
