import api from 'services/api'
import { queryString } from 'utils'

export const sendPayment = async (
  {
    cardName,
    cardNumber,
    cardVality,
    cardCVV,
    orderId,
    type,
    logoCode,
    expiration,
  },
  isMocked = false
) => {
  if (!!isMocked) {
    return ''
  }

  try {
    const date = cardVality?.split('/')

    const payload = {
      cardNumber: cardNumber,
      month: date && date[0],
      year: date && date[1],
      cvv: cardCVV,
      name: cardName,
      type: type,
      logoCode,
      expiration,
    }

    const params = {
      order_id: orderId,
    }

    const queryParms = queryString(params)

    const response = await api.post(
      `/integracao/payments?${queryParms}`,
      payload
    )

    if (response.data.status !== 'OK') {
      throw response.data.message
    }

    return response.data
  } catch (error) {
    const err = new Error(error?.data?.message || 'Falha ao prosseguir.')

    err.code = error.response.status

    throw err
  } finally {
    api.defaults.timeout = 30000
  }
}
