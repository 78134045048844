import React, { useEffect } from 'react'

import useFlow from 'store'
import { Flex, FloatingButton, Text } from 'components'

import { Container, Content } from './styles'
import { encryptTextWithKey, formatMoney, updateCustomData } from 'utils'

export default function Completed() {
  const { dataLayer = [] } = window
  const {
    store: { order },
    setLoading,
  } = useFlow()
  const { firstName = 'Cliente' } = order?.customer || {}

  useEffect(() => {
    setLoading({ visible: false, text: '' })
  }, [setLoading])

  useEffect(() => {
    if (order) {
      updateCustomData({
        page: {
          step: 'pagamento-status',
        },
        carro: {
          categoria: order?.product.vehicle.category.slug,
          marca: order?.product.vehicle.model.brand.title.toLowerCase(),
          id: order?.product.id,
          nome: order?.product.vehicle.model.specification,
          variacao: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
          preco: formatMoney(
            order?.summary.baseCost.rawValue * order?.summary.months
          ),
        },
      })
      dataLayer.push({
        event: 'purchase',
        etapa: '/checkout/pagamento-status/done',
        client_id: encryptTextWithKey(order?.customer.cpf.number, 'chave'),
        revenue: formatMoney(
          order?.summary.baseCost.rawValue * order?.summary.months
        ),
        coupon: order?.coupon,
        paymentMethod:
          {
            other: `cartao-de-credito`,
            porto: `cartao-de-credito-porto-seguro`,
            offer: 'oferta-cartao-de-credito-porto-seguro',
          }[order?.payment.type] || null,
        retorno: 'sucesso',
        descricao: 'Pagemento realizado com sucesso',
        transaction_id: '',
        oferta_cartao: '',
        products: [
          {
            porto_product: '',
            id: '',
            name: order?.product.vehicle.model.specification,
            brand: order?.product.vehicle.model.brand.title.toLowerCase(),
            price: formatMoney(
              order?.summary.baseCost.rawValue * order?.summary.months
            ),
            valor_parcela: formatMoney(order?.summary.baseCost.rawValue),
            category: order?.product.vehicle.category.slug,
            variant: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
            quantity: 1,
          },
        ],
      })
    }
  }, [dataLayer, order])

  return (
    <Container fullHeight={true}>
      <Content>
        <Text title color="white">
          Parabéns {firstName}.
        </Text>
        <Text title color="white">
          Tudo concluído por aqui!
        </Text>
        <Flex mt="50px" flexDirection="column">
          <Text color="white">
            Em alguns instantes enviaremos um e-mail para começarmos o check-in.
            <br />O seu prazo de entrega passa a contar após a finalização desta
            etapa, sendo assim, sugerimos que a conclua o quanto antes, para
            pegar o seu carro 0 km o mais rápido possível.
          </Text>
          <br />
          <Text color="white">
            Caso esta etapa não seja concluída em até <strong>4 dias</strong>, a
            solicitação do Carro por Assinatura Porto Bank será cancelada e será
            necessário iniciar um novo pedido. Além disso, caso tenha solicitado
            a confecção de um Cartão Porto Bank para o pagamento da sua
            assinatura, a solicitação também será declinada.
          </Text>
        </Flex>
      </Content>
      <FloatingButton
        text="Entendi"
        height="45px"
        gtmName="entendi"
        action={() =>
          (window.location.href = process.env.REACT_APP_URL_SITE) || '/'
        }
        rounded
        color="success"
      />
    </Container>
  )
}

Completed.properties = {
  title: 'Fluxo Completo',
}
