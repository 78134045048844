import { rem } from 'polished'
import styled, { css } from 'styled-components'
import { colors, fonts } from 'styles'

const bold = css`
  font-family: ${({ theme }) => theme.fonts.black};
`

const colorFormat = ({ color, theme }, fallbackColor = '') => {
  if (!color) {
    return fallbackColor
  }

  if (Object.keys(theme.colors).includes(color)) {
    return theme.colors[color]
  }

  return color
}

const textTypeCollection = {
  body: {
    desktop: css`
      font-size: ${rem('18px')};
      line-height: 1.6;
      letter-spacing: 0.6px;
    `,
    mobile: css`
      font-size: ${rem('16px')};
      color: ${colors.gray.medium};
      line-height: 1.4;
      letter-spacing: 0.4px;
    `,
  },
  tinyText: {
    desktop: css`
      font-size: ${rem('16px')};
    `,
    mobile: css`
      font-size: ${rem('14px')};
      color: ${colors.gray.medium};
      font-family: ${fonts.book};
    `,
  },
}

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.medium};

  ${({ textType }) => textTypeCollection[textType || 'body'].mobile};

  color: ${(props) => colorFormat(props)};

  margin-bottom: 12px;

  ${(props) => (props?.bold ? bold : '')};

  strong {
    ${bold};
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    ${({ textType }) => textTypeCollection[textType || 'body'].desktop};
  }
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.black};
  font-size: ${rem('18px')};
  margin-bottom: 12px;

  color: ${(props) => colorFormat(props, props.theme.colors.gray.dark)};

  strong {
    ${bold};
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('24px')};
  }
`
