import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: 50px auto;
  grid-column-gap: 10px;
  align-items: center;
`

export const Input = styled.input`
  position: relative;
  appearance: none;
  outline: none;
  width: 50px;
  height: 20px;

  background-color: #ffffff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #ffffff;
  transition-duration: 200ms;

  cursor: pointer;

  :checked {
    :after {
      left: 20px;

      box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  :after {
    content: '';
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    left: 1px;

    width: 26px;
    height: 16px;

    background-color: ${({ theme }) => theme.colors.gray.medium};
    border-radius: 39%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);

    transition: left 0.2s ease;
    will-change: left;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${({ theme }) => theme.colors.danger};
      box-shadow: 0px 0px 8px 1px ${({ theme }) => theme.colors.danger};
    `}
`

export const Label = styled.label`
  color: #fff;
  font-size: 10px;
  line-height: 15px;
`

export const Error = styled.p`
  position: absolute;
  bottom: -18px;
  left: 0;

  padding: 6px 0 0;
  text-align: center;

  font-size: 10px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.medium};
`
