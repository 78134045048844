import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

export default createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: "DrukWide-Bold";
    font-style: normal;
    src: url("/fonts/DrukWide-Bold.ttf");
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a { text-decoration: none; }

  html { font-size: 16px; background: #fff; }

  body {
    font-family: 'Open Sans', sans-serif;
    height: 100vh;
    transition: height 0.1s;

    .simplebar-scrollbar::before {
      background-color: #777;
    }

    .simplebar-track.simplebar-vertical {
      width: 8px;
    }
  }

  #root {
    height: 100%;
  }

  /* Move reCAPTCHA v3 badge to the left */
  .grecaptcha-badge {
    visibility: hidden;
  }

  input:disabled {
    cursor: not-allowed;
  }
`
