import { useStore } from 'effector-react'

import { $requestError } from './store'
import * as actions from './action'

export function useErrorHandler() {
  const { showModal } = useStore($requestError)

  return {
    showModal,
    ...actions,
  }
}
