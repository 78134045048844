import * as yup from 'yup'

export default yup.object().shape({
  carColor: yup.object().shape({
    text: yup.string().required('A cor é obrigatória'),
    value: yup.string().required('A cor é obrigatória'),
  }),
  plateNumber: yup
    .array()
    .test(
      'validate-plate',
      'Necessita selecionar ao menos 3 placas',
      (value) => {
        if (!value.length) {
          return false
        }

        if (value[0].exclusive) {
          return true
        }

        return value.length === 3
      }
    ),

  // info: yup
  //   .boolean()
  //   .required('Obrigatório')
  //   .oneOf([true], 'Obrigatório'),
  // terms: yup
  //   .boolean()
  //   .required('Obrigatório')
  //   .oneOf([true], 'Obrigatório'),
})
