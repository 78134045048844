/**
 *
 * @param {number} value
 * @returns string
 */
export const formatMoneyDecimalToBRL = (value = 0) => {
  const options = {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'decimal',
  }
  return String(new Intl.NumberFormat('pt-BR', options).format(+value / 100))
}

/**
 *
 * @param {string} value
 * @returns float
 */
export const formatMoneyDecimalFromBRL = (value = '0') => {
  const valueString = value.replace(/\./g, '').replace(',', '.')

  return parseFloat(valueString)
}
