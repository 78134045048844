import React, { useEffect } from 'react'

import useFlow from 'store'
import { Container } from 'components'

import { updateCustomData } from 'utils'

import * as S from './styles'

export default function DeniedSelfie() {
  const { dataLayer = [] } = window

  const { store } = useFlow()
  const { firstName } = store?.order?.customer

  useEffect(() => {
    updateCustomData({
      page: {
        title: 'Sobre você',
        step: 'negado-selfie',
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: 'analise-selfie-negado',
    })
  }, [dataLayer])

  return (
    <Container
      padding={[
        [45, 30, 20],
        [40, 18, 0],
      ]}
      image="/images/negado.png"
      fullHeight={true}
    >
      <S.Wrapper>
        <S.Title>Olá, {firstName || 'Cliente'}</S.Title>

        <S.Text>
          Agradecemos o seu interesse, mas infelizmente não foi possível
          concluir sua solicitação neste momento.
        </S.Text>
      </S.Wrapper>
    </Container>
  )
}
