import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Alert, Container } from 'components'

import {
  UnicoCheckBuilder,
  SelfieCameraTypes,
  UnicoThemeBuilder,
} from 'unico-webframe'

import { sendAnalyzeSelf } from 'services/api'
import { encryptTextWithKey, formatMoney, updateCustomData } from 'utils'
import { BoxCamera } from './styles'
import useFlow from 'store'
import { useErrorHandler } from 'store/orderRequest/hook'

const unicoTheme = new UnicoThemeBuilder()
  .setColorSilhouetteSuccess('#FFF')
  .setColorSilhouetteError('#D50000')
  .setColorSilhouetteNeutral('#fcfcfc')
  .setBackgroundColor('#0027F4')
  .setColorText('#FFF')
  .setBackgroundColorComponents('#FFF')
  .setColorTextComponents('#0027F4')
  .setBackgroundColorButtons('#FFF')
  .setColorTextButtons('#0027F4')
  .setBackgroundColorBoxMessage('#FFF')
  .setColorTextBoxMessage('#232323')
  .build()

const isDev = process.env.REACT_APP_UNICO_KEYS === 'dev'

const UploadCNH = () => {
  const history = useHistory()
  const { dataLayer = [] } = window

  const {
    store: { order },
    setLoading,
  } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const [showError, setShowError] = useState('')

  const onSubmit = async (selfie) => {
    try {
      setLoading({ visible: true, text: '' })

      await sendAnalyzeSelf({
        order_id: order.id,
        mask: false,
        encryptedBase64: selfie,
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'envio-selfie',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'sucesso',
        descricao: 'Selfie enviada com sucesso',
      })

      history.push('/envio-selfie')
    } catch (e) {
      setShowError(e.message)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'envio-selfie',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'erro',
        descricao: e.message,
        erro: {
          codigo: e.code,
          servico: '/ca_riskanalysis/face_match',
          mensagem: e.message,
        },
      })

      if (e.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }

  const unicoCamera = new UnicoCheckBuilder()
    .setResourceDirectory('/resources')
    .setModelsPath('/models')
    .setTheme(unicoTheme)
    .build()

  const handleUnicoSelf = async () => {
    setLoading({ visible: true, text: 'Abrindo câmera' })

    const unicoFile = isDev ? '/services-stg.json' : '/services-prd.json'

    const cameraPromised = unicoCamera.prepareSelfieCamera(
      unicoFile,
      SelfieCameraTypes.NORMAL
    )

    cameraPromised
      .then((cameraOpener) => {
        cameraOpener.open({
          on: {
            success: (obj) => {
              onSubmit(obj.encrypted)
            },
            error: (error) => {
              setShowError(
                'Ocorreu um erro ao inciar a camera, por favor tente novamente!'
              )
            },
          },
        })
      })
      .finally(() => {
        setLoading({ visible: false, text: '' })
      })
  }

  useEffect(() => {
    handleUnicoSelf()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'selfie',
      },
      carro: {
        nome: order?.product?.title,
        categoria: order?.product?.vehicle?.category?.title,
        marca: order?.product?.vehicle?.model?.brand?.title,
        id: order?.product?.vehicle?.id,
        preco: formatMoney(
          order?.package?.months * order?.package?.cost?.rawValue
        ),
        variacao: `${order?.colorName} | ${order?.package?.mileage} km | ${order?.package?.months} meses`,
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkin/selfie',
    })
  }, [dataLayer, order])

  const handleSDKonError = () => {
    setShowError('')
    handleUnicoSelf()
  }

  return (
    <Container
      padding={[
        [45, 30, 20],
        [40, 18, 0],
      ]}
    >
      <BoxCamera>
        <div id="box-camera" />
      </BoxCamera>

      <Alert
        isVisible={!!showError}
        actions={{
          primary: { onClick: handleSDKonError, label: 'Entendi' },
        }}
      >
        <p>{showError}</p>
      </Alert>
    </Container>
  )
}

export default UploadCNH
