import styled from 'styled-components'
import { breakpoints } from 'styles'

export const Form = styled.form`
  margin: 25px 0;
  padding: 35px 15px;

  background: #ffffff;
  border: 2px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
`

export const FormGrid = styled.div`
  display: grid;
  margin-top: 20px;
  grid-gap: 30px;

  @media (min-width: ${breakpoints.medium}) {
    grid-template-columns: 1fr 1fr;
  }
`
