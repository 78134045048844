import api from 'services/api'

export async function getBroker(susep) {
  try {
    const response = await api.get(`/integracao/users`, {
      params: {
        'broker.susep': susep || '',
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
