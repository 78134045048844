import styled, { css } from 'styled-components'
import { rem, darken } from 'polished'
import styledMap from 'styled-map'
import { layout, space, typography, shadow, border } from 'styled-system'

import colors from 'styles/colors'
import { componentLoader } from 'styles/helpers'
import { breakpoints } from 'styles'

const buttonTheme = styledMap('color', {
  ...colors,
  gray: colors.gray.light,
  white: '#fff',
  default: colors.primary,
})

export const Container = styled.button`
  all: unset;
  -webkit-text-fill-color: ${(props) =>
    buttonTheme(props) === '#fff' ? colors.primary : '#fff'};
  opacity: 1;

  font-size: ${rem('14px')};
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: 0 8px;

  ${layout}
  ${space}
  ${typography}
  ${shadow}
  ${border}

  color: ${(props) =>
    buttonTheme(props) === '#fff' ? colors.primary : '#fff'};
  cursor: pointer;
  transition: 0.3s;
  background-color: ${(props) => buttonTheme(props)};
  font-family: ${({ theme }) => theme.fonts.bold};

  &:hover {
    transition: 0.3s;
    background-color: ${(props) => darken('.06', buttonTheme(props))};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.gray.light};
  }

  ${({ isLoading }) =>
    isLoading && componentLoader({ centered: true, visible: true })}

  ${({ block }) =>
    block &&
    css`
      width: 100%;
      display: block;

      text-align: center;
    `}

  ${({ outline }) =>
    outline &&
    css`
      border: 2px solid ${buttonTheme};
      background-color: transparent;
      color: ${buttonTheme};
      -webkit-text-fill-color: ${buttonTheme};

      :hover {
        color: ${(props) =>
          buttonTheme(props) === '#fff' ? colors.primary : '#fff'};
        -webkit-text-fill-color: ${(props) =>
          buttonTheme(props) === '#fff' ? colors.primary : '#fff'};
      }
    `}

  ${({ link }) =>
    link &&
    css`
      box-shadow: none;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.gray.medium};

      &:hover {
        text-decoration: underline;
        background-color: transparent;
      }
    `}


  @media (min-width: ${breakpoints.small}) {
    width: ${rem('220px')};
    height: ${({ height }) => height || rem('57px')};
  }
`
