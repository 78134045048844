import { css } from 'styled-components'

export const componentLoader = ({
  centered,
  centeredRight,
  visible,
  primary,
}) => css`
  position: relative;

  &:after {
    width: 12px;
    height: 12px;
    display: block;

    content: '';
    border-radius: 30px;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    animation: spinAround 500ms infinite linear;
  }

  ${centered &&
    css`
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;

        margin-top: -5px;
        margin-left: -5px;
      }
    `}

  ${centeredRight &&
    css`
      &:after {
        position: absolute;
        top: 50%;
        right: 10px;

        margin-top: -5px;
      }
    `}

  ${visible &&
    css`
      color: transparent;
      -webkit-text-fill-color: transparent;

      &:after {
        border-color: #fff;
        border-top-color: transparent;
        border-right-color: transparent;
      }
    `}

  ${primary &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      -webkit-text-fill-color: ${({ theme }) => theme.colors.primary};
      &:after {
        border-color: ${({ theme }) => theme.colors.primary};
        border-top-color: transparent;
        border-right-color: transparent;
      }
    `}

  @keyframes spinAround {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`
