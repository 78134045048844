import { rem } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  input {
    padding-left: 18px;
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    input {
      padding-left: 25px;
    }
  }
`

export const Prefix = styled.span`
  position: absolute;

  bottom: 6px;
  left: 6px;

  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    bottom: 11px;
    font-size: ${rem('16px')};
  }
`
