import styled from 'styled-components'
import { breakpoints } from 'styles'

export const Container = styled.div`
  display: none;

  @media (min-width: ${breakpoints.medium}) {
    display: flex;
    height: 100%;

    background-image: url(${({ background }) => background});
    background-position: center;
    background-size: cover;
  }
`
