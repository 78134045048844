import { STATUS, CHECKIN_STATUS } from 'helpers'
import { getOrderById } from 'services/api'
import { encryptTextWithKey } from './encryption'

export const documentCheck = async (
  token,
  setOrder,
  dataLayer,
  history,
  setStep1Info,
  setLoading
) => {
  if (!token) {
    return
  }

  const statusFilter = [STATUS.PENDING]

  const uncancelableStatus = [
    STATUS.PAYMENT,
    STATUS.DONE,
    STATUS.CNH_SENT,
    STATUS.CNH_REJECTED,
    STATUS.CNH_APPROVED,
    STATUS.SELFIE,
    STATUS.SEND_SELFIE,
    STATUS.SELFIE_REJECTED,
    STATUS.CANCELED_ZENDESK,
    STATUS.PCO_TIMEOUT,
  ]
  setLoading({ visible: true, text: 'Buscando dados do contrato' })
  try {
    const order = await getOrderById(token)

    if (!statusFilter.includes(order?.status)) {
      if (order.status === STATUS.APPROVED_ZENDESK) {
        const { customer } = order

        setStep1Info({
          name: customer.fullName,
          email: customer.email,
          cellphone: customer.cellphone,
          document: customer.cpf.number,
          defaultAddress: {
            ...customer.defaultAddress,
          },
          birthdate: customer.birthdate,
          customer: {
            ...customer,
          },
        })

        history.push('/consent')
        return
      }

      if (order.status === STATUS.CANCELED) {
        const { customer } = order
        setStep1Info({
          customer: {
            id: customer.id,
          },
        })

        history.push('/step1')
        return
      }

      setOrder({ ...order })

      dataLayer.push({
        event: 'validacao_cpf',
        nome_servico: 'validar',
        tipo_servico: 'CPF',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        origem: order.origin,
        susep: order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'sucesso',
        descricao: 'usuario-possui-assinatura',
      })

      if (uncancelableStatus.includes(order.status)) {
        const route = await redirectUser(order.status)
        history.push(route)
        return
      }

      history.push('/orders-check')
      return
    }

    dataLayer.push({
      event: 'validacao_cpf',
      nome_servico: 'validar',
      tipo_servico: 'CPF',
      client_id: '',
      origem: '',
      susep: '',
      product: 'carro-facil',
      retorno: 'sucesso',
      descricao: 'usuario-nao-possui-assinatura',
    })

    history.push('/step1')
  } catch (error) {
    dataLayer.push({
      event: 'validacao_cpf',
      nome_servico: 'validar',
      tipo_servico: 'CPF',
      client_id: '',
      origem: '',
      susep: '',
      product: 'carro-facil',
      retorno: 'erro',
      descricao: 'instabilidade-no-sistema',
      erro: {
        codigo: error.code,
        servico: '/orders?customer.cpf.number=',
        mensagem: error.message,
      },
    })
    console.error(error)
  } finally {
    setLoading({ visible: false, text: '' })
  }
}

export const redirectUser = async (status) => CHECKIN_STATUS[status]
