import { ChatBox, Container, FloatingButton, Icon, Alert } from 'components'
import { PCO_STATUS_ENUM } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { updateCustomer, sendToRiskAnalyze } from 'services/api'

import useFlow from 'store'
import { useErrorHandler } from 'store/orderRequest/hook'
import { encryptTextWithKey, formatMoney, updateCustomData } from 'utils'

import * as S from './styles'

const SignatureButtons = ({ action, icon, gtmName }) => (
  <S.Button
    type="button"
    onClick={action}
    data-gtm-type="click"
    data-gtm-clicktype="button"
    data-gtm-name={gtmName}
  >
    <Icon size="22px" color="#FFF" name={icon} />
  </S.Button>
)

export default function UserSignature() {
  const { dataLayer = [] } = window
  const {
    store: { order },
    setOrder,
    setLoading,
  } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const sigCanvasRef = useRef(null)
  const history = useHistory()

  const [userSignin, setUserSignin] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [visibility, setVisibility] = useState(false)
  const [error, setError] = useState('')

  const clearUserSignature = () => {
    sigCanvasRef.current.clear()
    setUserSignin('')
  }
  const signatureToBase64 = () =>
    setUserSignin(
      sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
    )

  const submitSignature = async () => {
    if (sigCanvasRef.current.isEmpty()) {
      setVisibility(true)
      setError('Para proseguir é necessário Assinar o documento')
      return
    }

    const [, signature] = userSignin.split(',')

    try {
      setIsLoading(true)
      setLoading({ visible: true, text: '' })
      const customer = await updateCustomer(order?.customer?.id, {
        signature,
        order_id: order?.id,
      })

      const { decision } = await sendToRiskAnalyze({ order_id: order?.id })

      setOrder({
        ...order,
        customer,
      })

      const goTo = PCO_STATUS_ENUM[decision] || PCO_STATUS_ENUM.pco_timeout

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'envio-assinatura',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'sucesso',
        descricao: 'Assinatura enviada com sucesso',
      })

      history.push(goTo)
    } catch (error) {
      setError(error.message)
      setVisibility(true)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'envio-assinatura',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'erro',
        descricao: error.message,
        erro: {
          codigo: error.code,
          servico: '/ca_riskanalysis/send_collected',
          mensagem: error.message,
        },
      })

      if (error.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    } finally {
      setIsLoading(false)
      setLoading({ visible: false, text: '' })
    }
  }

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'assinatura',
      },
      carro: {
        nome: order?.product?.title,
        categoria: order?.product?.vehicle?.category?.title,
        marca: order?.product?.vehicle?.model?.brand?.title,
        id: order?.product?.vehicle?.id,
        preco: formatMoney(
          order?.package?.months * order?.package?.cost?.rawValue
        ),
        variacao: `${order?.colorName} | ${order?.package?.mileage} km | ${order?.package?.months} meses`,
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkin/assinatura',
    })
  }, [dataLayer, order])

  return (
    <Container
      padding={[
        [45, 30, 20],
        [40, 18, 0],
      ]}
    >
      <div>
        <ChatBox
          text={`
            <p>
              <strong class="text-wide">${order?.customer?.fullName}</strong>, estamos quase lá! Só Precisamos da sua assinatura.
            </p>
            <p>Assine como você assina sua CNH.</p>
          `}
        />

        <S.Content>
          <S.Wrapper>
            <SignatureCanvas
              ref={sigCanvasRef}
              penColor="#0027F4"
              canvasProps={{
                className: 'sigCanvas',
              }}
            />
          </S.Wrapper>

          <S.Buttons>
            <SignatureButtons
              action={clearUserSignature}
              icon="rotate-ccw"
              gtmName="recarregar"
            />
            <SignatureButtons
              action={signatureToBase64}
              icon="check"
              gtmName="adicionar-assinatura"
            />
          </S.Buttons>
        </S.Content>
      </div>

      <FloatingButton
        rounded
        disabled={!userSignin || isLoading}
        isLoading={isLoading}
        action={submitSignature}
        text="Enviar assinatura"
        gtmName="enviar-assinatura"
        gtmClickType="submit"
        height="57px"
      />

      <Alert
        isVisible={visibility}
        actions={{
          primary: { onClick: () => setVisibility(false), label: 'Entendi' },
        }}
      >
        <p>{error}</p>
      </Alert>
    </Container>
  )
}
