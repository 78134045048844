export const STATUS = Object.freeze({
  PENDING: 'pending',
  PREAPP: 'preapp',
  OFFER: 'offer',
  PRODUCT: 'product',
  PERFIL: 'perfil',
  ORDERED: 'ordered',
  APPROVED: 'approved',
  DENIED: 'denied',
  DRIVERS: 'drivers',
  TERMS: 'terms',
  PAYMENT: 'payment',
  CANCELED: 'canceled',
  EXPIRED_CART: 'expired_cart',
  RESERVATION: 'reservation',

  DONE: 'done',
  CNH_SENT: 'cnh_sent',
  SEND_CNH: 'send_cnh',
  CNH_REJECTED: 'cnh_rejected',
  CNH_APPROVED: 'cnh_approved',

  SEND_SELFIE: 'send_selfie',
  SELFIE_REJECTED: 'selfie_rejected',
  SELFIE: 'selfie',

  PCO_TIMEOUT: 'pco_timeout',

  APPROVED_ZENDESK: 'approved_zendesk',
  CANCELED_ZENDESK: 'canceled_zendesk',
})

export const CHECKIN_STATUS = Object.freeze({
  done: '/checkin',
  cnh_sent: '/upload-cnh',
  send_cnh: '/upload-cnh',
  cnh_rejected: '/upload-cnh',
  cnh_approved: '/checkin-self',
  send_selfie: '/checkin-self',
  selfie: '/assinar',
  selfie_rejected: '/selfie-denied',

  pco_timeout: '/em-analise',
  derivation: '/em-analise',

  approved_zendesk: '/bem-vindo',
  canceled_zendesk: '/denied',

  preapp: '/step1',
  pending: '/consent',
  offer: '/step2',
  perfil: '/analysis',
  reservation: '/terms',
  payment: '/completed',
  ordered: '/analysis',
  denied: '/denied',
  default: '/step1',
})

export const prepareToRedirect = (status, customer, order) => {
  if (Object.keys(CHECKIN_STATUS).includes(status)) {
    return CHECKIN_STATUS[status]
  }

  if (status === 'product') {
    if (customer.income) return '/step4'

    return '/step3'
  }

  if (status === 'approved') {
    if (!!customer?.cnh?.number) {
      return '/step6'
    }

    return '/step5'
  }

  if (status === 'terms') {
    if (!!order?.pco?.token) return '/selecao-cartao'

    return '/resume'
  }
}

export const PCO_STATUS_ENUM = Object.freeze({
  approved: '/bem-vindo',
  denied: '/denied',
  pco_timeout: '/em-analise',
  none: '/denied',
})

export const PAYMENT = Object.freeze({
  PORTO: 'porto',
  OFFER: 'offer',
  OTHER: 'other',
})

export const RESET_BIRTH_DATE = Object.freeze('1900-01-01')
