import styled, { css } from 'styled-components'
import { breakpoints } from 'styles'
import { Container as ButtonContainer } from 'components/Button/styles'

const baseText = css`
  font-weight: 200;
  font-size: 14px;
  line-height: 16px;

  color: #eff0f0;
`

const baseDivider = css`
  border-bottom: 1px solid #405df7;
  padding-bottom: 1rem;
  margin: 0.8rem 0;
`

export const Container = styled.div`
  padding: 45px 16px;
  padding-bottom: 1rem;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.small}) {
    padding: 70px 50px;
  }
`

export const Content = styled.div`
  padding-bottom: 30px;

  display: flex;
  flex-direction: column;
`

export const Card = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 16px;
  padding: 20px;
  color: #fff;
  width: 100%;
  margin-top: 2rem;

  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: unset;
  grid-gap: 30px;
  align-items: center;

  img {
    width: 100%;
    object-fit: cover;
  }

  @media (min-width: ${breakpoints.small}) {
    grid-template-rows: unset;
    grid-template-columns: 1fr 1fr;
    padding: 20px 15px;

    > div {
      grid-column-start: 2;
    }
  }
`

export const Title = styled.div`
  ${baseDivider}
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    margin: 0;
  }

  p {
    ${baseText}
  }
`

export const Package = styled.div`
  ${baseDivider}
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    ${baseText}
    font-weight: 600;
    color: #fff;
  }

  p {
    ${baseText}
    line-height: 20px;
  }
`

export const PackageItem = styled.div`
  width: 50%;
`

export const Price = styled.div`
  display: block;

  font-size: 18px;
  line-height: 22px;
  font-weight: 200;
  strong {
    font-weight: 600;
    color: #fff;
  }
`

export const PriceOld = styled.p`
  font-size: 12px;
  text-decoration: line-through;
`

export const WrapButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  width: 100%;

  @media (min-width: ${breakpoints.small}) {
    margin-top: 0;
    justify-content: flex-start;
  }

  ${ButtonContainer} {
    width: 100px;
    height: 40px;
  }
`

export const Coupon = styled.div`
  margin-top: 20px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 12px;
  }

  @media (min-width: ${breakpoints.small}) {
    align-self: flex-start;
    width: 100%;

    form {
      display: flex;
      align-items: flex-end;
      gap: 20px;
    }
  }
`

export const WrapInput = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.small}) {
    width: 400px;
  }
`
