import { rem } from 'polished'
import styled, { css } from 'styled-components'
import { breakpoints } from 'styles'

const flex = css`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.small}) {
    flex-direction: row;
  }
`

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 16px;
  padding: 2rem;
  margin: 1.2rem 0;
  color: #fff;
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.small}) {
    height: ${rem('280px')};
  }
`

export const CardContent = styled.div`
  ${flex}
  justify-content: space-between;
  gap: 2rem;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p {
    color: #fff;
    font-family: ${({ theme }) => theme.fonts.medium};
  }
`

export const IconWrapper = styled.div`
  ${flex}
`
