import { AES } from 'crypto-js'

export const handleDocumentEncrypt = (document) => {
  const encrypt = AES.encrypt(
    document,
    process.env.REACT_APP_ENCRYPT_KEY
  ).toString()

  return encrypt.replaceAll('/', '2wW07lM')
}
