import * as yup from 'yup'

export default yup.object().shape({
  defaultAddress: yup.object().shape({
    cep: yup.string().required('Por favor insira seu cep'),
    number: yup
      .string()
      .required('Por favor insira o numero')
      .test(
        'number-test',
        'Por favor insira um número válido',
        (value) => parseInt(value) > 0
      ),
    street: yup.string().required('Por favor insira a rua'),
    neighborhood: yup.string().required('Por favor insira o bairro'),
    city: yup.string().required('Por favor insira a cidade'),
    state: yup.string().required('Por favor insira o estado'),
  }),
})
