import * as S from './styles'

export default function ChatBox({ text }) {
  return (
    <S.ChatBox>
      <S.Car src="/images/evy-avatar.png" />
      <S.Text dangerouslySetInnerHTML={{ __html: text }} />
    </S.ChatBox>
  )
}
