import React, { useState, useEffect, useRef } from 'react'
import Carousel, { ModalGateway, Modal } from 'react-images'

import { Container, Item, Image, Wrapper } from './styles'

export default function Gallery({ data }) {
  const scrollRef = useRef(null)

  const [viewer, setViewer] = useState({
    selected: 0,
    visible: false,
  })
  const [images, setImages] = useState([])

  const handleViewer = selected =>
    setViewer({ selected, visible: !viewer.visible })

  useEffect(() => {
    setImages(data)
  }, [data])

  useEffect(() => {
    const ref = scrollRef.current
    if (scrollRef && ref) {
      const onWheel = e => {
        if (e.deltaY === 0) return
        e.preventDefault()
        ref.scrollTo({
          left: ref.scrollLeft + e.deltaY * 2,
          behavior: 'smooth',
        })
      }

      ref.addEventListener('wheel', onWheel)
      return () => ref.removeEventListener('wheel', onWheel)
    }
  }, [])

  return (
    <>
      <Container ref={scrollRef}>
        <Wrapper>
          {images.map(({ src }, index) => (
            <Item key={index} onClick={() => handleViewer(index)}>
              <Image src={src} />
            </Item>
          ))}
        </Wrapper>
      </Container>

      <ModalGateway>
        {viewer.visible && (
          <Modal onClose={handleViewer}>
            <Carousel views={images} currentIndex={viewer.selected} />
          </Modal>
        )}
      </ModalGateway>
    </>
  )
}
