import { yupResolver } from '@hookform/resolvers/yup'
import {
  Alert,
  FloatingButton,
  LineInput,
  ChatBox,
  Container,
  Button,
} from 'components'
import { useCallback, useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { setCouponCode, updateOrder } from 'services/api'
import useFlow from 'store'
import { useErrorHandler } from 'store/orderRequest/hook'
import {
  calculateDiscount,
  encryptTextWithKey,
  formatMoney,
  updateCustomData,
} from 'utils'
import schema from './schema'

import * as S from './styles'

export default function Resume() {
  const forms = useForm({
    resolver: yupResolver(schema),
  })
  const history = useHistory()
  const { dataLayer = [] } = window

  const {
    store: { order, coupon },
    setLoading,
    setCoupon,
    setOrder,
  } = useFlow()

  const { showModalHendler } = useErrorHandler()

  const {
    package: orderPackege,
    product: { vehicle },
  } = order

  const reservation = order?.reservations?.slice(-1).pop()

  const [parcels, setParcels] = useState(orderPackege?.cost.rawValue)
  const [coupom, setCoupom] = useState({
    perMonth: '',
    total: '',
  })
  const [discounts, setDiscounts] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState('')

  const onSubmit = async ({ coupon }) => {
    setLoading({ visible: true, text: 'Aplicando seu cupom.' })

    try {
      const { value, type, id } = await setCouponCode({
        code: coupon,
        vehicle_category: vehicle?.category?.title,
      })
      const orderPrice = orderPackege?.cost.rawValue * orderPackege?.months

      const finalPrice = calculateDiscount(orderPrice, value, type)

      setCoupom({
        perMonth: formatMoney((orderPrice - finalPrice) / orderPackege?.months),
        total: formatMoney(orderPrice - finalPrice),
      })
      setDiscounts(finalPrice / orderPackege?.months)
      setParcels(finalPrice / orderPackege?.months)
      setCoupon(id)
      setAlertText('Cupom inserido com sucesso. Aproveite o desconto!')
      setShowAlert(true)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'aplicar-cupom',
        tipo_servico: 'cupom',
        client_id: encryptTextWithKey(order?.customer.cpf.number, 'chave'),
        descricao: 'Cupom aplicado com sucesso',
        retorno: 'sucesso',
      })
    } catch (error) {
      setAlertText(error?.message)
      setShowAlert(true)
      forms.setError('coupon', {
        type: 'custom',
        message: 'Cupom inválido!',
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'aplicar-cupom',
        tipo_servico: 'cupom',
        client_id: encryptTextWithKey(order?.customer.cpf.number, 'chave'),
        retorno: 'erro',
        descricao: error.message,
        erro: {
          codigo: error.code,
          servico: '/coupons',
          mensagem: error.message,
        },
      })
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }

  const onSubmitOrder = async () => {
    setLoading({
      visible: true,
      text: 'Quase lá você está bem próximo do seu Carro por Assinatura Porto Bank',
    })

    try {
      const orderResponse = await updateOrder(order.id, {
        coupon: coupon || null,
      })

      setOrder({ ...orderResponse })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'confirmar-resumo',
        client_id: encryptTextWithKey(order?.customer.cpf.number, 'chave'),
        retorno: 'sucesso',
        descricao: 'sucesso-confirmar-resumo',
      })

      history.push('/selecao-cartao')
    } catch (error) {
      let message = 'Ocorreu uma instabilidade, tente novamente'
      if (error?.response?.data?.message) {
        message = error?.response?.data?.message
      }

      if (error.code === 409) {
        showModalHendler({ showModal: true })
        return
      }

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'confirmar-resumo',
        client_id: encryptTextWithKey(order?.customer.cpf.number, 'chave'),
        retorno: 'erro',
        descricao: 'erro-confirmar-resumo',
        erro: {
          codigo: error.code,
          servico: '/orders',
          mensagem: error.message,
        },
      })
      setAlertText(message)
      setShowAlert(true)
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }

  const handleNotSelectedPlate = (plate) => {
    if (!plate) {
      return 'Indiferente'
    }

    let plates = '0123456789'
    plate.split('').forEach((element) => (plates = plates.replace(element, '')))

    return `${plates.split('').join(',')}`
  }

  useEffect(() => {
    ;(async () => {
      try {
        setLoading({ visible: true, text: 'Preparando termos de contrato' })

        if (!order?.id) {
          throw Error('Não foi possivel encontrar dados da order')
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading({ visible: false, text: '' })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'resume',
      },
      carro: {
        categoria: vehicle?.category.slug,
        marca: vehicle?.model.brand.title.toLowerCase(),
        id: order?.product.id,
        nome: vehicle?.model.specification,
        variacao: `${vehicle?.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
        preco: formatMoney(
          order?.summary.baseCost.rawValue * order?.summary.months
        ),
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkout/resumo',
    })
  }, [dataLayer, order, vehicle])

  const renderPerMonths = useCallback(() => {
    if (discounts) {
      return (
        <>
          <S.PriceOld>
            De{' '}
            <strong>
              {orderPackege?.months}x {formatMoney(orderPackege?.cost.rawValue)}
            </strong>
          </S.PriceOld>
          <S.Price>
            Por{' '}
            <strong>
              {orderPackege?.months}x {formatMoney(parcels)}
            </strong>
          </S.Price>
        </>
      )
    }

    return (
      <S.Price>
        Por{' '}
        <strong>
          {orderPackege?.months}x {formatMoney(parcels)}
        </strong>
      </S.Price>
    )
  }, [discounts, orderPackege, parcels])

  const removeCoupom = () => {
    setCoupon(null)
    setDiscounts('')
    setParcels(orderPackege?.cost.rawValue)
    forms.setValue('coupon', '')
  }

  return (
    <Container>
      <S.Content>
        <ChatBox
          text={`${order?.customer?.firstName}, chegou o momento de realizar o pagamento. O veículo será reservado para você pelas próximas 2 horas. Caso não haja confirmação após este período, o veículo será liberado novamente para assinatura.`}
        />

        <S.Card>
          <img
            src={vehicle?.model?.gallery[0]?.url}
            alt={`Carro ${vehicle?.model?.title} - ${vehicle?.specification}`}
          />

          <div>
            <S.Title>
              <h1>{vehicle?.model?.title}</h1>
              <p>{vehicle?.specification}</p>
            </S.Title>

            <S.Package>
              <S.PackageItem>
                <span>Período de contrato</span>
                <p>{orderPackege?.months} meses</p>
              </S.PackageItem>
              <S.PackageItem>
                <span>Pacote solicitado</span>
                <p>{orderPackege?.mileage / orderPackege?.months} Km/mês</p>
              </S.PackageItem>
            </S.Package>

            <S.Package>
              <S.PackageItem>
                <span>Cor do veículo</span>
                <p>{order?.colorName}</p>
              </S.PackageItem>
              <S.PackageItem>
                <span>Finais de placas</span>
                <p>{handleNotSelectedPlate(order?.plate)}</p>
              </S.PackageItem>
            </S.Package>

            {reservation?.deliveryCode && (
              <S.Package>
                <S.PackageItem>
                  <span>Estimativa de entrega</span>
                  <p>
                    {{
                      31003: '± 10 dias úteis',
                      31002: '± 30 dias úteis',
                      '0180D': '± 180 dias úteis',
                    }[reservation?.deliveryCode] || '-'}
                  </p>
                </S.PackageItem>
              </S.Package>
            )}

            {discounts ? (
              <S.Package>
                <S.PackageItem>
                  <span>Desconto total</span>
                  <p>{coupom.total}</p>
                </S.PackageItem>
                <S.PackageItem>
                  <span>Desconto por mês</span>
                  <p>{coupom.perMonth}</p>
                </S.PackageItem>
              </S.Package>
            ) : (
              <></>
            )}

            {renderPerMonths()}
          </div>
        </S.Card>

        <S.Coupon>
          <p>Deseja aplicar um cupom de desconto?</p>

          <FormProvider {...forms}>
            <form onSubmit={forms.handleSubmit(onSubmit)}>
              <S.WrapInput>
                <LineInput
                  name="coupon"
                  placeholder="Cupom"
                  label="Código do cupom"
                  defaultValue=""
                  disabled={discounts}
                  onChange={(e) =>
                    forms.setValue('coupon', e?.target?.value?.toUpperCase())
                  }
                />
              </S.WrapInput>

              <S.WrapButton>
                <Button
                  type="submit"
                  gtmName="aplicar"
                  gtmClickType="submit"
                  disabled={discounts}
                >
                  Aplicar
                </Button>

                {discounts && (
                  <Button type="button" color="danger" onClick={removeCoupom}>
                    Remover
                  </Button>
                )}
              </S.WrapButton>
            </form>
          </FormProvider>
        </S.Coupon>
      </S.Content>
      <FloatingButton
        rounded
        gtmClickType="submit"
        action={onSubmitOrder}
        gtmName="tudo-certo-vamos-seguir"
        text="Tudo certo, vamos seguir!"
      />

      <Alert
        isVisible={showAlert}
        actions={{
          primary: { onClick: () => setShowAlert(false), label: 'Entendi' },
        }}
      >
        <p>{alertText}</p>
      </Alert>
    </Container>
  )
}

Resume.properties = {
  pageStep: 'Resumo',
  showStep: true,
}
