const steps = [
  {
    step: 'Dados Pessoais',
    page: 'step1',
    position: 1,
  },
  {
    step: 'Sobre você',
    page: 'step3',
    position: 2,
  },
  {
    step: 'Endereço',
    page: 'step4',
    position: 3,
  },
  {
    step: 'Dados da CNH',
    page: 'step5',
    position: 4,
  },
  {
    step: 'Dados do veículo',
    page: 'step6',
    position: 5,
  },
  {
    step: 'Resumo',
    page: 'resume',
    position: 6,
  },
]

export default steps
