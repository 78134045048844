import { Box } from 'components/FloatingButton/styles'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  padding: 0 25px;

  display: flex;
  justify-content: flex-end;

  ${Box}:first-child {
    margin-right: 20px;
  }
`

export const Message = styled.h1`
  font-family: ${({ theme }) => theme.fonts.bold};
`

export const Frame = styled.div`
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 10px;

  text-align: right;
`

export const Label = styled.p`
  font-family: ${({ theme }) => theme.fonts.book};
  color: #333;
  font-size: 12px;
`
