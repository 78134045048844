export const stockStatusId = {
  TRANSITO: '31002',
  ESTOQUE: '31003',
}

export const stockStatusInverted = {
  31002: 'TRANSITO',
  31003: 'ESTOQUE',
}

export const stockStatusPriority = {
  ESTOQUE: 1,
  TRANSITO: 2,
  ENCOMENDA: 3,
}

export const stockPriorityByStatus = {
  1: 'ESTOQUE',
  2: 'TRANSITO',
  3: 'ENCOMENDA',
}

export const STOCK_CODES = {
  ESTOQUE: '± 10 dias úteis',
  TRANSITO: '± 30 dias úteis',
  ENCOMENDA: '± 180 dias úteis',
}

export const getDeliveryDate = (code) => {
  if (!Object.keys(stockStatusInverted).includes(code)) {
    return STOCK_CODES.ENCOMENDA
  }

  const key = stockStatusInverted[code]

  return STOCK_CODES[key]
}
