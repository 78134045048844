export const daysOption = (days = 31) => {
  let options = []

  for (let index = 1; index < days; index++) {
    let day = index.toString().padStart(2, '0')

    options.push({
      label: `Dia ${day}`,
      value: `${day}`,
    })
  }

  return options
}
