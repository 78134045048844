import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import Router from 'routes/router'
import { ThemeProvider } from 'styled-components'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import GlobalStyles from 'styles/global'
import * as theme from 'styles'
import 'services/firebase'
import { firebaseInit } from 'services/firebase'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { isMobile } from 'react-device-detect'

import { useErrorHandler } from 'store/orderRequest/hook'
import { Modal, Button } from 'components'
import useFlow from 'store'

const version = 'v2.0.51'

const App = () => {
  const { resetStore } = useFlow()
  const { showModal, showModalHendler } = useErrorHandler()

  async function init() {
    await firebaseInit()

    window.build_version = version
  }

  function resetHeight() {
    document.body.style.height = window.innerHeight + 'px'
  }

  useEffect(() => {
    init()

    if (isMobile) {
      resetHeight()
      window.addEventListener('resize', resetHeight, false)
    }

    return () => window.removeEventListener('resize', resetHeight, false)
  }, [])

  async function handleError() {
    showModalHendler({ showModal: false })
    resetStore()
    window.location.replace('/')
  }

  return (
    <React.StrictMode>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA}
        language="pt-BR"
      >
        <GlobalStyles />
        <ThemeProvider theme={theme}>
          <Router />

          <Modal
            title="Alerta"
            visible={showModal}
            onClickCloseButton={() => {}}
            buttonText="Entendi"
            buttonClose={() => <Button onClick={handleError}>Entendido</Button>}
          >
            <p>
              Não foi possível realizar a alteração solicitada. Parece que o
              registro que você está tentando modificar foi alterado em outro
              lugar.
            </p>
          </Modal>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
