import api from 'services/api'

export async function createReservation({
  order,
  color,
  chassi = '',
  vehiclePlate = '',
  product,
  deliveryCode,
  state,
}) {
  try {
    const payload = {
      order,
      chassi_number: chassi,
      plate: vehiclePlate,
      color,
      product,
      state,
      deliveryCode,
    }
    const response = await api.post('/integracao/reservations/book', payload)

    return response.data
  } catch (error) {
    const err = new Error(
      'Não foi possível gerar uma reserva. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}
