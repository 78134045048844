import styled, { css } from 'styled-components'
import { breakpoints } from 'styles'

const communText = css`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;
  line-height: 18px;
  color: rgba(5, 5, 5, 0.5);
`

export const Container = styled.article`
  padding: 70px 25px 18px;
  min-height: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.small}) {
    padding: 70px 30px 30px;
    height: 100%;
  }
`

export const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  padding: 30px;

  background-color: #e8e8e8;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 30px 0;
`

export const OldPrice = styled.div`
  ${communText}

  p {
    text-decoration: line-through;
  }
`

export const NewPrice = styled.div`
  ${communText}
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: end;
`

export const Content = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;

  margin-top: 40px;
`

export const Wrapper = styled.div`
  align-self: center;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  @media (min-width: ${breakpoints.small}) {
    flex-direction: row;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.small}) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
  }
`
