import Modal from '../Modal'
import LineInput from '../LineInput'

import { FormProvider, useForm } from 'react-hook-form'
import useFlow from 'store'

import * as S from './styles'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import validationSchema from './validation'
import { yupResolver } from '@hookform/resolvers/yup'

import { validateClientByPhone } from 'services/api'
import { documentCheck } from 'utils'

export function ReturnOrderModal({ showModal, setShowModal }) {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  })
  const history = useHistory()
  const { dataLayer = [] } = window

  const {
    store: { step1 },
    resetStore,
    setOrder,
    setStep1Info,
    setLoading,
  } = useFlow()

  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState('')
  const [isReseted, setIsReseted] = useState(false)

  const onSubmit = methods.handleSubmit(async (values) => {
    setIsLoading(true)
    try {
      const { data, status } = await validateClientByPhone(
        step1?.customer?.id,
        values.phone
      )

      if (status === 204) {
        resetStore()
        setHasError(
          'Número de tentivas excedido, sua proposta será cancelada e uma nova terá de ser iniciada.'
        )
        setIsReseted(true)
        return
      }

      setShowModal(false)
      await documentCheck(
        data?.token,
        setOrder,
        dataLayer,
        history,
        setStep1Info,
        setLoading
      )
    } catch (error) {
      setHasError(error.message)
    } finally {
      setIsLoading(false)
    }
  })

  const handleRestOrder = () => {
    setShowModal(false)
    window.location.reload(false)
  }

  return (
    <Modal
      title="Precisamos validar seus dados!"
      visible={showModal}
      setIsVisible={setShowModal}
      buttonText={!isReseted ? 'Enviar' : 'Entendi'}
      onClickCloseButton={!isReseted ? onSubmit : handleRestOrder}
    >
      <S.Wrapper>
        <FormProvider {...methods}>
          <p>
            Identificamos que você possui uma proposta de Carro por Assinatura
            em andamento, para garantir a segurança das informações, pode nos
            confirmar os últimos 4 dígitos de seu telefone celular?
          </p>
          <form onSubmit={onSubmit}>
            <LineInput
              name="phone"
              placeholder="0000"
              label="Código de confirmação"
              mask="####"
              maxLength="4"
              focusedColor="#0027F4"
              disabled={isLoading}
              isLoading={isLoading}
              numericOnly
              defaultValue=""
            />
          </form>
        </FormProvider>

        {!!hasError && <S.ErrorMessage>** {hasError}</S.ErrorMessage>}
      </S.Wrapper>
    </Modal>
  )
}
