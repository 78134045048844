import React, { useEffect, useState } from 'react'
import useFlow from 'store'

import { Container, Modal, FloatingButton, Alert } from 'components'
import * as S from './styles'
import { getOrderDetails } from 'services/api'
import { useHistory } from 'react-router-dom'

import { useIsMobileChecker } from 'hooks'

export default function SelfieSMS() {
  const history = useHistory()
  const isMobile = useIsMobileChecker()

  const {
    store: { order },
    setOrder,
  } = useFlow()

  const {
    processSelfie,
    customer: { firstName },
  } = order

  const [showButton, setShowButton] = useState(false)
  const [showOption, setShowOption] = useState(false)
  const [showError, setShowError] = useState('')

  useEffect(() => {
    if (processSelfie?.method === 'SMS') {
      const timer = setTimeout(() => setShowOption(true), 1000 * 60 * 5)
      return () => clearTimeout(timer)
    }
  }, [processSelfie?.method])

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const response = await getOrderDetails(order?.id)

        if (
          !['send_selfie', 'cnh_approved', 'cnh_sent'].includes(response.status)
        ) {
          clearInterval(interval)

          setOrder({
            ...response,
          })
          history.push(
            {
              selfie: '/assinar',
              selfie_rejected: '/selfie-denied',
            }[response.status] || '/checkin-self'
          )
        }
      } catch (error) {
        clearInterval(interval)
        setShowError(error.message)
      }
    }, 5000)
  }, [order.id, history, setOrder])

  const ShallowRender = ({ condition, children }) => {
    return condition && <S.Button>{children}</S.Button>
  }

  const handleOnError = () => {
    setShowError('')
    history.push('/checkin-self')
  }

  return (
    <Container
      padding={[
        [45, 30, 20],
        [40, 18, 20],
      ]}
      image="/images/welcome.png"
    >
      <S.Wrapper>
        <S.Content>
          <S.Wrapp>
            <S.RingLoad />

            <div>
              <h2>Olá, {firstName}.</h2>

              {processSelfie?.method === 'SMS' ? (
                <p>
                  Enviamos um SMS para seu número com o link para realizar a
                  captura da sua selfie.
                </p>
              ) : (
                <p>
                  Fique tranquilo! Estamos verificando se está tudo certo, por
                  favor aguarde.
                </p>
              )}
            </div>
          </S.Wrapp>
        </S.Content>

        {processSelfie?.method === 'SMS' && (
          <>
            <ShallowRender condition={isMobile && showOption}>
              <FloatingButton
                rounded
                gtmName="nao-recebi-sms"
                color="white"
                action={() => window.open(order?.processSelfie?.link, '_blank')}
                text="Não recebi SMS"
              />
            </ShallowRender>

            <ShallowRender condition={!isMobile && showOption}>
              <FloatingButton
                rounded
                gtmName="nao-recebi-sms"
                color="white"
                action={() => setShowButton(true)}
                text="Não recebi SMS"
              />
            </ShallowRender>
          </>
        )}
      </S.Wrapper>

      <Modal
        title={'Para continuar aponte a camera do seu celular para o QRcode!'}
        visible={showButton}
        setIsVisible={setShowButton}
      >
        <S.Modal>
          <img src={order?.processSelfie?.QRCode} alt="QRCODE" />
        </S.Modal>
      </Modal>

      <Alert
        isVisible={!!showError}
        actions={{
          primary: { onClick: handleOnError, label: 'Entendi' },
        }}
      >
        <p>{showError}</p>
      </Alert>
    </Container>
  )
}
