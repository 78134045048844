import React from 'react'

import { Container, Logo } from './styles'

export default function Header() {
  return (
    <Container>
      <Logo>
        <img
          src="/images/cf-assinatura-logo.svg"
          alt="logo carro facil assinatura"
        />
      </Logo>
    </Container>
  )
}
