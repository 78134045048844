import styled from 'styled-components'

export const Container = styled.div``

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.gray.medium};
`

export const Bold = styled.strong`
  font-family: ${({ theme }) => theme.fonts.black};
`

export const CreditCard = styled.button`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 14px;
  line-height: 18px;
  color: rgba(5, 5, 5, 0.5);

  border-radius: 30px;
  padding: 27px 20px;
  border: none;

  background: #e8e8e8;
  box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 20%);

  cursor: pointer;
`

export const CreditCardPorto = styled(CreditCard)`
  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const WrapAdice = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;

  ${Text} {
    display: block;
    text-align: center;
  }
`

export const WrapCreditCard = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 40px 0;

  ${CreditCard} {
    width: 50%;
  }

  img {
    width: 80%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 500px;
    margin: 40px auto;
  }
`

export const NextButton = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary};

  ${Text} {
    color: #fff;
  }
`
