import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useFlow from 'store'
import { FloatingButton } from 'components'

import { Bold, Container, Content, Text } from './styles'

export default function Completed() {
  const history = useHistory()
  const { store } = useFlow()
  const { firstName = 'Cliente' } = store?.order?.customer || {}

  useEffect(() => {
    const { dataLayer = [] } = window

    dataLayer.push({
      event: 'step_change',
      etapa: 'evy/fluxo-corretor',
    })
  }, [])

  return (
    <Container>
      <Content>
        <Text>
          Olá, <Bold>{firstName}</Bold>
        </Text>
        <Text>Um consultor vai entrar em contato com você.</Text>
        <Text>
          <Bold>Aguarde!</Bold>
        </Text>
      </Content>
      <FloatingButton
        text="Se você preferir, continue o processo sozinho."
        action={() => history.push('/step2')}
      />
    </Container>
  )
}

Completed.properties = {
  title: 'Fluxo Corretor',
}
