import React, { useEffect, useState } from 'react'
import useFlow from 'store'
import schema from './schema'

import LineInputUpload from 'components/LineInputUpload'

import * as S from './styles'
import { FormProvider, useForm } from 'react-hook-form'
import { Alert, FloatingButton, Text } from 'components'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { updateCustomer, analyzeCNH } from 'services/api'
import { encryptTextWithKey, formatMoney, updateCustomData } from 'utils'
import { useErrorHandler } from 'store/orderRequest/hook'

const UploadCNH = () => {
  const { dataLayer = [] } = window

  const {
    setLoading,
    store: { order, isLoading },
  } = useFlow()
  const { showModalHendler } = useErrorHandler()
  const history = useHistory()

  const [showError, setShowError] = useState('')

  const methods = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  })

  const onSubmit = async (data) => {
    try {
      setLoading({ visible: true, text: '' })
      const customer = await updateCustomer(order.customer.id, {
        order_id: order.id,
        cnh: {
          backCNH: data.cnhBack[0].base64,
          frontCNH: data.cnhFront[0].base64,
        },
      })

      const payload = {
        order_id: order.id,
        files: [customer?.cnh.frontCNH, customer?.cnh.backCNH],
      }

      await analyzeCNH(payload)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'envio-cnh',
        tipo_servico: 'cartao',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'sucesso',
        descricao: 'CNH enviada com sucesso',
      })

      history.push('/checkin-self')
    } catch (e) {
      setShowError(e.message)
      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'envio-cnh',
        tipo_servico: 'cartao',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'erro',
        descricao: e.message,
        erro: {
          codigo: e.code,
          servico: '/ca_riskanalysis/analyze_cnh',
          mensagem: e.message,
        },
      })

      if (e.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    } finally {
      setLoading({ visible: false, text: '' })
    }
  }

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'envio-cnh',
      },
      carro: {
        nome: order?.product?.title,
        categoria: order?.product?.vehicle?.category?.title,
        marca: order?.product?.vehicle?.model?.brand?.title,
        id: order?.product?.vehicle?.id,
        preco: formatMoney(
          order?.package?.months * order?.package?.cost?.rawValue
        ),
        variacao: `${order?.colorName} | ${order?.package?.mileage} km | ${order?.package?.months} meses`,
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkin/foto-cnh',
    })
  }, [dataLayer, order])

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={methods.handleSubmit(onSubmit)}>
        <S.Container>
          <S.Content>
            <Text title>Foto da CNH</Text>
            <Text>
              Para iniciar o cadastro, você deve enviar uma foto da CNH,
              escolhendo um arquivo da galeria do seu dispositivo. <br />{' '}
              IMPORTANTE: Não aceitamos arquivos em formato PDF.
            </Text>

            <Text>
              Atenção: a foto da CNH deve estar fora do plástico e com a
              validade em dia.
            </Text>

            <br />
            <br />

            <S.WrapCNH>
              <S.CHNItem>
                <Text>A parte da frente da CNH:</Text>

                <S.WrapImage>
                  <img src="/images/cnh_frente.png" alt="CNH aberta" />
                </S.WrapImage>

                <LineInputUpload
                  label="Parte da frente da CNH"
                  maxFiles={1}
                  gtmName="clique-aqui-para-adicionar-o-arquivo-ou-arraste-aqui"
                  acceptFiles="image"
                  name="cnhFront"
                />
              </S.CHNItem>

              <S.CHNItem>
                <Text>A parte de trás da CNH:</Text>

                <S.WrapImage>
                  <img
                    src="/images/cnh_verso.png"
                    alt="Parte inferior da CNH"
                  />
                </S.WrapImage>

                <LineInputUpload
                  label="Parte de trás da CNH"
                  maxFiles={1}
                  acceptFiles="image"
                  gtmName="clique-aqui-para-adicionar-o-arquivo-ou-arraste-aqui"
                  name="cnhBack"
                />
              </S.CHNItem>
            </S.WrapCNH>
          </S.Content>

          <FloatingButton
            gtmName="salvar"
            gtmClickType="submit"
            text="Salvar"
            height="45px"
            rounded
            isLoading={isLoading}
          />
        </S.Container>
      </S.Form>

      <Alert
        isVisible={!!showError}
        actions={{
          primary: { onClick: () => setShowError(''), label: 'Entendi' },
        }}
      >
        <p>{showError}</p>
      </Alert>
    </FormProvider>
  )
}

export default UploadCNH
