import * as CryptoJS from 'crypto-js'
import * as AesJs from 'aes-js'

import { Buffer } from 'buffer'

export const encryptByCrypto = (value = '', pass = '') => {
  return CryptoJS.AES.encrypt(value, pass).toString()
}

export const getEncryptedKeyBuffer = (key) => {
  const key256 = CryptoJS.SHA256(key)
  const buffer = Buffer.from(key256.toString(CryptoJS.enc.HEX), 'hex')
  return new Uint8Array(buffer).slice(0, 16)
}

export const encryptTextWithKey = (text, key) => {
  if (!text || !key) return
  const textAsBytes = AesJs.utils.utf8.toBytes(text)
  const aes = new AesJs.AES(getEncryptedKeyBuffer(key))
  const encryptedBytes = aes.encrypt(AesJs.padding.pkcs7.pad(textAsBytes))

  return AesJs.utils.hex.fromBytes(encryptedBytes)
}
