import React from 'react'
import { Helmet } from 'react-helmet'

import { Aside, Loading, Phase, Header } from 'components'

import { Main, Wrapper, Content, Overflow } from './styles'

export default function Default({ children }) {
  const { properties = {} } = children.type
  const { metatags = {}, pageStep, showStep } = properties
  const baseTitle = 'Carro por Assinatura Porto Bank'

  return (
    <>
      <Helmet>
        {metatags.title ? (
          <title>
            {metatags.title} - {baseTitle}
          </title>
        ) : (
          <title>{baseTitle}</title>
        )}

        <script
          src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script={process.env.REACT_APP_ONETRUST_KEY}
        />

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
        />
      </Helmet>

      <Main>
        <Header />
        <Wrapper>
          <Aside />

          <Overflow>
            <Content>
              {showStep && <Phase pageStep={pageStep} />}

              {children}
            </Content>

            <Loading />
          </Overflow>
        </Wrapper>
      </Main>
    </>
  )
}
