import api from 'services/api'
import {
  stockStatusPriority,
  getDeliveryDate,
  stockPriorityByStatus,
  STOCK_CODES,
} from 'utils'

/**
 * retorna os veículos por placa, priorizando a ordem
 * 1- ESTOQUE, 2- TRANSPORTE, 3 - ENCOMENDA
 *
 * @param {*} balancesbyaddress
 * @returns
 */
const getPlates = (balancesbyaddress) => {
  let plates = {}

  let minimumDelivery = {
    priority: 3,
    plateData: {},
  }

  for (let index = 0; index < balancesbyaddress?.length; index++) {
    const detail = balancesbyaddress[index]

    let final = 'NO_PLATE'
    if (detail.vehiclePlate) {
      final = detail.vehiclePlate.slice(-1)
    }

    let platePriority = 3

    if (plates[final]?.disponibility) {
      platePriority = stockStatusPriority[plates[final]?.disponibility]
    }

    //2
    const currentPriority = stockStatusPriority[detail.disponibility]

    if (minimumDelivery.priority > currentPriority) {
      minimumDelivery.priority = currentPriority
      minimumDelivery.plateData = detail
    }

    if (currentPriority < platePriority || !plates[final]) {
      plates[final] = detail
      plates[final].plateFinal = final
      plates[final].delivery = {
        priority: stockStatusPriority[plates[final].disponibility],
        text: getDeliveryDate(detail.address),
        code: plates[final].disponibility || 'ENCOMENDA',
      }
    }
  }

  return {
    plates,
    delivery: {
      code: stockPriorityByStatus[minimumDelivery.priority],
      detail: minimumDelivery.plateData,
    },
  }
}

const agroupPlates = (balancesbyaddress = []) => {
  const { plates, delivery: deliveryData } = getPlates(balancesbyaddress)

  const delivery = {
    text: STOCK_CODES.ENCOMENDA,
    code: 'ENCOMENDA',
    priority: 3,
  }

  let platesFormated = [
    {
      platesIncluded: [1, 2],
      delivery,
      details: {},
    },
    {
      platesIncluded: [3, 4],
      delivery,
      details: {},
    },
    {
      platesIncluded: [5, 6],
      delivery,
      details: {},
    },
    {
      platesIncluded: [7, 8],
      delivery,
      details: {},
    },
    {
      platesIncluded: [9, 0],
      delivery,
      details: {},
    },
  ]

  Object.keys(plates).forEach((element) => {
    const key = element === 'NO_PLATE' ? 'NO_PLATE' : parseInt(element)
    let plate = plates[key]

    if (key === 'NO_PLATE' && !!deliveryData?.detail?.chassiNumber) {
      plate = deliveryData.detail
    }

    platesFormated = platesFormated.map((item) => {
      let details = {}

      if (key === 'NO_PLATE' && !!deliveryData?.detail?.chassiNumber) {
        details = {
          details: deliveryData.detail,
          delivery: deliveryData.detail.delivery,
        }
      }

      if (
        item.platesIncluded.includes(key) &&
        item.delivery.priority > plate.delivery.priority
      ) {
        details = {
          details: plate,
          delivery: plate.delivery,
        }
      }

      return {
        ...item,
        ...details,
      }
    })
  })

  return {
    plates: platesFormated,
    delivery: deliveryData,
  }
}

export async function getProducts(params = {}) {
  const { states, brands, category, model, sort } = params

  try {
    const response = await api.get('/integracao/products', {
      params: {
        visible: true,
        'disponibility_zones.id': states || null,
        'vehicle.category.slug': category || null,
        'vehicle.model.brand.id': brands || null,
        'vehicle.model.code': model || null,
        _sort: sort || null,
        _limit: 0,
        _start: 0,
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getProductDetail(params) {
  const { state, version, model, brand } = params

  try {
    const response = await api.get('/integracao/stock', {
      params: {
        brandCode: brand || null,
        modelCode: model || null,
        versionCode: version || null,
        uf: state?.toUpperCase() || null,
      },
    })

    return response.data.psstockFixedAsset
      .map((item) => {
        const {
          trademarkCode,
          trademarkDescription,
          modelCode,
          modelDescription,
          versionCode,
          versionDescription,
          colorCode,
          colorDescription,
        } = item

        const { plates, delivery } = agroupPlates(item.balancesbyaddress)

        return {
          brand: {
            code: trademarkCode,
            title: trademarkDescription,
          },
          model: {
            code: modelCode,
            title: modelDescription,
          },
          version: {
            code: versionCode,
            title: versionDescription,
          },
          color: {
            code: colorCode,
            title: colorDescription,
          },
          inStockPlates: plates,
          deliveryDate: STOCK_CODES[delivery.code],
          deliveryCode: delivery.code,
          deliveryDetails: delivery.detail,
        }
      })
      .filter((item) => item?.color?.title)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getBrands(params = {}) {
  const { states } = params

  try {
    const response = await api.get('/integracao/vehicle-brands', {
      params: {
        disponibility_zones_in: states || null,
      },
    })

    return response.data.map((brand) => {
      const { id, code, title } = brand

      return {
        id,
        code,
        title,
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getModels(params = {}) {
  const { states, brands } = params

  try {
    const response = await api.get('/integracao/vehicle-models', {
      params: {
        disponibility_zones_in: states || null,
        'brand.id': brands || null,
      },
    })

    return response.data.map((model) => {
      const { id, code, title } = model

      return {
        id,
        code,
        title,
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getCategories() {
  try {
    const response = await api.get('/integracao/vehicle-categories')

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function setCouponCode(params) {
  try {
    const response = await api.get('/integracao/coupons', { params })

    return response.data[0]
  } catch (error) {
    const err = new Error(
      error?.data?.message ||
        'Cupom inválido! Verifique os caracteres e tente novamente!'
    )

    err.code = error.response.status

    throw err
  }
}

export async function getCoupon(coupon_id) {
  try {
    const response = await api.get(`/integracao/coupons/${coupon_id}`)

    return response.data
  } catch (error) {
    throw Error('Cupom não encontrado!')
  }
}
