import styled from 'styled-components'

export const Content = styled.div`
  position: relative;
`

export const Wrapper = styled.div`
  height: 21rem;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 32px;

  .sigCanvas {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    width: 100%;
    height: 100%;
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  position: absolute;
  right: 0;
  bottom: -20px;
`

export const Button = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 42px;
  height: 42px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`
