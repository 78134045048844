export const updateCustomData = (data) => {
  let { customData = {} } = window

  if (data.page) {
    const oldPage = customData.page

    const page = {
      title: 'Comprar ou Assinar um Carro',
      ...oldPage,
      ...data.page,
    }

    customData.page = page
  }

  if (data?.user) {
    const oldUser = customData.user

    const user = {
      ...oldUser,
      ...data.user,
    }

    customData.user = user
  }

  if (data.carro) {
    const oldCar = customData.carro

    const carro = {
      ...oldCar,
      ...data.carro,
    }
    customData.carro = carro
  }

  if (!customData.site) {
    customData.site = {
      portal: 'omni',
      brand: 'porto-seguro',
      product: 'carro-facil',
      version: 'v3',
    }
  }

  window.customData = customData

  return window.customData
}
