import { rem } from 'polished'
import styled, { css } from 'styled-components'

export const Content = styled.div``

export const Wrap = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.medium};
  padding: 10px;

  ${({ containError }) =>
    containError &&
    css`
      border-bottom-color: ${({ theme: { colors } }) => colors.danger};
    `}
`

export const Text = styled.p`
  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};

  margin-bottom: 0;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('16px')};
  }
`

export const Label = styled.p`
  font-size: ${rem('10px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};
  margin-bottom: 5px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('14px')};
  }
`

export const WrapImage = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`

export const Item = styled.div`
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    width: 200px;
  }
`

export const RemoveItem = styled.button`
  position: absolute;

  top: -10px;
  right: -10px;

  border: 1px solid;
  border-radius: 50%;

  background: white;

  cursor: pointer;

  height: 20px;
  width: 20px;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;

    width: 1px;
    height: 15px;

    top: 50%;
    left: 50%;

    background: black;

    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

export const Error = styled.p`
  font-size: ${rem('10px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.medium};
  text-align: right;
  margin-top: 5px;

  @media (min-width: ${({ theme: { breakpoints } }) => breakpoints.medium}) {
    font-size: ${rem('14px')};
  }
`
