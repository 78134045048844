import React, { useState } from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'

import { DefaultLayout } from 'layouts'
import { firebase } from 'services/firebase'

import routes from './index'
import useFlow from 'store'

export default function Router() {
  const [inMaintenance, setMaintenance] = useState(false)

  const {
    store: { order },
  } = useFlow()

  const checkMaintenance = () => {
    const ref = firebase
      .database()
      .ref('web/config/site/maintenance/inMaintenance')

    ref.on('value', (snap) => {
      const value = snap.val()
      if (inMaintenance !== value) {
        setMaintenance(value)
      }
    })
  }

  checkMaintenance()

  return (
    <BrowserRouter>
      <Switch>
        {routes.map(
          ({
            component: Component,
            layout: Layout = DefaultLayout,
            ...route
          }) => (
            <Route
              exact
              key={route.path}
              {...route}
              render={(props) => {
                if (
                  inMaintenance &&
                  route.path !== '/maintenance' &&
                  !route.outMaintenance
                ) {
                  return <Redirect from={route.path} exact to="/maintenance" />
                }

                if (!order?.id && route.redrect) {
                  return <Redirect to="/step0" />
                }

                return (
                  <Layout>
                    <Component {...props} />
                  </Layout>
                )
              }}
            />
          )
        )}
        {
          <Route
            path="*"
            render={() => {
              return <Redirect to="/" />
            }}
          />
        }
      </Switch>
    </BrowserRouter>
  )
}
