import { useEffect, useState } from 'react'

export default function useIsMobileChecker() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const handleWindowSize = () => {
    setScreenWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSize)

    return () => {
      window.removeEventListener('resize', handleWindowSize)
    }
  }, [])

  return screenWidth <= 768
}
