import React, { useState, useEffect } from 'react'

import { useFormContext, useController } from 'react-hook-form'

import Icon from '../Icon'

import get from 'lodash/get'

import 'cleave.js/dist/addons/cleave-phone.br'

import { Container, Field, Label, Value, Error, WrapperEyeIcon } from './styles'

export default function Input({
  label,
  name,
  placeholder,
  mask,
  numericOnly,
  creditCard,
  isPassword,
  phone,
  date,
  prefix,
  shortDate,
  rules,
  invertColor,
  toUpperCase,
  isLoading,
  maskedValue,
  numeral,
  disabled,
  focusedColor,
  defaultValue,
  onBlur: outerOnBlur,
  onChange: outerOnChange,
  ...props
}) {
  const { unregister, errors, control, getValues } = useFormContext()

  const [seePassword, setSeePassword] = useState(false)

  const [focus, setFocus] = useState(false)

  const {
    field: { onBlur, onChange, ...inputProps },
  } = useController({ name, control, defaultValue })

  const setDelimiter = () => {
    if (mask) {
      return mask.match(/[^#]/g)
    }

    return creditCard ? [' '] : ['/']
  }

  const handleBlur = (e) => {
    const value = maskedValue ? e.target.value : e.target.rawValue

    onBlur(value)

    outerOnBlur && outerOnBlur(e)

    !e.target.rawValue && setFocus(false)

    return value
  }

  const handleChange = (e) => {
    const value = maskedValue ? e.target.value : e.target.rawValue

    onChange(value)

    outerOnChange && outerOnChange(e)

    return value
  }

  useEffect(() => {
    getValues()[name] ? setFocus(true) : setFocus(false)
  }, [setFocus, getValues, name])

  useEffect(() => {
    return () => unregister(name)
  }, [unregister, name])

  const blocks = mask ? mask.match(/#+/g).map((each) => each.length) : [99999]

  const delimiters = setDelimiter()

  const datePattern = shortDate ? ['m', 'y'] : ['d', 'm', 'Y']

  const options = {
    blocks,
    delimiters,
    numericOnly,
    creditCard,
    creditCardStrictMode: true,
    phone,
    date,
    prefix,
    datePattern,
    phoneRegionCode: 'BR',
    numeral,
  }

  const hasErrors = get(errors, name)

  function inputType() {
    if (!isPassword) {
      return inputProps?.type
    }

    if (!seePassword) {
      return 'password'
    }

    return 'text'
  }

  inputProps.type = inputType()

  return (
    <Container {...props}>
      <Field
        focus={focus}
        focusedColor={focusedColor}
        disabled={disabled}
        isLoading={isLoading}
        className={hasErrors ? 'withError' : ''}
        error={hasErrors}
      >
        <Label>{label}</Label>

        <Value
          {...props}
          options={options}
          onFocus={() => setFocus(true)}
          autoComplete="off"
          placeholder={placeholder}
          data-gtm-name={`${label.toLowerCase() || name.toLowerCase()}:omni`}
          data-gtm-form="input"
          rules={rules}
          control={control}
          name={name}
          onBlur={(e) => handleBlur(e)}
          onChange={(e) => handleChange(e)}
          disabled={disabled}
          {...inputProps}
        />

        {isPassword && (
          <WrapperEyeIcon>
            {seePassword ? (
              <>
                <Icon
                  name="eye"
                  size="14px"
                  onClick={() => setSeePassword(!seePassword)}
                />
              </>
            ) : (
              <>
                <Icon
                  name="eye-off"
                  size="14px"
                  onClick={() => setSeePassword(!seePassword)}
                />
              </>
            )}
          </WrapperEyeIcon>
        )}

        <Error>{hasErrors?.message}</Error>
      </Field>
    </Container>
  )
}
