import useFlow from 'store'

import { ChatBox, Select, FloatingButton } from 'components'

import { daysOption, PAYMENT } from 'helpers'

import * as S from './styles'
import { useEffect, useState } from 'react'
import { getPortoOffers, sendPayment } from 'services/api'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import validationSchema from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { encryptTextWithKey, formatMoney, updateCustomData } from 'utils'
import { useErrorHandler } from 'store/orderRequest/hook'

const DISCOUNT_VALUE = 150

export default function OfferPortoCard() {
  const {
    store: { order, isMocked },
    setLoading,
    setOrder,
  } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const history = useHistory()
  const { dataLayer = [] } = window

  const methods = useForm({ resolver: yupResolver(validationSchema) })

  const [cardFlags, setCardFlags] = useState([])
  const [fomLoading, setFormLoading] = useState(false)

  const priceWithDiscount = order?.summary?.baseCost.rawValue - DISCOUNT_VALUE

  const handlePriceWithDiscount = (price) => {
    if (!price) {
      return
    }

    return price?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      setFormLoading(true)

      const payment = await sendPayment(
        {
          orderId: order?.id,
          value: priceWithDiscount,
          logoCode: values?.logoCode,
          expiration: values?.expiration,
          type: PAYMENT.OFFER,
        },
        isMocked
      )

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'pagamento-cartao-porto',
        tipo_servico: 'cartao',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'sucesso',
        descricao: 'Pagamento realizado com sucesso',
      })

      setOrder({
        ...order,
        payment,
      })
      history.push('/completed')
      return
    } catch (error) {
      console.error(error)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'dados-cartao',
        tipo_servico: 'cartao',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'erro',
        descricao: error.message,
        erro: {
          codigo: error.code,
          servico: '/payments',
          mensagem: error.message,
        },
      })

      if (error.code === 409) {
        showModalHendler({ showModal: true })
      }
    } finally {
      setFormLoading(false)
    }
  })

  useEffect(() => {
    ;(async () => {
      setLoading({ visible: true, text: 'Buscando as melhores ofertas' })
      try {
        const { logos } = await getPortoOffers(order?.id, isMocked)

        // const logos = [
        //   {
        //     logo: 'MASTERCARD',
        //     logo_code: 2,
        //     logo_type: 'GOLD',
        //     logo_limit: 4000,
        //   },
        // ]

        const flags = logos?.map((card) => {
          return { label: card?.logo, value: card?.logo_code }
        })

        setCardFlags(flags)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading({ visible: false, text: '' })
      }
    })()
  }, [setLoading, order?.id, isMocked])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'oferta-cartao',
      },
      carro: {
        categoria: order?.product.vehicle.category.slug,
        marca: order?.product.vehicle.model.brand.title.toLowerCase(),
        id: order?.product.id,
        nome: order?.product.vehicle.model.specification,
        variacao: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
        preco: formatMoney(
          order?.summary.baseCost.rawValue * order?.summary.months
        ),
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/checkout/pagamento/cartao-porto',
    })
  }, [dataLayer, order])

  return (
    <S.Container>
      <ChatBox
        text={`${order?.customer?.firstName}, contrate o Cartão de Crédito Porto e ganhe R$ 150,00 de desconto em uma das 3 primeiras parcelas do seu Carro por Assinatura Porto Bank!`}
      />

      <S.Box>
        <S.OldPrice>
          <span>De</span>
          <p>{handlePriceWithDiscount(order?.summary?.baseCost.rawValue)}</p>
        </S.OldPrice>

        <S.NewPrice>
          <span>Por</span>
          <p>{handlePriceWithDiscount(priceWithDiscount)}</p>
        </S.NewPrice>
      </S.Box>

      <FormProvider {...methods}>
        <S.Content onSubmit={onSubmit}>
          <S.Wrapper>
            <p>Escolha a melhor data da fatura e bandeira:</p>

            <S.Flex>
              <Select
                placeholder="Vencimento"
                name="expiration"
                options={daysOption()}
                gtmName="vencimento"
              />

              <Select
                placeholder="Bandeira"
                name="logoCode"
                options={cardFlags}
                gtmName="bandeira"
              />
            </S.Flex>
          </S.Wrapper>
          <S.Buttons>
            <FloatingButton
              rounded
              color="white"
              text="Usar outro cartão"
              height="57px"
              action={() => history.push('/selecionar-cartao-credito')}
            />
            <FloatingButton
              rounded
              text="Pagar com cartão Porto Bank"
              height="57px"
              action={onSubmit}
              gtmClickType="submit"
              isLoading={fomLoading}
              disabled={fomLoading}
            />
          </S.Buttons>
        </S.Content>
      </FormProvider>
    </S.Container>
  )
}

OfferPortoCard.properties = {
  pageStep: 'Oferta de cartão',
  showStep: false,
}
