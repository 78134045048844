import React from 'react'

import { v1 } from 'uuid'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import { Container, Input, Label, Error } from './styles'

export default function Switch({ label, name, gtmName, ...props }) {
  const { register, errors = {} } = useFormContext() || {}

  const id = v1()

  return (
    <Container>
      <Input
        {...props}
        data-gtm-type="click"
        data-gtm-clicktype="switch"
        data-gtm-name={gtmName}
        name={name}
        id={id}
        ref={register}
        type="checkbox"
        hasError={errors[name]}
      />
      <Label htmlFor={id}>{label}</Label>
      <ErrorMessage errors={errors} name={name} as={<Error />} />
    </Container>
  )
}
