import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import {
  Container,
  ChatBox,
  LineInput,
  FloatingButton,
  Alert,
} from 'components'
import { getUserAnalysis, updateCustomer } from 'services/api'
import { formatDate } from 'utils'
import useFlow from 'store'

import * as S from './styles'
import validationSchema from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { useErrorHandler } from 'store/orderRequest/hook'

const filedsProps = {
  birthdate: {
    placeholder: 'dd/mm/aaaa',
    label: 'Data de nascimento*',
    mask: '',
    maskedValue: true,
    date: true,
  },
  cellphone: {
    placeholder: '00 00000-0000',
    label: 'Nº do seu celular*',
    mask: '',
    phone: true,
  },
  fullName: {
    placeholder: 'Digite aqui seu nome completo*',
    label: 'Nome completo*',
    mask: '',
  },
  motherName: {
    label: 'Nome completo da mãe*',
    placeholder: 'Digite aqui o nome da sua mãe',
    mask: '',
  },
}

export default function SerasaPosBureau() {
  const forms = useForm({ resolver: yupResolver(validationSchema) })
  const { showModalHendler } = useErrorHandler()

  const [error, setError] = useState('')

  const {
    store: { order, posBureau, isLoading },
    setLoading,
    setOrder,
  } = useFlow()
  const history = useHistory()

  const onSubmit = forms.handleSubmit(async (values) => {
    try {
      setLoading({ visible: true })
      const bureau = {
        ...values,
        cellphone: values.cellphone.replace(/\s/g, ''),
        birthdate: values?.birthdate ? formatDate(values?.birthdate) : null,
      }

      const customer = await updateCustomer(order?.customer?.id, {
        ...bureau,
      })

      setOrder({
        ...order,
        customer,
      })

      const response = await getUserAnalysis(order.id)

      history.push(
        {
          derivation: '/em-analise',
          denied: '/denied',
          approved: '/step5',
        }[response?.order?.status]
      )
    } catch (error) {
      setError(error.message)
      console.log('error', error)

      if (error.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    } finally {
      setLoading({ visible: false })
    }
  })

  const renderInputs = () =>
    posBureau.length &&
    posBureau.map((fields, id) => {
      return (
        <LineInput
          key={id}
          name={fields}
          placeholder={filedsProps[fields]?.placeholder}
          label={filedsProps[fields]?.label}
          mask={filedsProps[fields]?.mask}
          focusedColor="#0027F4"
          defaultValue=""
          maskedValue={filedsProps[fields]?.maskedValue}
          date={filedsProps[fields]?.date}
          phone={filedsProps[fields]?.phone}
          numericOnly={filedsProps[fields]?.numericOnly}
          isLoading={isLoading}
        />
      )
    })

  const inputValuesOnField = useCallback(() => {
    const { customer } = order

    posBureau?.forEach((value) => {
      customer.birthdate = customer.birthdate.split('-').reverse().join()
      forms.setValue(value, customer[value])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    inputValuesOnField()
  }, [inputValuesOnField])

  return (
    <Container
      padding={[
        [45, 30, 20],
        [40, 18, 20],
      ]}
    >
      <ChatBox text={`Todos os dados apresentados a seguir estão corretos?`} />

      <FormProvider {...forms}>
        <S.Form onSubmit={onSubmit}>
          <S.FormGrid>{renderInputs()}</S.FormGrid>
        </S.Form>
      </FormProvider>
      <FloatingButton
        rounded
        text="Avançar"
        height="57px"
        action={onSubmit}
        isLoading={isLoading}
      />

      <Alert
        isVisible={!!error}
        actions={{
          primary: { onClick: () => setError(''), label: 'Entendi' },
        }}
      >
        <p>{error}</p>
      </Alert>
    </Container>
  )
}
