export const checkUserWebcam = async () => {
  const midia = navigator.mediaDevices

  if (!midia || !midia.enumerateDevices) {
    return false
  }

  const devices = await midia.enumerateDevices()

  return devices.some((device) => 'videoinput' === device.kind)
}

export const getUserCameraPermission = async () => {
  const midia = navigator.mediaDevices

  try {
    const stream = await midia.getUserMedia({ video: true })

    return stream.active
  } catch (err) {
    return false
  }
}

export const detectUserBrowser = () => {
  switch (true) {
    case checkString(/edg/i):
      return 'Edge'
    case checkString(/firefox|fxios/i):
      return 'Mozilla Firefox'
    case checkString(/opr\//i):
      return 'Opera'
    case checkString(/samsungbrowser/i):
      return 'Samsung Browser'
    case checkString(/chrome|chromium|crios/i):
      return 'Chrome'
    case checkString(/safari/i):
      return 'Safari'
    default:
      return 'Other'
  }
}

export const userCanUseSDK = () => {
  const platform = window.navigator?.userAgentData?.platform

  if (/Linux/.test(platform)) {
    return false
  }

  return true
}

const checkString = function (regexp) {
  return regexp.test(navigator.userAgent)
}
