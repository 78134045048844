import styled from 'styled-components'
import { flexbox, grid, layout, space } from 'styled-system'

/**
 * Grid usage example: https://styled-system.com/api#grid-layout
 * Layout usage example: https://styled-system.com/api#layout
 * Space usage example: https://styled-system.com/getting-started#margin--padding
 */
export const Grid = styled.div`
  ${grid}
  ${layout}
  ${space}
  ${flexbox}

  display: grid;
`
