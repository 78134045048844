import React, { useCallback, useState, useEffect } from 'react'
import {
  FloatingButton,
  LineInput,
  LineInputMoney,
  Container,
  Text,
} from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { updateCustomer } from 'services/api'

import useFlow from 'store'
import {
  formatMoneyDecimalFromBRL,
  MARITAL_STATUSES,
  OCCUPATIONS,
} from 'helpers'
import { Card, Form, WrapForm } from './styles'
import LineSelect from 'components/LineSelect'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from './schema'
import { encryptTextWithKey, updateCustomData } from 'utils'

export default function Step3() {
  const history = useHistory()
  const { setStep3Info, store, setOrder } = useFlow()

  const [loading, setLoading] = useState(false)
  const { dataLayer = [] } = window

  const forms = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data) => {
    setLoading(true)
    let formData = data

    formData.gender = formData.gender.value
    formData.marital = formData.marital.value
    formData.occupation = formData.occupation.value
    formData.income = formatMoneyDecimalFromBRL(formData.income)

    try {
      const response = await updateCustomer(store.order.customer.id, formData)
      setStep3Info(formData)

      setOrder({
        ...store.order,
        customer: response,
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'cadastrar',
        tipo_servico: 'dados-pessoais',
        client_id: encryptTextWithKey(store.order.customer.cpf.number, 'chave'),
        origem: store.order.origin,
        susep: store.order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'sucesso',
        descricao: 'Lead enviado com sucesso',
      })

      history.push('/step4')
    } catch (error) {
      console.error(error)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'cadastrar',
        tipo_servico: 'dados-pessoais',
        client_id: encryptTextWithKey(store.order.customer.cpf.number, 'chave'),
        origem: store.order.origin,
        susep: store.order.representative.broker?.susep || '',
        product: 'carro-facil',
        retorno: 'error',
        descricao: error.message,
      })
    } finally {
      setLoading(false)
    }
  }

  const formatSelectOptions = useCallback((value) => {
    const keys = Object.keys(value)
    const rows = []

    for (const key in keys) {
      if (Object.hasOwnProperty.call(keys, key)) {
        const index = keys[key]
        const element = value[index]

        rows.push({
          label: element,
          value: index,
        })
      }
    }

    return rows
  }, [])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'step3',
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/evy/sobre-voce',
    })
  }, [dataLayer])

  return (
    <Container>
      <FormProvider {...forms}>
        <Form onSubmit={forms.handleSubmit(onSubmit)}>
          <div>
            <Card>
              <Text>Informações pessoais</Text>
              <WrapForm>
                <LineSelect
                  label="Estado civil*"
                  placeholder="Selecione"
                  options={formatSelectOptions(MARITAL_STATUSES)}
                  name="marital"
                />
              </WrapForm>

              <WrapForm>
                <LineSelect
                  label="Gênero*"
                  placeholder="Selecione"
                  options={[
                    { value: 'M', label: 'Masculino' },
                    {
                      value: 'F',
                      label: 'Feminino',
                    },
                  ]}
                  name="gender"
                />
              </WrapForm>
              <WrapForm>
                <LineInput
                  label="Nome completo da mãe*"
                  placeholder="Digite aqui"
                  name="motherName"
                />
              </WrapForm>
            </Card>
            <Card>
              <Text>Informações profissionais</Text>

              <WrapForm>
                <LineInput
                  label="Empresa em que trabalha*"
                  placeholder="Digite aqui"
                  name="company"
                />
              </WrapForm>
              <WrapForm>
                <LineInputMoney
                  name="income"
                  placeholder="0,00"
                  label="Renda mensal*"
                />
              </WrapForm>
              <WrapForm>
                <LineSelect
                  label="Ocupação profissional*"
                  placeholder="Selecione"
                  options={formatSelectOptions(OCCUPATIONS)}
                  name="occupation"
                />
              </WrapForm>
            </Card>
          </div>

          <FloatingButton
            rounded
            gtmName="anvançar"
            gtmClickType="submit"
            isLoading={loading}
            disabled={loading}
            text="Avançar"
            height="57px"
          />
        </Form>
      </FormProvider>
    </Container>
  )
}

Step3.properties = {
  pageStep: 'Sobre você',
  showStep: true,
}
