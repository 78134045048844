import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { Select as BaseSelect } from 'components'
import { breakpoints } from 'styles'

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`
export const Price = styled.img`
  cursor: pointer;
  justify-self: center;

  transition: transform 0.1s;
  will-change: transition;

  ${({ inverted }) =>
    inverted &&
    css`
      transform: rotate(180deg);
    `}
`

export const Select = styled(BaseSelect)`
  height: 56px;
  width: ${rem('220px')};

  @media (min-width: ${breakpoints.small}) {
    width: ${rem('310px')};
  }
`

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 10px 30px;

  @media (min-width: ${breakpoints.small}) {
    justify-content: flex-end;
    gap: 2rem;
  }
`

export const Wrapper = styled.div`
  background-color: #fff;
  height: 100%;

  @media (min-width: ${breakpoints.small}) {
    padding: 0 30px;
    /* overflow-x: auto; */
  }
`

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  gap: 1rem;
`

export const Text = styled.h2`
  width: 100%;
  font-size: ${rem('20px')};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-align: center;

  margin: 30px 0;

  @media (min-width: ${breakpoints.medium}) {
    text-align: left;
    margin: 0 30px;
  }
`

export const WrapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  margin-top: 20px;

  @media (min-width: ${breakpoints.medium}) {
    flex-direction: row;
  }
`
