import * as yup from 'yup'

export default yup.object().shape({
  password: yup
    .string()
    .min(6, 'Senha precisa ter mais que 6 caracteres.')
    .required('A senha é obrigatória'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Senha precisa ser igual a anterior.')
    .required('A confirmação de senha é obrigatória'),
})
