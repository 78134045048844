import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ChatBox, Container, FloatingButton, LineInput, Text } from 'components'
import Modal from 'components/Modal'

import * as S from './styles'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from './schema'
import { sendPayment } from 'services/api/payments'
import useFlow from 'store'
import { useHistory } from 'react-router-dom'
import { PAYMENT } from 'helpers'
import { getCoupon } from 'services/api'
import {
  calculateDiscount,
  encryptTextWithKey,
  formatMoney,
  updateCustomData,
} from 'utils'
import { useErrorHandler } from 'store/orderRequest/hook'

const keysToIgnore = ['Backspace', 'Delete']
const OtherCreditCard = () => {
  const { dataLayer = [] } = window
  const history = useHistory()
  const { store, setLoading, setOrder } = useFlow()
  const { showModalHendler } = useErrorHandler()

  const { portoCard, isElegiblePortoCard, order } = store
  const { package: orderPackege } = order

  const [modalTransation, setModalTransation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [price, setPrice] = useState(order?.summary?.baseCost?.formattedValue)

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      cardCVV: '',
      cardName: '',
      cardNumber: '',
      cardVality: '',
    },
  })

  const onSubmit = async (data) => {
    setIsLoading(true)
    setLoading({ visible: true, text: 'Enviando seus dados' })
    try {
      const payment = await sendPayment({
        ...data,
        orderId: order.id,
        type: PAYMENT.OTHER,
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'cadastrar',
        tipo_servico: 'bandeira-cartao',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'sucesso',
        descricao: 'Pagamento realizado com sucesso',
      })

      setOrder({
        ...store.order,
        payment,
      })

      history.push('/completed')
    } catch (error) {
      setModalTransation(true)

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'cadastrar',
        tipo_servico: 'bandeira-cartao',
        client_id: encryptTextWithKey(order.customer.cpf.number, 'chave'),
        retorno: 'erro',
        descricao: error.message,
        erro: {
          codigo: error.code,
          servico: '/payments',
          mensagem: error.message,
        },
      })

      if (error.code === 409) {
        showModalHendler({ showModal: true })
        return
      }
    } finally {
      setIsLoading(false)
      setLoading({ visible: false, text: 'Enviando seus dados' })
    }
  }

  useEffect(() => {
    if (order.coupon) {
      ;(async () => {
        try {
          setLoading({ visible: true, text: 'Verificando cupom' })
          const { value, type } = await getCoupon(order.coupon)
          const orderPrice = orderPackege?.cost.rawValue * orderPackege?.months

          const finalPrice = calculateDiscount(orderPrice, value, type)

          setPrice(formatMoney(finalPrice / orderPackege?.months))
        } catch (error) {
          console.warning(error)
        } finally {
          setLoading({ visible: false, text: '' })
        }
      })()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.coupon])

  useEffect(() => {
    if (order) {
      updateCustomData({
        page: {
          step: 'pagamento-dados-cartao',
        },
        carro: {
          categoria: order?.product?.vehicle?.category?.slug,
          marca: order?.product.vehicle.model.brand.title.toLowerCase(),
          id: order?.product.id,
          nome: order?.product.vehicle.model.specification,
          variacao: `${order?.product.vehicle.specification} | ${order?.summary.months} meses | ${order?.summary.mileage} km`,
          preco: formatMoney(
            order?.summary.baseCost.rawValue * order?.summary.months
          ),
        },
      })
      dataLayer.push({
        event: 'step_change',
        etapa: '/checkout/pagamento/cartao',
      })
    }
  }, [dataLayer, order])

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Container>
          <div>
            <ChatBox
              text={`Agora estamos na etapa de pagamento! Por favor, insira os dados do cartão de crédito`}
            />

            <S.Card>
              <S.Text>Valor da parcela</S.Text>
              <S.TextHeader>{price || ''}</S.TextHeader>
              <S.Line />

              <S.Field>
                <LineInput
                  label="Digite o número do cartão*"
                  name="cardNumber"
                  placeholder="0000 0000 0000 0000"
                  creditCard
                />
              </S.Field>

              <S.Field>
                <LineInput
                  label="Nome idêntico ao descrito no cartão*"
                  name="cardName"
                  placeholder="Nome no cartão"
                />
              </S.Field>

              <S.WrapInlineFields>
                <LineInput
                  label="Validade do cartão*"
                  name="cardVality"
                  date
                  shortDate
                  placeholder="00/00"
                  maskedValue
                />

                <LineInput
                  label="Código CVV*"
                  name="cardCVV"
                  placeholder="000"
                  mask="####"
                  onKeyDown={(e) => {
                    if (/\D/.test(e.key) && !keysToIgnore.includes(e.key)) {
                      e.preventDefault()
                    }
                  }}
                />
              </S.WrapInlineFields>
            </S.Card>
          </div>

          <S.WrapButton>
            {portoCard?.length || isElegiblePortoCard ? (
              <FloatingButton
                rounded
                color={'white'}
                text="Voltar"
                height="57px"
                action={() => {
                  let url = '/selecao-cartao'
                  if (portoCard?.length) {
                    url = '/porto-payment'
                  } else if (isElegiblePortoCard) {
                    url = '/cartao-porto'
                  }

                  history.push(url)
                }}
                gtmName="voltar"
                disabled={isLoading}
                isLoading={isLoading}
              />
            ) : (
              <></>
            )}

            <FloatingButton
              isLoading={isLoading}
              text="Prosseguir"
              gtmName="prosseguir"
              gtmClickType="submit"
              rounded
            />
          </S.WrapButton>
        </Container>
      </S.Form>

      <Modal
        title="Transação não aprovada"
        visible={modalTransation}
        setIsVisible={setModalTransation}
      >
        <>
          <Text>
            Transação não foi aprovada, tente novamente com outro cartão.
          </Text>
        </>
      </Modal>
    </FormProvider>
  )
}

export default OtherCreditCard
