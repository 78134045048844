import React, { useEffect, useState, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import {
  FloatingButton,
  LineInput,
  Container,
  ChatBox,
  Switch,
} from 'components'
import useFlow from 'store'
import { checkZipCode } from 'services/api'

import { Text as TextComp } from 'components'

import { Content, Form, FormGrid, Option } from './styles'
import validationSchema from './validation'
import { STATUS } from 'helpers'
import { formatDate, updateCustomData } from 'utils'
import Modal from 'components/Modal'

import { ReturnOrderModal } from 'components/ReturnOrder'

export default function Step1() {
  const {
    store: { step1, order },
    setStep1Info,
  } = useFlow()

  const forms = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  })
  const { setValue } = forms

  const history = useHistory()
  const [loaders, setLoaders] = useState({
    doc: false,
    page: false,
    cep: false,
  })
  const [modalRegion, setModalRegion] = useState(false)
  const [showValidateModal, setShowValidateModal] = useState(false)

  const { dataLayer = [] } = window

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    return await executeRecaptcha('CREATE_LEAD')
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  const verifyCEP = async (cep) => {
    if (cep?.length < 8) {
      return
    }

    try {
      setLoaders((state) => ({ ...state, cep: true }))

      const response = await checkZipCode(cep)

      if (!response?.message) {
        throw new Error('Regiao não atendida')
      }

      return {
        zip: cep,
        ...response,
      }
    } catch (error) {
      setModalRegion(true)
      forms.setError(
        'cep',
        {
          type: 'focus',
          message: 'Não atendemos nessa região ainda.',
        },
        {
          shouldFocus: true,
        }
      )

      return {
        zip: cep,
      }
    } finally {
      setLoaders((state) => ({ ...state, cep: false }))
    }
  }

  const onSubmit = forms.handleSubmit(async (data) => {
    const { terms, ...values } = data
    const cepInfo = await verifyCEP(values.cep)

    if (!cepInfo.dataCep) {
      return
    }

    setLoaders((state) => ({ ...state, page: true }))

    try {
      await handleReCaptchaVerify()
      if (window?.parent) {
        window.parent.postMessage('leadSubmitted', '*')
      }

      const defaultAddress = {
        cep: cepInfo.zip,
        city: cepInfo?.dataCep?.cidade,
        neighborhood: cepInfo?.dataCep?.bairro,
        state: cepInfo?.dataCep?.uf,
        street: cepInfo?.dataCep?.end,
      }

      const user = {
        ...values,
        birthdate: formatDate(values?.birthdate),
        cellphone: values.cellphone.replace(/\s/g, ''),
        customer: {
          id: step1?.customer?.id || null,
        },
      }

      setStep1Info({ ...user, defaultAddress })
      history.push('/consent')
    } catch (error) {
      forms.setError(
        'cep',
        {
          type: 'focus',
          message: 'Não atendemos nessa região ainda.',
        },
        {
          shouldFocus: true,
        }
      )
    } finally {
      setLoaders((state) => ({ ...state, page: false }))
    }
  })

  useEffect(() => {
    const { cellphone, cep, document, email, name } = step1
    const birth = step1?.birthdate?.split('-') || []

    let birthdate = null

    if (birth.length > 0) {
      birthdate = `${birth[2]}/${birth[1]}/${birth[0]}`
    }

    const fields = [
      { name: 'cep', value: cep },
      { name: 'cellphone', value: cellphone },
      { name: 'document', value: document },
      { name: 'email', value: email },
      { name: 'name', value: name },
      { name: 'birthdade', value: birthdate },
    ]

    fields.map(({ name, value }) => setValue(name, value))
  }, [setValue, step1])

  useEffect(() => {
    const { document } = step1
    if (!document) {
      history.push('/step0')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step1])

  useEffect(() => {
    if (order.status === STATUS.PREAPP) {
      setStep1Info({
        cellphone: order?.customer?.cellphone,
        cep: order?.customer?.defaultAddress?.cep,
        document: order?.customer?.cpf?.number,
        email: order?.customer?.email,
        name: order?.customer?.fullName,
      })
    }
  }, [order, setStep1Info])

  useEffect(() => {
    updateCustomData({
      page: {
        step: 'step1',
      },
    })
    dataLayer.push({
      event: 'step_change',
      etapa: '/evy/quero-assinar-o-carro-facil',
    })
  }, [dataLayer])

  return (
    <Container>
      <Content>
        <ChatBox
          text={`Olá! Eu sou a <strong>EVY</strong> a <strong>inteligência</strong> que vai
            te ajudar na jornada até seu Carro por Assinatura Porto Bank.
            <br />
            <br />
            Antes de iniciar sua jornada como cliente, conte mais sobre você`}
        />
        <FormProvider {...forms}>
          <Form onSubmit={onSubmit}>
            <TextComp>Dados pessoais</TextComp>
            <FormGrid>
              <LineInput
                disabled={loaders.doc || loaders.page}
                name="name"
                placeholder="Digite aqui seu nome completo"
                label="Nome completo*"
                focusedColor="#0027F4"
                defaultValue=""
              />
              <LineInput
                disabled
                name="document"
                placeholder="000.000.000-00"
                label="CPF*"
                mask="###.###.###-##"
                focusedColor="#0027F4"
                isLoading={loaders.doc}
                numericOnly
                defaultValue=""
              />
              <LineInput
                disabled={loaders.doc || loaders.page}
                name="email"
                placeholder="seuemail@provedor.com"
                label="E-mail*"
                defaultValue=""
                focusedColor="#0027F4"
              />
              <LineInput
                name="cep"
                placeholder="00000-000"
                label="CEP*"
                mask="#####-###"
                isLoading={loaders.cep}
                focusedColor="#0027F4"
                numericOnly
                disabled={loaders.doc || loaders.page || loaders.cep}
              />
              <LineInput
                disabled={loaders.doc || loaders.page}
                name="cellphone"
                placeholder="00 00000-0000"
                label="Nº do seu celular*"
                focusedColor="#0027F4"
                defaultValue=""
                phone
              />
              <LineInput
                disabled={loaders.doc || loaders.page}
                name="birthdate"
                placeholder="dd/mm/aaaa"
                label="Data de nascimento*"
                focusedColor="#0027F4"
                maskedValue
                defaultValue=""
                date
              />
            </FormGrid>
          </Form>
          <Option>
            <Switch name="terms" />

            <p>
              Estou ciente que o Carro por Assinatura Porto Bank
              <br /> não é permitido para o uso em aplicativos de <br /> carona
              (Uber, 99, Cabify e outros).
            </p>
          </Option>
        </FormProvider>
      </Content>
      <FloatingButton
        rounded
        gtmName="avancar"
        isLoading={loaders.page || loaders.cep}
        disabled={loaders.doc || loaders.page}
        text="Avançar"
        action={onSubmit}
        gtmClickType="submit"
        height="57px"
      />

      <Modal
        title="Região ainda não abrangida"
        visible={modalRegion}
        setIsVisible={setModalRegion}
      >
        <TextComp textType="tinyText">
          Olá, não atendemos esta região ainda.
          <br />
          <br />
          Acompanhe pelos nossos canais digitais quando sua região será
          atendida.
        </TextComp>
      </Modal>

      <ReturnOrderModal
        setShowModal={setShowValidateModal}
        showModal={showValidateModal}
      />
    </Container>
  )
}

Step1.properties = {
  pageStep: 'Dados Pessoais',
  showStep: true,
}
