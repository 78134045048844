import styled, { css } from 'styled-components'
import { rem } from 'polished'
import styledMap from 'styled-map'

import colors from 'styles/colors'
import { breakpoints } from 'styles'

const flagTheme = styledMap('color', {
  ...colors,
  default: colors.gray.medium,
})

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 0;
  top: 0;
  z-index: 3;
  overflow: hidden;
`

export const Overlay = styled.div`
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  z-index: 2;
  transition: opacity 0.5s ease;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      background-color: rgba(0, 0, 0, 0.75);
    `}
`

export const Dialog = styled.div`
  padding: 3rem 1.2rem;
  padding-bottom: 1.2rem;
  z-index: 3;
  position: fixed;
  max-height: 80%;
  width: 90%;
  border-radius: 8px;
  top: -10vh;
  right: 50%;
  opacity: 0;
  transform: translate(50%, -50%);
  background-color: #fff;

  transition: top, opacity;
  transition-duration: 0.3s, 0.5s;
  transition-timing-function: ease;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ isOpen }) =>
    isOpen &&
    css`
      top: 50%;
      opacity: 1;
    `}

  @media (min-width: ${breakpoints.small}) {
    width: 30%;
  }
`

export const Flag = styled.div`
  position: absolute;
  top: -10px;
  left: 50%;

  padding: 8px 20px;

  color: #fff;
  border-radius: 30px;
  font-size: ${rem('12px')};
  background-color: ${flagTheme};
  font-family: ${({ theme }) => theme.fonts.bold};
  transform: translateX(-50%);
`

export const Content = styled.div`
  margin-bottom: 20px;
`

export const Actions = styled.div`
  > button {
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
`
