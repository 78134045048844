import api from 'services/api'

export async function analyzeCNH(payload) {
  try {
    const response = await api.post(`/ca_riskanalysis/analyze_cnh`, payload)

    return response.data
  } catch (error) {
    const err = new Error(
      'Ocorreu um problema ao enviar a CNH. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}

export async function sendAnalyzeSelf(payload) {
  try {
    const response = await api.post(`/ca_riskanalysis/face_match`, payload)

    return response.data
  } catch (error) {
    const { data } = error.response

    const err = new Error(
      data?.message
        ? data?.message
        : 'Ocorreu um erro ao enviar a selfie. Tente novamente mais tarde.'
    )

    err.code = error?.response?.status

    throw err
  }
}

export async function sendAnalyzeSelfSMS(id, recaptcha) {
  try {
    const response = await api.post(`/cf_unico/sms/${id}`, null, {
      headers: {
        recaptcha,
      },
    })

    return response.data
  } catch (error) {
    const { data } = error.response

    const err = new Error(
      data?.message
        ? data?.message
        : 'Ocorreu um erro ao enviar a selfie. Tente novamente mais tarde.'
    )

    err.code = error?.response?.status

    throw err
  }
}

export async function sendToRiskAnalyze(payload) {
  try {
    const response = await api.post(`/ca_riskanalysis/send_collected`, payload)

    return response.data
  } catch (error) {
    const { data } = error.response

    const err = new Error(
      data?.message
        ? data?.message
        : 'Ocorreu um erro ao enviar a assinatura. Tente novamente mais tarde.'
    )

    err.code = error.response.status

    throw err
  }
}
